export default function CategoryAddonPranzo({
    className = 'w-[14px] h-[14px] screen600:w-[26px] screen600:h-[12px]',
}: {
    className?: string
}) {
    return (
        <svg
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g strokeWidth="0" />

            <g strokeLinecap="round" strokeLinejoin="round" />

            <g>
                <path d="M7.49902 0.75C7.49902 0.335786 7.16324 0 6.74902 0C6.33481 0 5.99902 0.335786 5.99902 0.75H7.49902ZM5.99902 23.25C5.99902 23.6642 6.33481 24 6.74902 24C7.16324 24 7.49902 23.6642 7.49902 23.25H5.99902ZM5.99902 0.75V23.25H7.49902V0.75H5.99902Z" />{' '}
                <path d="M10.499 0.75C10.499 0.335786 10.1632 0 9.74902 0C9.33481 0 8.99902 0.335786 8.99902 0.75H10.499ZM9.74902 7.5L10.499 7.50224V7.5H9.74902ZM6.74902 10.5L6.74678 11.25L6.75126 11.25L6.74902 10.5ZM3.74902 7.5H2.99902L2.99903 7.50224L3.74902 7.5ZM4.49902 0.75C4.49902 0.335786 4.16324 0 3.74902 0C3.33481 0 2.99902 0.335786 2.99902 0.75H4.49902ZM8.99902 0.75V7.5H10.499V0.75H8.99902ZM8.99903 7.49776C8.99724 8.09454 8.75938 8.66638 8.33739 9.08837L9.39805 10.149C10.1003 9.44683 10.4961 8.49529 10.499 7.50224L8.99903 7.49776ZM8.33739 9.08837C7.9154 9.51036 7.34357 9.74822 6.74678 9.75L6.75126 11.25C7.74432 11.247 8.69585 10.8512 9.39805 10.149L8.33739 9.08837ZM6.75126 9.75C6.15448 9.74822 5.58265 9.51036 5.16066 9.08837L4.1 10.149C4.80219 10.8512 5.75373 11.247 6.74678 11.25L6.75126 9.75ZM5.16066 9.08837C4.73866 8.66638 4.5008 8.09454 4.49902 7.49776L2.99903 7.50224C3.00199 8.49529 3.3978 9.44683 4.1 10.149L5.16066 9.08837ZM4.49902 7.5V0.75H2.99902V7.5H4.49902Z" />{' '}
                <path d="M18.749 15.75L18.7776 15.0005C18.7681 15.0002 18.7586 15 18.749 15V15.75ZM20.249 14.25L19.4996 14.278L19.4996 14.2785L20.249 14.25ZM17.136 1.09299L17.7676 0.688436L17.763 0.681443L17.136 1.09299ZM15.748 1.50299L14.998 1.49819V1.50299H15.748ZM14.998 23.25C14.998 23.6642 15.3338 24 15.748 24C16.1623 24 16.498 23.6642 16.498 23.25H14.998ZM16.0545 15C15.6402 15 15.3045 15.3358 15.3045 15.75C15.3045 16.1642 15.6402 16.5 16.0545 16.5V15ZM18.7205 16.4994C19.0227 16.511 19.3241 16.4599 19.6057 16.3495L19.0582 14.953C18.9689 14.988 18.8734 15.0042 18.7776 15.0005L18.7205 16.4994ZM19.6057 16.3495C19.8873 16.2391 20.143 16.0717 20.3569 15.8578L19.2962 14.7972C19.2285 14.865 19.1474 14.918 19.0582 14.953L19.6057 16.3495ZM20.3569 15.8578C20.5708 15.644 20.7382 15.3882 20.8486 15.1066L19.4521 14.5591C19.4171 14.6483 19.364 14.7294 19.2962 14.7972L20.3569 15.8578ZM20.8486 15.1066C20.959 14.825 21.01 14.5237 20.9985 14.2214L19.4996 14.2785C19.5032 14.3743 19.4871 14.4698 19.4521 14.5591L20.8486 15.1066ZM20.9985 14.222C20.8382 9.93091 20.6699 5.21946 17.7676 0.688461L16.5045 1.49752C19.1582 5.64052 19.3379 9.95307 19.4996 14.278L20.9985 14.222ZM17.763 0.681443C17.5856 0.411148 17.3259 0.205124 17.0224 0.0938421L16.5061 1.5022C16.5073 1.50264 16.5083 1.50346 16.509 1.50454L17.763 0.681443ZM17.0224 0.0938421C16.7188 -0.0174388 16.3875 -0.0280686 16.0774 0.0635247L16.5023 1.50208C16.5036 1.50171 16.5049 1.50175 16.5061 1.5022L17.0224 0.0938421ZM16.0774 0.0635247C15.7673 0.155119 15.495 0.344073 15.3006 0.60244L16.4992 1.50422C16.5 1.50319 16.5011 1.50244 16.5023 1.50208L16.0774 0.0635247ZM15.3006 0.60244C15.1062 0.86081 15.0001 1.17488 14.9981 1.49819L16.498 1.50779C16.498 1.5065 16.4985 1.50525 16.4992 1.50422L15.3006 0.60244ZM14.998 1.50299V23.25H16.498V1.50299H14.998ZM16.0545 16.5H18.749V15H16.0545V16.5Z" />{' '}
            </g>
        </svg>
    )
}
