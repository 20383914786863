import ReactDOM from 'react-dom/client'
import { HolidoitEmbedDrawerWrapper } from './HolidoitEmbed'
import { debugLog } from './lib/utils'
import { FloatingButtonConfig, HolidoitEmbedEventName } from './types'
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'

export const rootNodeId = 'rootHolidoitEmbed'

export function toggleEmbed(embed_id?: string, experience_id?: string, test?: boolean) {
    const detail = embed_id ? { embed_id, test: test ? test : false, experience_id: experience_id ?? null } : {}
    // console.log('[toggleEmbed] embed_id', embed_id)
    debugLog('[toggleEmbed] test', test)
    const event = new CustomEvent(HolidoitEmbedEventName.TOGGLE, { detail, bubbles: true, cancelable: true })
    document.dispatchEvent(event)
}

export function openEmbed() {
    const event = new CustomEvent(HolidoitEmbedEventName.OPEN, { bubbles: true, cancelable: true })
    document.dispatchEvent(event)
}

export function closeEmbed() {
    const event = new CustomEvent(HolidoitEmbedEventName.CLOSE, { bubbles: true, cancelable: true })
    document.dispatchEvent(event)
}

export function renderHolidoitEmbed(
    defaultEmbedId?: 'string',
    floatingButton?: boolean,
    floatingButtonOptions?: FloatingButtonConfig
) {
    const bodyElement = document.body
    const rootElement = document.createElement('div')
    rootElement.setAttribute('id', rootNodeId)
    rootElement.classList.add('notranslate')
    bodyElement.appendChild(rootElement)

    const hostElement = document.getElementById(rootNodeId)
    if (!hostElement) {
        console.error(
            `[holidoit] No element found with id: ${rootNodeId}. Could not create shadow root. e.g. Add <div id="root"></div> to your page.`
        )
        return
    }
    const shadowRoot = hostElement.shadowRoot || hostElement.attachShadow({ mode: 'open' })
    const root = ReactDOM.createRoot(shadowRoot)

    debugLog(`[renderHolidoitEmbed] Holidoit initializing correctly`)

    root.render(
        <I18nextProvider i18n={i18n}>
            <HolidoitEmbedDrawerWrapper
                withFloatingButton={floatingButton}
                floatingButtonOptions={floatingButtonOptions}
                defaultEmbedId={defaultEmbedId}
            />
        </I18nextProvider>
    )
}
