import { Fragment, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import Button, { ButtonVariant } from 'src/lib/components/Button'
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner'
import { ChevronRight } from 'src/lib/icons/icons'
import { BOOKING_BOX_TYPE, COCustomerInfoForm } from 'src/types'
import { VAT_MULTIPLIER } from '../../../BookingBox/BBRecap'
import { useCOContext } from '../../Context'
import { AdditionalInformationSection } from '../components/AdditionalInformationSection'
import { useBBContext } from '../../../BookingBox/Context'

export const checkoutInputClassname = `focus:outline-none focus:!border-zinc-900 bg-zinc-50 h-[44px] border border-zinc-300 placeholder-zinc-500 text-sm px-3.5 bg-white w-full rounded cursor-text`

export const COCustomerInformationForm = () => {
    const {
        customerInfo: contactUserData,
        onCustomerInfoSubmit,
        booking,
        step,
        isLoadingCustomerInfo,
        config,
        onOpenBookingCustomerInfoSubmit,
        isLoadingOpenBookingCustomerInfo,
        openBooking,
    } = useCOContext()

    const { mode } = useBBContext()

    const [additonalInfoFilled, setAdditionalInfoFilled] = useState<boolean>(
        contactUserData.additional_info && contactUserData.additional_info.length > 0
    )

    const { t } = useTranslation()

    const [submitClicked, setSubmitClicked] = useState<boolean>(false)

    const additionalInfosRequired =
        booking?.info.experience_variant.additional_info && booking.info.experience_variant.additional_info.length > 0

    const form = useForm<COCustomerInfoForm>({
        defaultValues:
            mode === BOOKING_BOX_TYPE.BOOKING
                ? {
                      first_name: contactUserData.first_name ?? '',
                      last_name: contactUserData.last_name ?? '',
                      email: contactUserData.email ?? '',
                      confirm_email: contactUserData.email ?? '',
                      phone: contactUserData.phone ?? '',
                      additional_info: contactUserData.additional_info ?? '',
                  }
                : {
                      first_name: contactUserData.first_name ?? '',
                      last_name: contactUserData.last_name ?? '',
                      email: contactUserData.email ?? '',
                      confirm_email: contactUserData.email ?? '',
                  },
    })
    const { register, control, handleSubmit, formState } = form
    const { errors } = formState

    const onSubmit = (data: COCustomerInfoForm) => {
        setSubmitClicked(true)

        if (mode === BOOKING_BOX_TYPE.BOOKING) {
            if (additonalInfoFilled || !additionalInfosRequired) {
                const body = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    confirm_email: data.confirm_email,
                    phone: data.phone,
                    additional_info: additionalInfoString,
                }
                onCustomerInfoSubmit(body)
            }
        }
        if (mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING) {
            const body = {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                confirm_email: data.confirm_email,
            }
            onOpenBookingCustomerInfoSubmit(body)
        }
    }

    const [phoneInputHasFocus, setPhoneInputHasFocus] = useState(false)

    // Additional Info handling
    const [additionalInfoString, setAdditionalInfoString] = useState<string>(contactUserData.additional_info ?? '')

    const onAdditionalInfoComplete = useCallback((payload: string, additionalInfoFlag: boolean) => {
        setAdditionalInfoString(payload)
        setAdditionalInfoFilled(additionalInfoFlag)
    }, [])

    // debugLog('additonalInfoFilled', additonalInfoFilled)
    // debugLog('additionalInfoString', additionalInfoString)
    // debugLog('customerInfo.additional_info', contactUserData.additional_info)

    // If the user comes back to this step, reset the submitClicked state
    useEffect(() => {
        setSubmitClicked(false)
    }, [step])

    // debugLog('contactUserData.phone', contactUserData.phone);

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="screen775:!pb-[24px]">
                    <Fragment>
                        <div className="my-3 flex flex-col">
                            <div className="grid grid-cols-2 gap-2.5">
                                <div className="">
                                    <input
                                        className={`${checkoutInputClassname} ${
                                            errors.first_name ? 'validation-error' : ''
                                        }`}
                                        type="text"
                                        id="first_name"
                                        placeholder={t('name')}
                                        {...register('first_name', {
                                            required: {
                                                value: true,
                                                message: t('nameRequired'),
                                            },
                                        })}
                                    />
                                </div>

                                <div className="">
                                    <input
                                        className={`${checkoutInputClassname} ${
                                            errors.last_name ? 'validation-error' : ''
                                        }`}
                                        type="text"
                                        id="last_name"
                                        placeholder={t('surname')}
                                        {...register('last_name', {
                                            required: {
                                                value: true,
                                                message: t('surnameRequired'),
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <input
                                className={`${checkoutInputClassname} ${errors.email ? 'validation-error' : ''}`}
                                type="email"
                                id="email"
                                placeholder="Email"
                                {...register('email', {
                                    required: true,
                                })}
                            />
                        </div>
                        {/* CONFIRM EMAIL */}
                        <div className="mt-3 screen775:!mt-2.5">
                            <input
                                className={`${checkoutInputClassname} ${
                                    errors.confirm_email ? 'validation-error' : ''
                                }`}
                                type="email"
                                id="confirm_email"
                                placeholder={t('emailWithConfirmation')}
                                {...register('confirm_email', {
                                    required: true,
                                    validate: {
                                        emailMatch: (value) => {
                                            return value === form.getValues('email') ? true : t('addressesDoNotMatch')
                                        },
                                    },
                                })}
                            />
                            <div className="mt-1 text-[14.5px] text-red-700/80">{errors.confirm_email?.message}</div>
                        </div>

                        {mode === BOOKING_BOX_TYPE.BOOKING && (
                            <Fragment>
                                {/* Phone */}

                                <div className="mt-3 screen775:!mt-0">
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: t('phoneNumberRequired'),
                                            validate: (value) => {
                                                // react-phone-input-2 (handles as number, not string)
                                                // const isValid = isValidPhoneNumber('+' + value)
                                                const isValid = isValidPhoneNumber(value)
                                                if (!isValid) {
                                                    return t('invalidPhoneNumber')
                                                }
                                                return true
                                            },
                                        }}
                                        render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                                            <div
                                                style={{
                                                    border: error
                                                        ? '1px solid rgb(220 38 38)'
                                                        : phoneInputHasFocus
                                                          ? '1px solid rgb(24 24 27)'
                                                          : '1px solid rgb(212 212 216)',
                                                    borderRadius: '4px',
                                                }}
                                            >
                                                {/* react-phone-number-input (handles as string) */}
                                                <PhoneInput
                                                    defaultCountry="IT"
                                                    countryCallingCodeEditable={false}
                                                    withCountryCallingCode={true}
                                                    value={value}
                                                    international={true}
                                                    onChange={(evt) => {
                                                        onChange(evt || '')
                                                    }}
                                                    onBlur={() => {
                                                        onBlur()
                                                        setPhoneInputHasFocus(false)
                                                    }}
                                                    onFocus={() => setPhoneInputHasFocus(true)}
                                                />
                                                {/* react-phone-input-2 (handles as number, not string) */}
                                                {/* <PhoneInput
                                            country="it"
                                            regions={['europe', 'north-america']}
                                            countryCodeEditable={false}
                                            inputProps={{
                                                id: 'phone',
                                                name: 'phone',
                                            }}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            inputStyle={{
                                                width: '100%',
                                                height: '45px',
                                                padding: '0 0 0 50px',
                                                backgroundColor: '#fff',
                                                borderRadius: '4px',
                                                fontSize: '14px',
                                                fontFamily: 'DM sans',
                                                color: '#000000',
                                                outline: 'none',
                                            }}
                                        /> */}
                                            </div>
                                        )}
                                    />
                                    {errors.phone && (
                                        <div className="mt-1 select-none text-[13px] text-red-600">
                                            {errors.phone.message}
                                        </div>
                                    )}
                                </div>

                                {/* ADDITIONAL INFORMATION */}
                                {booking?.info.experience_variant.additional_info &&
                                    booking?.info.experience_variant.additional_info.length > 0 && (
                                        <Fragment>
                                            <div className="mb-2.5 mt-5">
                                                {/*<div className="mb-1 text-[13.5px] font-medium text-zinc-700 screen775:text-[14.5px]">*/}
                                                <div className="mb-2 mt-3.5 text-[16px] font-medium screen775:!mb-1 screen775:!mt-0 screen775:!text-[14.5px] screen775:!text-zinc-700">
                                                    {t('additionalInfo')}
                                                </div>
                                            </div>
                                            <AdditionalInformationSection
                                                submitClicked={submitClicked}
                                                onAdditionalInfoComplete={onAdditionalInfoComplete}
                                                id="additional_info"
                                            />
                                        </Fragment>
                                    )}
                            </Fragment>
                        )}

                        {mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING && (
                            <div className="my-[10px] text-xs leading-[22px] text-gray-500">
                                {t('willReceiveVoucher')}
                            </div>
                        )}
                    </Fragment>
                </div>

                <div className="absolute bottom-0 left-0 z-50 flex w-full items-center justify-center gap-3 border-t-[1.5px] border-zinc-300 bg-white px-6 py-[18px] shadow-top screen775:!px-5 screen775:!py-4">
                    <div className="flex w-36 flex-col gap-[2px] text-[16.5px] font-medium uppercase text-zinc-800 screen775:!text-[16px]">
                        <span className="text-[13.5px] uppercase text-zinc-500/95">{t('total')}</span>
                        {mode === BOOKING_BOX_TYPE.BOOKING && (
                            <Fragment>
                                <span>
                                    {booking?.holidoit_price ? Number(booking.holidoit_price).toFixed(2) : '-'}€
                                </span>
                                {(config.customer_fee ?? 0) > 0 && (
                                    <span className="-mt-[2px] whitespace-nowrap text-[12px] font-normal text-gray-400 screen775:!text-[12px]">
                                        {Number(config.customer_fee * VAT_MULTIPLIER).toFixed(1) ?? 0 * 100}%{' '}
                                        {t('serviceCosts').toLowerCase()}
                                    </span>
                                )}
                            </Fragment>
                        )}
                        {mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING && (
                            <span>
                                {openBooking?.original_value ? Number(openBooking.original_value).toFixed(2) : '-'}€
                            </span>
                        )}
                    </div>

                    <Button
                        variant={ButtonVariant.BLACK}
                        // type="submit"
                        className="flex w-full items-center justify-center gap-1.5 transition-all duration-150 hover:gap-2 disabled:cursor-not-allowed"
                    >
                        {mode === BOOKING_BOX_TYPE.BOOKING && (
                            <Fragment>
                                {isLoadingCustomerInfo && <LoadingSpinner className="text-white" />}
                                {!isLoadingCustomerInfo && <span>{t('continue')}</span>}
                                {!isLoadingCustomerInfo && <ChevronRight className="h-5 w-5" />}
                            </Fragment>
                        )}

                        {mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING && (
                            <Fragment>
                                {isLoadingOpenBookingCustomerInfo && <LoadingSpinner className="text-white" />}
                                {!isLoadingOpenBookingCustomerInfo && <span>{t('continue')}</span>}
                                {!isLoadingOpenBookingCustomerInfo && <ChevronRight className="h-5 w-5" />}
                            </Fragment>
                        )}
                    </Button>
                </div>
            </form>
        </Fragment>
    )
}
