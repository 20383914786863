import React from 'react'

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    name: string
    isError?: boolean
    className?: string
    id?: string
}

export const Checkbox: React.FC<CheckboxProps> = ({ id, checked, onChange, name, isError, className = '', ...props }) => {
    return (
        <label className="relative inline-flex cursor-pointer items-center">
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                name={name}
                className="peer sr-only"
                {...props}
                id={id || name}
            />
            <div
                className={`
          relative h-4 w-4 
          shrink-0 rounded-md
          border
          transition-colors
          peer-checked:border-accent-dark
          peer-checked:bg-accent-dark
          ${isError ? 'border-red-500' : 'border-gray-300'}
          ${className || ''}
          after:absolute 
          after:left-[4px]
          after:top-[1px] after:h-[10px]
          after:w-[6px]
          after:rotate-45 after:border-b-2
          after:border-r-2 after:border-white
          after:opacity-0
          after:transition-opacity
          after:content-['']
          peer-checked:after:opacity-100
        `}
            />
        </label>
    )
}
