import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Import translation files
import translationEN from 'src/lib/locales/en/translation.json'
import translationIT from 'src/lib/locales/it/translation.json'
import { Languages } from './types'

const resources = {
    [Languages.ENGLISH]: {
        translation: translationEN,
    },
    [Languages.ITALIAN]: {
        translation: translationIT,
    },
}

i18n.use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources,
        lng: 'it', // Default language
        fallbackLng: Languages.ITALIAN, // Fallback language
        interpolation: {
            escapeValue: false, // React already safes from XSS
        },
    })

export default i18n
