import { COBookingInfo, Coupon, DISCOUNT_TYPE } from '../../types'
import { customRound } from '../../lib/utils'

export const computeAmountToUseFromVoucher = (
    bookingAmount: string | number,
    appliedCoupons: Coupon[],
    coupon: Coupon
) => {
    let amountBeforeCoupon = typeof bookingAmount === 'string' ? parseFloat(bookingAmount) : bookingAmount
    const isGiftCard = coupon.discount_type === DISCOUNT_TYPE.GIFT_CARD
    const isOpenBooking = coupon.discount_type === DISCOUNT_TYPE.OPEN_BOOKING

    // Decrease the bookingAmountAsNumber for all the gift cards until the one that is coupon
    for (let i = 0; i < appliedCoupons.length; i++) {
        const couponToUse = appliedCoupons[i]
        if (couponToUse.code === coupon.code) {
            break
        } else {
            amountBeforeCoupon -= couponToUse.value
        }
    }

    if ((isGiftCard || isOpenBooking) && coupon.value > amountBeforeCoupon) {
        return amountBeforeCoupon
    }
    return coupon.value
}
export const computeDiscountAmount = (bookingPrice: string | number, discount: Coupon): number => {
    if (discount.discount_type === DISCOUNT_TYPE.DISCOUNT_CODE) {
        if (discount.is_percentage) {
            return (Number(bookingPrice) * discount.value) / 100
        } else {
            return Number(discount.value)
        }
    } else {
        // it hsould be exception bc this method works only for discount not for voucher (open booking)
        return Number(0)
    }
}
export const computeCouponAmount = (bookingPrice: string | number, coupon: Coupon): number => {
    if (coupon.discount_type === DISCOUNT_TYPE.DISCOUNT_CODE) {
        return computeDiscountAmount(bookingPrice, coupon)
    } else {
        return coupon.value
    }
}
export const computeTotalVouchersValue = (bookingPrice: number | string, coupons: Coupon[]) => {
    const vouchers = coupons.filter((discount) => discount.discount_type !== DISCOUNT_TYPE.DISCOUNT_CODE)
    const discounts = coupons.filter((discount) => discount.discount_type === DISCOUNT_TYPE.DISCOUNT_CODE)
    if (discounts.length > 0) {
        return discounts.reduce((total, discount) => {
            return total + computeDiscountAmount(bookingPrice, discount)
        }, 0)
    } else {
        return vouchers.reduce((total, discount) => {
            return total + discount.value
        }, 0)
    }
}
export const computeBookingPriceWithVoucher = (booking: COBookingInfo, coupons: Coupon[]) => {
    return computeTotalVouchersValue(booking.partner_price, coupons) >= Number(booking.holidoit_price)
        ? Number(0)
        : Number(Number(booking.holidoit_price) - computeTotalVouchersValue(booking.partner_price, coupons))
}
export const computeBookingPriceWithCoupons = (booking: COBookingInfo, coupons: Coupon[]): number => {
    const discounts = coupons.filter((coupon) => coupon.discount_type === DISCOUNT_TYPE.DISCOUNT_CODE)
    const vouchers = coupons.filter((coupon) => coupon.discount_type === DISCOUNT_TYPE.OPEN_BOOKING)

    if (vouchers.length > 0) {
        return customRound(computeBookingPriceWithVoucher(booking, vouchers))
    } else {
        if (booking.has_deposit) {
            return customRound(
                Number(booking.deposit_holidoit_price) -
                    discounts.reduce((acc, discount) => acc + computeDiscountAmount(booking.partner_price, discount), 0)
            )
        } else {
            return customRound(
                Number(booking.holidoit_price) -
                    discounts.reduce((acc, discount) => acc + computeDiscountAmount(booking.partner_price, discount), 0)
            )
        }
    }
}