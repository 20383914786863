import { Fragment } from 'react'
import CrossRedIcon, { CheckYesGreenIcon } from '../../lib/icons/icons'

interface IncludedExcludedSectionProps {
    included?: string[]
    excluded?: string[]
}

export const IncludedExcludedSection = ({ included, excluded }: IncludedExcludedSectionProps): JSX.Element => {
    return (
        <Fragment>
            <div className="space-y-3">
                {/* <h3 className="screen600:hidden text-[17px] screen900:text-[16px] font-bold">
                                            Incluso / escluso
                                        </h3> */}
                <div className="grid grid-cols-2 gap-4 text-[15px] leading-[21px]">
                    {included && (
                        <div className="grid grid-cols-1 gap-0.5">
                            {included.map((el, index) => (
                                <div key={index} className="mb-2 flex items-start gap-2">
                                    <div className="mt-0.5">
                                        <CheckYesGreenIcon className="h-4 w-4" />
                                    </div>

                                    {el}
                                </div>
                            ))}
                        </div>
                    )}
                    {excluded && (
                        <div className="grid grid-cols-1 gap-0.5">
                            {excluded.map((el, index) => (
                                <div key={index} className="mb-2 flex items-start gap-2">
                                    <div className="mt-0.5">
                                        <CrossRedIcon className="h-4 w-4" />
                                    </div>
                                    {el}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
