import { useExperiencePickerContext } from '../Context'
import { useState } from 'react'
import { CarouselGallery } from '../../lib/components/CarouselGallery'
import { CalendarIcon, CheckYesGreenIcon, LocationMarkerRedIcon } from '../../lib/icons/icons'
import Button, { ButtonVariant } from '../../lib/components/Button'
import { BOOKING_BOX_TYPE, DetailedPartnerExperience, ExperienceType, Languages } from '../../types'
import { useTranslation } from 'react-i18next'
import {
    addLangParam,
    checkNullOrUndefined,
    debugLog,
    parseAndRenderHtmlStrict,
    parseHtmlStrict,
} from '../../lib/utils'
import Accordion from './Accordion'
import { api } from '../../api'
import { PartnerExperiencePrices } from './PartnerExperiencePrices'
import { IncludedExcludedSection } from './IncludedExcludedSection'
import { ExperienceReviews } from './Reviews'
import useSWR from 'swr'
import { ChevronUpIcon } from '@radix-ui/react-icons'
import OpenBookingButton from '../../BookingBox/components/OpenBookingButton'
import { useBBContext } from '../../BookingBox/Context'

interface ExperienceInfoProps extends React.ComponentPropsWithoutRef<'div'> {
    experience: ExperienceType
}

export const ExperienceInfo = ({ experience }: ExperienceInfoProps): JSX.Element => {
    const { handleGoToNextStep, config } = useExperiencePickerContext()
    const { mode } = useBBContext()
    const { t, i18n } = useTranslation()
    const language = i18n.language

    const isChristmasPeriod = [10, 11].includes(new Date().getMonth())

    const { data: detailedExperience, isLoading } = useSWR<DetailedPartnerExperience>(
        experience
            ? addLangParam(
                  api.endpoints.backend.experiencePicker.getDetailedExperience.replace(':slug', experience.slug),
                  language
              )
            : null,
        (url) => fetch(url).then((res) => res.json())
    )

    // const participantsDescription = detailedExperience ? detailedExperience.participants_description : ''
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
    const participantsDescription = detailedExperience ? detailedExperience.participants_description : null
    const address = detailedExperience
        ? detailedExperience.address.full_address + ', ' + detailedExperience.address.region
        : null

    const prices = detailedExperience ? (
        <PartnerExperiencePrices variants={detailedExperience.experience_variants} />
    ) : null

    const sections = []
    if (participantsDescription) {
        sections.push({
            title: t('experienceInfo.participantDescription'),
            body: (
                <div
                    className="prose-base prose-h3:text-[16.4px] prose-h3:font-medium pb-2 text-[15.2px]"
                    dangerouslySetInnerHTML={{ __html: parseHtmlStrict(participantsDescription) }}
                />
            ),
        })
    }

    if (prices) {
        sections.push({ title: t('experienceInfo.prices'), body: prices })
    }
    if (detailedExperience && detailedExperience.what_to_bring && detailedExperience.what_to_bring.length > 0) {
        debugLog('detailedExperience.what_to_bring', detailedExperience.what_to_bring)

        sections.push({
            title: t('experienceInfo.whatToBring'),
            body: (
                <div
                    className="prose-base prose-h3:text-[16.4px] prose-h3:font-medium pb-2 text-[15.2px]"
                    dangerouslySetInnerHTML={{
                        __html: parseHtmlStrict('<p>' + detailedExperience.what_to_bring + '</p>'),
                    }}
                />
            ),
        })
    }
    if (
        detailedExperience &&
        ((detailedExperience.included && detailedExperience.included.length > 0) ||
            (detailedExperience.not_included && detailedExperience.not_included.length > 0))
    ) {
        sections.push({
            title: t('experienceInfo.included'),
            body: (
                <IncludedExcludedSection
                    included={detailedExperience.included}
                    excluded={detailedExperience.not_included}
                />
            ),
        })
    }

    if (address) {
        sections.push({
            title: t('experienceInfo.address'),
            body: (
                <div
                    className="prose-base prose-h3:text-[16.4px] prose-h3:font-medium pb-2 text-[15.2px]"
                    dangerouslySetInnerHTML={{ __html: parseHtmlStrict('<p>' + address + '</p>') }}
                />
            ),
        })
    }

    return (
        detailedExperience && (
            <div className="flex h-full flex-col">
                <div className="flex-grow overflow-y-auto px-8 pb-5 screen775:!px-[17px]">
                    <div className="mt-6 flex flex-col gap-3.5">
                        <div className="flex cursor-pointer flex-col gap-2.5 rounded-[5px] border border-zinc-300 bg-zinc-50">
                            <div className="relative flex h-[250px] w-full items-center justify-center overflow-hidden rounded-[5px]">
                                {/* TODO: rimuovere sto scempio */}
                                {config.embed_id !== 'ba96b407-c872-4492-a4f3-d6d9320adeac' ? (
                                    <CarouselGallery
                                        images={experience.images
                                            .map((img) => img.image)
                                            .map((img) => img.replace('/full/', '/600/'))}
                                        id={experience.slug}
                                        outsideContainerClasses="w-full h-full overflow-hidden"
                                        containerClasses=""
                                        showMobileArrows
                                    />
                                ) : (
                                    <img
                                        src={experience.images[0].image.replace('/full/', '/600/')}
                                        alt={experience.name}
                                        className="object-cover"
                                        loading="lazy"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="flex justify-between">
                                <div className="max-w-[550px] text-[19px] font-medium leading-[30px] screen775:leading-[27.5px]">
                                    {detailedExperience.name_partner_pages || detailedExperience.name}
                                </div>
                                {experience.embed_open_booking && (
                                    <OpenBookingButton christmasHat={isChristmasPeriod} />
                                )}
                            </div>

                            <div className="flex">
                                <ul className="flex w-full gap-2 text-sm leading-[18px]">
                                    <li>
                                        <button className="flex items-center justify-center gap-2 whitespace-nowrap rounded-[20px] border-[0.5px] border-gray-200 bg-gray-100/70 px-[12px] py-[6px]">
                                            <div>
                                                <LocationMarkerRedIcon className="h-4 w-4" />
                                            </div>
                                            <span className="">
                                                {detailedExperience.address.city}, {detailedExperience.address.region}
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-1.5">
                                <div className="flex w-full items-center justify-start gap-1.5 pr-2 text-[13px] text-emerald-600">
                                    <CheckYesGreenIcon className="h-3.5 w-3.5" />
                                    <div>
                                        {t('freeCancellation', { hours: detailedExperience.cancellation_period })}
                                    </div>
                                </div>
                                {experience && !checkNullOrUndefined(experience.deposit_percentage) && (
                                    <div className="flex w-full items-center justify-start gap-1.5 pr-2 text-[13px] text-emerald-600">
                                        <CheckYesGreenIcon className="h-3.5 w-3.5" />
                                        {t('depositExperienceBoxBeforeRecap', {
                                            deposit: Math.round(experience.deposit_percentage),
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div
                                className={`prose-base prose-h3:text-[16.4px] prose-h3:font-medium text-[15.2px] screen900:text-[15px] ${
                                    isDescriptionExpanded ? 'pb-2' : 'line-clamp-2 max-h-[52px]'
                                }`}
                            >
                                {parseAndRenderHtmlStrict(
                                    detailedExperience.program_partner_pages || detailedExperience.program
                                )}
                            </div>
                            <p
                                onClick={(e) => {
                                    setIsDescriptionExpanded(!isDescriptionExpanded)
                                }}
                                className="flex w-full cursor-pointer items-center gap-1 bg-white py-1 text-[15.2px] font-medium hover:underline screen900:text-[15px] screen600:py-1.5"
                            >
                                {!isDescriptionExpanded ? (
                                    <span>{t('review.expand')}</span>
                                ) : (
                                    <span>{t('review.collapse')}</span>
                                )}
                                <ChevronUpIcon className={`h-3 w-3 ${isDescriptionExpanded ? '' : 'hidden'}`} />
                            </p>
                        </div>

                        <Accordion items={sections} />

                        <ExperienceReviews
                            lang={language as Languages}
                            reviewsPageSize={5}
                            averageRating={experience.average_review}
                            totalReviews={experience.total_reviews}
                            t={t}
                            // images={reviewsImages && reviewsImages.length > 0 ? reviewsImages : []}
                            // comments={experienceReviews.results}
                            experienceId={experience!.id}
                            // numPages={Math.ceil(experienceReviews.count / reviewsPageSize)}
                            // averageRating={experience!.average_review}
                            // totalReviews={experience?.total_reviews || 0}
                            showDisclaimer={false}
                            // showDisclaimer={experience?.review_disclaimer ? true : false}
                            showVariant={
                                detailedExperience.experience_variants &&
                                detailedExperience.experience_variants?.length > 1
                            }
                        />
                    </div>
                </div>

                <div className="flex items-center justify-center gap-3 border-t border-zinc-300 bg-white px-6 py-5 shadow-top screen775:!px-5 screen775:!py-4">
                    <Button
                        variant={ButtonVariant.BLACK}
                        onClick={handleGoToNextStep}
                        className="flex w-full items-center justify-center"
                    >
                        <CalendarIcon className="mx-1 fill-white" />
                        {mode === BOOKING_BOX_TYPE.BOOKING ? t('book') : t('giftBooking')}
                    </Button>
                </div>
            </div>
        )
    )
}
