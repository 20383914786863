import { useElements, useStripe } from '@stripe/react-stripe-js'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCOContext } from 'src/Checkout/Context'
import { api } from 'src/api'
import Button, { ButtonVariant } from 'src/lib/components/Button'
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner'
import { fetchWithLanguage } from 'src/lib/utils'
import useSWRMutation from 'swr/mutation'
import { BOOKING_BOX_TYPE } from '../../../types'
import { useBBContext } from '../../../BookingBox/Context'
import { computeBookingPriceWithCoupons } from '../../lib/priceUtils'

export interface IStripePaymentSection extends React.ComponentPropsWithRef<'form'> {}

export interface IAPIStripeOrderConfirmationInfo {
    booking_code: string
    order_client_secret: string
    order_id: string
    requires_action: boolean
}

export interface IAPIStripeOpenBookingOrderConfirmationInfo {
    open_booking_code: string
    order_client_secret: string
    order_id: string
    requires_action: boolean
}

const stripeInputStyle = {
    base: {
        color: '#2D3131',
        height: '50px',
        fontSmoothing: 'antialiased',
        fontSize: '15px',
        ':-webkit-autofill': {
            color: '#2D3131',
        },
        '::placeholder': {
            'font-size': '15px ! important',
            color: '#6b7280',
        },
    },
    invalid: {
        color: '#dc2626',
    },
}

const StripeCardSection: React.FC<IStripePaymentSection> = () => {
    const {
        booking,
        customerInfo,
        peopleFeaturesNotes,
        setPaymentSuccess,
        setPaymentError,
        config,
        setCompletedBookingCode,
        openBooking,
        coupons,
    } = useCOContext()

    const { mode } = useBBContext()

    const stripe = useStripe()
    const stripeElements = useElements()

    const refCardNumber = useRef<HTMLDivElement>(null)
    // These states are necessary to handle the validation state of the field, indeed we can't use the react hook form as the fields are rendered in iframes
    const [cardNumberError, setCardNumberError] = useState(false)
    const [cardNumberFocus, setCardNumberFocus] = useState(false)
    const [cardNumberDirty, setCardNumberDirty] = useState(false)

    const refCardExpiry = useRef<HTMLDivElement>(null)
    // These states are necessary to handle the validation state of the field, indeed we can't use the react hook form as the fields are rendered in iframes
    const [cardExpiryError, setCardExpiryError] = useState(false)
    const [cardExpiryFocus, setCardExpiryFocus] = useState(false)
    const [cardExpiryDirty, setCardExpiryDirty] = useState(false)

    // These states are necessary to handle the validation state of the field, indeed we can't use the react hook form as the fields are rendered in iframes
    const refCardCVV = useRef<HTMLDivElement>(null)
    const [cardCvvError, setCardCvvError] = useState(false)
    const [cardCvvFocus, setCardCvvFocus] = useState(false)
    const [cardCvvDirty, setCardCvvDirty] = useState(false)

    // Collects all the validation states of credit card fields in only one boolean
    const [cardDataValid, setCardDataValid] = useState(false)

    const { i18n, t } = useTranslation()
    const language = i18n.language

    useEffect(() => {
        // Here we are mounting the stripe elements in our DOM where needed and handling their events in order to keep track of the validation status
        const cardNumberElementRef = refCardNumber.current
        const cardExpiryElementRef = refCardExpiry.current
        const cardCVVElementRef = refCardCVV.current
        if (cardNumberElementRef && cardExpiryElementRef && cardCVVElementRef && stripeElements) {
            const cardNumberElement = stripeElements.create('cardNumber', {
                placeholder: '0000 0000 0000 0000',
                style: stripeInputStyle,
            })
            cardNumberElement.mount(cardNumberElementRef)
            cardNumberElement.on('focus', (_) => setCardNumberFocus(true))
            cardNumberElement.on('change', (evt) => {
                setCardNumberDirty(true)
                setCardNumberError(!evt.complete)
            })
            cardNumberElement.on('blur', (_) => setCardNumberFocus(false))

            const cardExpiryElement = stripeElements.create('cardExpiry', {
                placeholder: 'MM / YY',
                style: stripeInputStyle,
            })
            cardExpiryElement.mount(cardExpiryElementRef)
            cardExpiryElement.on('focus', (_) => setCardExpiryFocus(true))
            cardExpiryElement.on('change', (evt) => {
                setCardExpiryDirty(true)
                setCardExpiryError(!evt.complete)
            })
            cardExpiryElement.on('blur', (_) => setCardExpiryFocus(false))

            const cardCVVElement = stripeElements.create('cardCvc', {
                placeholder: '123',
                style: stripeInputStyle,
            })
            cardCVVElement.mount(cardCVVElementRef)
            cardCVVElement.on('focus', (_) => setCardCvvFocus(true))
            cardCVVElement.on('change', (evt) => {
                setCardCvvError(!evt.complete)
                setCardCvvDirty(true)
            })
            cardCVVElement.on('blur', (_) => setCardCvvFocus(false))

            return () => {
                cardNumberElement.destroy()
                cardExpiryElement.destroy()
                cardCVVElement.destroy()
            }
        }
    }, [stripeElements])

    // Collects all the validation states of credit card fields in only one boolean
    useEffect(() => {
        setCardDataValid(
            cardNumberDirty && cardExpiryDirty && cardCvvDirty && !cardNumberError && !cardExpiryError && !cardCvvError
        )
    }, [cardCvvDirty, cardCvvError, cardExpiryDirty, cardExpiryError, cardNumberDirty, cardNumberError])

    const {
        trigger: triggerStripeCreateAndConfirmOrder,
        error: errorStripeConfirmOrder,
        isMutating: loadingStripeConfirm,
    } = useSWRMutation<IAPIStripeOrderConfirmationInfo | undefined>(
        api.endpoints.backend.checkout.stripeCreateOrder,

        async (url: string, { arg: { bookingCode, email, first_name, last_name, phone } }: any) => {
            const card = stripeElements?.getElement('cardNumber')
            if (!card) {
                setPaymentError({
                    status: true,
                    message: 'Dati carta errati',
                })
            }

            const stripePayment = await stripe?.createPaymentMethod({
                type: 'card',
                card: card,
                billing_details: {
                    name: first_name + ' ' + last_name,
                    email: email,
                    phone: phone,
                },
            })
            if (!stripePayment || stripePayment.error || !stripePayment?.paymentMethod?.id) {
                setPaymentError({
                    status: true,
                    message: 'Error while creating stripe payment',
                })
            }
            const resOrderCreation = await fetchWithLanguage(
                url.replace(':embedId', config.embed_id).replace(':code', bookingCode),
                {
                    language,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        bookingCode,
                        email,
                        first_name,
                        last_name,
                        phone,
                        payment_method_id: stripePayment?.paymentMethod?.id,
                        discount_codes: coupons.map((coupon) => coupon.code),
                    }),
                }
            ).then((res) => res.json())
            const { order_id } = resOrderCreation
            let requiresAction = true
            let resOrderConfirmation: IAPIStripeOrderConfirmationInfo | undefined = undefined
            while (requiresAction) {
                resOrderConfirmation = await fetchWithLanguage(
                    api.endpoints.backend.checkout.stripeConfirmOrder
                        .replace(':embedId', config.embed_id)
                        .replace(':intentId', order_id),
                    {
                        language,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        method: 'POST',

                        body: JSON.stringify({
                            email,
                            first_name,
                            last_name,
                            phone,
                            intentId: order_id,
                            ...(peopleFeaturesNotes ? { notes: peopleFeaturesNotes } : {}),
                        }),
                    }
                ).then((res) => {
                    if (!res.ok) {
                        throw new Error('Error while confirming stripe payment')
                    }
                    return res.json()
                })

                requiresAction = resOrderConfirmation!.requires_action
                if (requiresAction) {
                    const { error: errorRequiresAction } = await stripe!.handleCardAction(
                        resOrderConfirmation!.order_client_secret
                    )

                    if (errorRequiresAction) {
                        setPaymentError({
                            status: true,
                            message: errorRequiresAction.message ?? 'Error while creating stripe payment',
                        })
                        // throw new Error(errorRequiresAction.message)
                    }
                }
            }

            return resOrderConfirmation
        }
    )

    const {
        trigger: triggerStripeCreateAndConfirmOpenBookingOrder,
        error: errorStripeConfirmOpenBookingOrder,
        isMutating: loadingStripeOpenBookingConfirm,
    } = useSWRMutation<IAPIStripeOpenBookingOrderConfirmationInfo | undefined>(
        api.endpoints.backend.checkout.openBookingStripeCreateOrder,

        async (url: string, { arg: { openBookingCode, email, first_name, last_name, phone } }: any) => {
            const card = stripeElements?.getElement('cardNumber')
            if (!card) {
                setPaymentError({
                    status: true,
                    message: 'Dati carta errati',
                })
            }

            const stripePayment = await stripe?.createPaymentMethod({
                type: 'card',
                card: card,
                billing_details: {
                    name: first_name + ' ' + last_name,
                    email: email,
                    phone: phone,
                },
            })
            if (!stripePayment || stripePayment.error || !stripePayment?.paymentMethod?.id) {
                setPaymentError({
                    status: true,
                    message: 'Error while creating stripe payment',
                })
            }
            const resOrderCreation = await fetchWithLanguage(url.replace(':code', openBookingCode), {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    openBookingCode,
                    email,
                    first_name,
                    last_name,
                    phone,
                    payment_method_id: stripePayment?.paymentMethod?.id,
                }),
            }).then((res) => res.json())
            const { order_id } = resOrderCreation
            let requiresAction = true
            let resOrderConfirmation: IAPIStripeOpenBookingOrderConfirmationInfo | undefined = undefined
            while (requiresAction) {
                resOrderConfirmation = await fetchWithLanguage(
                    api.endpoints.backend.checkout.openBookingStripeConfirmOrder.replace(':intentId', order_id),
                    {
                        language,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        method: 'POST',

                        body: JSON.stringify({
                            email,
                            first_name,
                            last_name,
                            phone,
                            intentId: order_id,
                        }),
                    }
                ).then((res) => {
                    if (!res.ok) {
                        throw new Error('Error while confirming stripe payment')
                    }
                    return res.json()
                })

                requiresAction = resOrderConfirmation!.requires_action
                if (requiresAction) {
                    const { error: errorRequiresAction } = await stripe!.handleCardAction(
                        resOrderConfirmation!.order_client_secret
                    )

                    if (errorRequiresAction) {
                        setPaymentError({
                            status: true,
                            message: errorRequiresAction.message ?? 'Error while creating stripe payment',
                        })
                        // throw new Error(errorRequiresAction.message)
                    }
                }
            }

            return resOrderConfirmation
        }
    )

    const onFormSubmitPayment = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (cardDataValid) {
            try {
                if (mode === BOOKING_BOX_TYPE.BOOKING) {
                    await triggerStripeCreateAndConfirmOrder({
                        bookingCode: booking.code,
                        email: customerInfo?.email,
                        first_name: customerInfo?.first_name,
                        last_name: customerInfo?.last_name,
                        phone: customerInfo?.phone,
                    })
                } else {
                    // mode === BOOKING_BOX_TYPE.OPEN_BOOKING
                    await triggerStripeCreateAndConfirmOpenBookingOrder({
                        openBookingCode: openBooking.code,
                        email: customerInfo?.email,
                        first_name: customerInfo?.first_name,
                        last_name: customerInfo?.last_name,
                        phone: customerInfo?.phone,
                    })
                }
                setPaymentSuccess(true)
                const code = mode === BOOKING_BOX_TYPE.BOOKING ? booking.code : openBooking.code
                setCompletedBookingCode(code)
            } catch (error) {
                setPaymentError({
                    status: true,
                    message: 'Error in stripe payment',
                })
            }
        }
    }

    return (
        <Fragment>
            <form onSubmit={(e) => onFormSubmitPayment(e)} className="relative" id="stripe-submit-form">
                <label className="text-[14px] font-medium" htmlFor="stripe-card-number">
                    {t('cardNumber')}
                </label>

                {/* The stripe card number component is mounted by the stripe elements api (see the relative useEffect) */}
                <div
                    className={`relative mt-2 h-[45px] rounded border border-zinc-300 bg-white px-3.5 py-3 pt-[13px] !text-[15px] shadow-sm ${
                        cardNumberFocus
                            ? '!border-zinc-800'
                            : cardNumberError && !cardNumberFocus
                              ? '!border-red-600'
                              : ''
                    }`}
                    id="stripe-card-number"
                    ref={refCardNumber}
                ></div>

                <div className="mt-3 grid grid-cols-2 gap-3">
                    <div className="grow">
                        <label className="mb-2 block text-[14px] font-medium" htmlFor="stripe-card-expiry">
                            {t('expirationDate')}
                        </label>

                        {/* <CardExpiryElement className="relative mt-2 h-[45px] rounded border border-zinc-300 bg-white px-3.5 py-3 text-[15px] focus:!border-zinc-800" /> */}

                        {/* The stripe card expiry date component is mounted by the stripe elements api (see the relative useEffect) */}
                        <div
                            className={`relative h-[45px] rounded border border-zinc-300 bg-white px-3.5 py-3 !text-[15px] shadow-sm ${
                                cardExpiryFocus
                                    ? '!border-zinc-800'
                                    : cardExpiryError && !cardExpiryFocus
                                      ? 'border-red-600'
                                      : ''
                            }`}
                            ref={refCardExpiry}
                            id="stripe-card-expiry"
                        ></div>
                    </div>

                    <div className="grow">
                        {/* The stripe card cvv  component is mounted by the stripe elements api (see the relative useEffect) */}

                        <label className="mb-2 block text-[14px] font-medium" htmlFor="stripe-card-cvv">
                            CVV
                        </label>

                        {/* <CardCvcElement className="relative mt-2 h-[45px] rounded border border-zinc-300 bg-white px-3.5 py-3 text-[15px] focus:!border-zinc-800" /> */}
                        <div
                            className={`relative h-[45px] rounded border border-zinc-300 bg-white px-3.5 py-3 !text-[15px] shadow-sm ${
                                cardCvvFocus
                                    ? '!border-zinc-800'
                                    : cardCvvError && !cardCvvFocus
                                      ? 'border-red-600'
                                      : ''
                            }`}
                            id="stripe-card-cvv"
                            ref={refCardCVV}
                        ></div>
                    </div>
                </div>

                {(errorStripeConfirmOrder || errorStripeConfirmOpenBookingOrder) && (
                    <div className="mt-2 text-[13px] text-red-500">{t('paymentError')}</div>
                )}

                {/* Conferma e paga */}
                <div className="mt-[24px] flex justify-end">
                    <Button
                        variant={ButtonVariant.BLACK}
                        className={`flex !h-[50px] !min-h-[50px] w-full items-center gap-2 !rounded-[5px] px-5 font-semibold focus:!outline-1 screen775:!px-[18px] ${loadingStripeConfirm || loadingStripeOpenBookingConfirm ? 'justify-center' : 'justify-between'}`}
                        // type="submit"
                    >
                        {loadingStripeConfirm || loadingStripeOpenBookingConfirm ? (
                            <LoadingSpinner className="text-white" />
                        ) : (
                            <Fragment>
                                <span className="font-semibold">{t('pay')}</span>

                                <span>
                                    {mode === BOOKING_BOX_TYPE.BOOKING &&
                                        booking &&
                                        computeBookingPriceWithCoupons(booking, coupons).toFixed(2)}
                                    {mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING &&
                                        openBooking &&
                                        Number(openBooking.original_value).toFixed(2)}{' '}
                                    €
                                </span>
                            </Fragment>
                        )}
                    </Button>
                </div>
            </form>
        </Fragment>
    )
}

export { StripeCardSection }
