import { CheckYesGreenIcon } from 'src/lib/icons/icons'

const InfoBox = ({ children }: { children: any }) => (
    <div className="flex w-full items-center justify-start gap-3 truncate rounded-[4px] border border-gray-300 p-3 text-[15px]">
        <div>
            <CheckYesGreenIcon className="h-5 w-5" />
        </div>
        <div>{children}</div>
    </div>
)

export { InfoBox }
