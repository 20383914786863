import { Fragment, useEffect, useState } from 'react'
import { getTotalParticipants } from 'src/lib/utils'
import { PeopleIcon, XMarkIcon } from '../../../lib/icons/icons'
import { useCOContext } from '../../Context'
import { additionalInformationFactory } from './AdditionalInformationInput'
import { useTranslation } from 'react-i18next'
import { AdditionalInformationInputTypeChoices } from '../../../types'

interface AdditionalInformationSectionProps extends React.ComponentPropsWithoutRef<'div'> {
    onAdditionalInfoComplete: (payload: string, additionalInfoFlag:boolean) => void
    submitClicked: boolean
}

export const AdditionalInformationSection: React.FC<AdditionalInformationSectionProps> = ({
    onAdditionalInfoComplete,
    submitClicked,
}) => {
    const { t } = useTranslation()
    const { booking } = useCOContext()
    const additional_info = booking?.info.experience_variant.additional_info.sort((a, b) => {
        return b.input_type.localeCompare(a.input_type);
    });

    const mappingAdditionalInfoNameUnit = additional_info.reduce((acc, item) => {
        // Check if the unit is not undefined or null
        if (item.unit) {
            acc[item.name] = item.unit // Add the name as key and unit as value
        }
        return acc // Return the accumulator for the next iteration
    }, {})
    const globalAdditionalInfo = additional_info.filter((additionalInfo) => !additionalInfo.is_per_person)
    const optionalGlobalAdditionalInfo = globalAdditionalInfo
        .filter((additionalInfo) => additionalInfo.optional)
        .map((obj) => obj.name)
    const perPersonAdditionalInfo = additional_info.filter((additionalInfo) => additionalInfo.is_per_person)
    const optionalPerPersonAdditionalInfo = perPersonAdditionalInfo
        .filter((additionalInfo) => additionalInfo.optional)
        .map((obj) => obj.name)

    const numberOfPersonalAdditionalInfo = perPersonAdditionalInfo.length

    const json_allocation = booking?.json_allocations
    const numberOfParticipants = getTotalParticipants(json_allocation)

    const [bookingPerPersonAdditionalInfo, setBookingPerPersonAdditionalInfo] = useState(
        numberOfParticipants > 0 && numberOfPersonalAdditionalInfo > 0
            ? Array.from({ length: numberOfParticipants }, () =>
                  perPersonAdditionalInfo.reduce((acc, info) => {
                      if (info.input_type === AdditionalInformationInputTypeChoices.CHECKBOX) {
                          acc[info.name] = t('unaccepted')
                      } else {
                          acc[info.name] = info.default ?? undefined
                      }
                      return acc
                  }, {})
              )
            : []
    )

    // equal to parsedAdditionalInfo without people attribute
    const [bookingGlobalAdditionalInfo, setBookingGlobalAdditionalInfo] = useState(
        globalAdditionalInfo.reduce((acc, info) => {
            if (info.input_type === AdditionalInformationInputTypeChoices.CHECKBOX) {
                acc[info.name] = t('unaccepted')
            } else {
                acc[info.name] = info.default ?? undefined
            }
            return acc
        }, {})
    )

    function updatePersonAdditionalInfo(key: string, value: string, index: number) {
        // Create a new array by spreading the existing array
        const updatedArray = [...bookingPerPersonAdditionalInfo]

        // Update the specific object at the given index
        updatedArray[index] = {
            ...updatedArray[index], // Spread the existing object properties
            [key]: value, // Update the specific key with the new value
        }
        // Update the state with the modified array
        setBookingPerPersonAdditionalInfo(updatedArray)
    }

    function updateGlobalAdditionalInfo(key: string, value: string) {
        // Create a new object by spreading the existing bookingGlobalAdditionalInfo
        const newValue = { ...bookingGlobalAdditionalInfo, [key]: value }
        // Update the state with the new object
        setBookingGlobalAdditionalInfo(newValue)
    }

    function getPersonDescription(obj: Record<string, string>) {
        // Map through each key-value pair in the object and append the unit if it exists
        return Object.entries(obj)
            .map(([key, value]) => {
                // Check if the key exists in the mapping and append the unit if available
                if (value === t('accepted')) {
                    return key
                }
                if (value === t('unaccepted')) {
                    return ''
                }
                const unit = mappingAdditionalInfoNameUnit[key]
                return unit ? `${value} ${unit}` : value // Add unit if available
            })
            .filter((item) => item.trim() !== '') // Remove empty values
            .join(', ') // Join all values with commas
    }

    const [peopleFeaturesActiveIndex, setPeopleFeaturesActiveIndex] = useState(-1)

    function convertObjectToArray(obj) {
        return Object.entries(obj).map(([key, value]) => ({
            name: key,
            value: value,
        }))
    }

    const validateAdditionalItem = (obj, ignoreKeys: string[] = []) => {
        // return true if all values are valid
        return !Object.entries(obj).some(([key, value]) => {
            // If the key is in the ignore list, skip it
            if (ignoreKeys.includes(key)) {
                return false;
            }

            // Check if the value is invalid
            return value === undefined || value === null || value === '' || value === t('unaccepted')
        });
    }

    const validateGlobalAdditionalItem = (obj, ignoreKeys: string[] = []) => {
        return validateAdditionalItem(obj, optionalGlobalAdditionalInfo)
    }

    const validatePerPersonAdditionalItem = (obj, ignoreKeys: string[] = []) => {
        return validateAdditionalItem(obj, optionalPerPersonAdditionalInfo)
    }

    useEffect(() => {
        const completeAdditionalInformation = {
            generic: convertObjectToArray(bookingGlobalAdditionalInfo),
            people: bookingPerPersonAdditionalInfo.map(convertObjectToArray),
        }
        if (
            validateGlobalAdditionalItem(bookingGlobalAdditionalInfo) &&
            bookingPerPersonAdditionalInfo.every((item) => validatePerPersonAdditionalItem(item))
        ) {
            onAdditionalInfoComplete(JSON.stringify(completeAdditionalInformation), true)
        } else {
            onAdditionalInfoComplete(JSON.stringify(completeAdditionalInformation), false)
        }
    }, [
        onAdditionalInfoComplete,
        peopleFeaturesActiveIndex,
        bookingGlobalAdditionalInfo,
        bookingPerPersonAdditionalInfo,
    ])

    return (
        <Fragment>
            <div className="mt-3 grid w-full gap-[10px] text-sm">
                {bookingPerPersonAdditionalInfo.map((_, index) => (
                    <div key={index}>
                        <div
                            className={`flex h-[48px] w-full cursor-pointer items-center justify-between gap-2.5 rounded border  bg-white px-3.5 pr-2.5 text-gray-700 ${
                                peopleFeaturesActiveIndex === index
                                    ? !validatePerPersonAdditionalItem(bookingPerPersonAdditionalInfo[index]) &&
                                    submitClicked
                                        ? 'border-red-500 hover:bg-red-500'
                                        : 'border-gray-900'
                                    : !validatePerPersonAdditionalItem(bookingPerPersonAdditionalInfo[index]) &&
                                    submitClicked
                                        ? 'border-red-500 hover:bg-red-500'
                                        : 'border-zinc-300'
                            }`}
                            onClick={() => {
                                setPeopleFeaturesActiveIndex(index)
                            }}
                        >
                            <div
                                className={`flex ${!validatePerPersonAdditionalItem(bookingPerPersonAdditionalInfo[index]) && submitClicked ? 'text-red-500' : ''} items-center gap-2.5`}
                            >
                                <div className="flex gap-0">
                                    <PeopleIcon className={`h-4 w-4 `} />
                                    <div className="mt-[3px] text-xs">{index + 1}</div>
                                </div>
                                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                    {validatePerPersonAdditionalItem(bookingPerPersonAdditionalInfo[index]) ? (
                                        getPersonDescription(bookingPerPersonAdditionalInfo[index])
                                    ) : (
                                        <span
                                            className={`${!validatePerPersonAdditionalItem(bookingPerPersonAdditionalInfo[index]) && submitClicked ? 'text-red-500' : ''}`}
                                        >
                                            - {/*- <span className="font-bold text-[#DE3730]">*</span>*/}
                                        </span>
                                    )}
                                </span>
                            </div>
                            {peopleFeaturesActiveIndex === index && (
                                <div
                                    className={`cursor-pointer rounded-full bg-zinc-50 p-2 hover:bg-zinc-100 ${!validatePerPersonAdditionalItem(bookingPerPersonAdditionalInfo[index]) && submitClicked ? 'border-red-500 hover:bg-red-500' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setPeopleFeaturesActiveIndex(-1)
                                    }}
                                >
                                    <XMarkIcon />
                                </div>
                            )}
                        </div>

                        {peopleFeaturesActiveIndex === index && (
                            <div
                                className={`mt-[7px] flex flex-col gap-2 rounded border p-2.5 ${!validatePerPersonAdditionalItem(bookingPerPersonAdditionalInfo[index]) && submitClicked ? 'border-red-500' : 'border-zinc-800'}`}
                            >
                                {perPersonAdditionalInfo.map((additionalInfo) =>
                                    additionalInformationFactory({
                                        inputType: additionalInfo.input_type,
                                        name: additionalInfo.name,
                                        unit: additionalInfo.unit,
                                        description: additionalInfo.description,
                                        choices: additionalInfo.choices,
                                        value: bookingPerPersonAdditionalInfo[index][additionalInfo.name],
                                        defaultValue: additionalInfo.default,
                                        updateInfo: updatePersonAdditionalInfo,
                                        index: index,
                                        className:
                                            !bookingPerPersonAdditionalInfo[index][additionalInfo.name] && submitClicked
                                                ? '!border-red-500'
                                                : '',
                                        optional: additionalInfo.optional ?? false,
                                        isSubmitted: submitClicked,
                                    })
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="mt-3 grid w-full gap-[10px] text-sm">
                <div className={`flex select-none flex-col gap-2 text-[14.5px]`}>
                    {globalAdditionalInfo.map((additionalInfo) =>
                        additionalInformationFactory({
                            inputType: additionalInfo.input_type,
                            name: additionalInfo.name,
                            unit: additionalInfo.unit,
                            description: additionalInfo.description,
                            choices: additionalInfo.choices,
                            value: bookingGlobalAdditionalInfo[additionalInfo.name],
                            defaultValue: additionalInfo.default,
                            updateInfo: updateGlobalAdditionalInfo,
                            className:
                                !bookingGlobalAdditionalInfo[additionalInfo.name] && submitClicked
                                    ? '!border-red-500'
                                    : '',
                            optional: additionalInfo.optional ?? false,
                            isSubmitted: submitClicked,
                        })
                    )}
                </div>
            </div>
        </Fragment>
    )
}
