import clsx from 'clsx'
import React, { Fragment } from 'react'
import { Slot } from 'src/types'
// @ts-ignore
export interface IRadioCheckbox extends React.ComponentPropsWithoutRef<'input'> {
    cont?: JSX.Element
    slot: Slot
}

const RadioBox: React.FC<IRadioCheckbox> = ({ slot, id, name, cont, ...inputProps }) => {
    return (
        <Fragment>
            <input type="radio" name={name} id={id} className="hidden" {...inputProps} />
            <label
                htmlFor={id}
                className={`flex w-full items-center gap-[15px] px-3 py-2.5 !font-sans ${
                    inputProps.disabled ? '' : 'cursor-pointer'
                } `}
            >
                <span
                    className={`relative inline-block aspect-square h-5 w-5 rounded-full ${
                        inputProps.checked ? 'border-[1.5px]' : 'border'
                    } ${inputProps.disabled ? 'border-zinc-300' : 'border-zinc-900'} `}
                >
                    <span
                        className={clsx(
                            inputProps.checked
                                ? 'block'
                                : !slot.available
                                  ? 'hidden'
                                  : 'hidden group-hover/radio:!block',

                            'absolute left-1/2 top-1/2  h-[9px] w-[9px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-zinc-900'
                        )}
                    ></span>
                </span>
                {cont && cont}
            </label>
        </Fragment>
    )
}

export default RadioBox
