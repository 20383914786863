import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { BoltIcon, ChevronDownIcon } from 'src/lib/icons/icons'
import { formatDuration, parseHtmlStrict } from 'src/lib/utils'
import { ExperienceVariantType } from 'src/types'
import { useBBContext } from './Context'
import { useTranslation } from 'react-i18next'

const BBVariant = () => {
    const {
        config,
        variants,
        selectedVariant,
        setSelectedVariant,
        setStep,
        setSelectedParticipants,
        setSelectedDate,
        setSelectedSlot,
        setSelectedAddons,
        handleGoBack,
    } = useBBContext()

    const [expandedVariantId, setExpandedVariantId] = useState<string>('')
    const { t } = useTranslation()

    const handler = useSwipeable({
        onSwipedRight: () => {
            handleGoBack()
        },
    })

    function handleClick(e: MouseEvent, v: ExperienceVariantType): void {
        e.preventDefault()
        setSelectedVariant(v)
        // RESET OF PARTICIPANTS, DATE AND SLOT
        setSelectedParticipants(null)
        setSelectedDate(null)
        setSelectedSlot(null)
        setSelectedAddons(null)
        setStep(2)
    }

    if (!variants)
        return (
            <div className="loading-pulse flex flex-col gap-2.5">
                <div className="h-[54px] w-full rounded-[4px] bg-zinc-100" />
                <div className="h-[54px] w-full rounded-[4px] bg-zinc-100" />
            </div>
        )

    return (
        <div className="min-h-[50dvh]" {...handler}>
            {variants &&
                variants.map((v, index) => (
                    <div
                        key={index}
                        className={`flex w-full flex-col gap-0 rounded-[4px] border ${index > 0 && 'mt-2.5'} ${
                            selectedVariant?.id === v.id ? 'border-zinc-600' : 'border-zinc-300 hover:!border-zinc-600'
                        }`}
                    >
                        <div className="flex max-h-[66px] items-center justify-between gap-3 px-4">
                            <button
                                className="group flex w-full cursor-pointer items-center gap-3.5 py-[18px]"
                                onClick={(e) => handleClick(e as any, v)}
                            >
                                <div
                                    className={clsx(
                                        'h-5 w-5 rounded-full border-zinc-700',
                                        selectedVariant?.id === v.id
                                            ? 'border-[1.5px]'
                                            : 'border-[1px] bg-white group-hover:!border-[1.5px]'
                                    )}
                                >
                                    <div
                                        className={clsx(
                                            'h-full w-full rounded-full p-0.5',
                                            selectedVariant?.id === v.id
                                                ? 'border-[4px] border-white bg-gray-800'
                                                : 'bg-white group-hover:!border-[4px] group-hover:!border-white group-hover:!bg-gray-800'
                                        )}
                                    />
                                </div>

                                <div className="flex max-w-[235px] flex-col items-start gap-0.5 screen600:max-w-[225px]">
                                    <div className="truncate text-[14.5px] leading-5">
                                        {formatDuration(v.formatted_duration!, t)}
                                        {v.output_name && (
                                            <Fragment>
                                                <span className="ml-[5px] mr-2">•</span>
                                                {v.output_name}
                                            </Fragment>
                                        )}
                                    </div>
                                    <div className="flex max-w-[175px] truncate text-[12.5px] leading-[16px] text-gray-500">
                                        <span>{t(v.type)}</span>{v.instant_booking && (
                                        <BoltIcon className="ml-2 w-[10px] text-gray-500" />
                                    )}
                                    </div>
                                </div>
                            </button>
                            <button
                                className="cursor-pointer rounded-full bg-gray-50 p-2 hover:!bg-zinc-100"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    if (expandedVariantId !== v.id.toString()) {
                                        setExpandedVariantId(v.id.toString())
                                    } else {
                                        setExpandedVariantId('')
                                    }
                                }}
                            >
                                <ChevronDownIcon
                                    className={`transition-all duration-100 ${expandedVariantId === v.id.toString() ? 'rotate-180' : ''}  h-4 w-4`}
                                />
                            </button>
                        </div>
                        <div className="px-4">
                            {expandedVariantId === v.id.toString() &&
                                ((v.partner_price_description && !config.is_holidoit_network_operator) ||
                                    (config.is_holidoit_network_operator && v.price_description) ||
                                    v.description) && (
                                    <div className="mx-1 mb-3.5 mt-0.5 flex max-h-[170px] flex-col gap-2.5 overflow-y-scroll pr-1 text-sm leading-[21px]">
                                        {v.description && v.description.length > 0 && (
                                            <div className="space-y-1.5 text-zinc-500">
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: parseHtmlStrict(v.description) }}
                                                />
                                            </div>
                                        )}
                                        {config.is_holidoit_network_operator &&
                                            v.price_description &&
                                            v.price_description.length > 0 && (
                                                <div className="space-y-[5px]">
                                                    <div className="font-medium">{t('Prices')}</div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: parseHtmlStrict(v.price_description),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        {!config.is_holidoit_network_operator &&
                                            v.partner_price_description &&
                                            v.partner_price_description.length > 0 && (
                                                <div className="space-y-[5px]">
                                                    <div className="font-medium">{t('Prices')}</div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: parseHtmlStrict(v.partner_price_description),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                )}
                        </div>
                    </div>
                ))}
        </div>
    )
}
export { BBVariant }
