const BASE_URL = import.meta.env.BASE_API_URL

export interface ApiConfig {
    endpoints: {
        backend: {
            experiencePicker: Record<string, string>
            bookingBox: Record<string, string>
            checkout: Record<string, string>
            bookingDetails: Record<string, string>
        }
        frontend: {
            experiencePicker: Record<string, string>
            bookingBox: Record<string, string>
            checkout: Record<string, string>
            bookingDetails: Record<string, string>
        }
    }
}

const api = {
    endpoints: {
        backend: {
            // Embed Config
            embed: {
                getConfig: `${BASE_URL}/partner/v0/embed-config/:embedId/get`,
            },

            // Experiences
            experiencePicker: {
                getPartnerExperiences: `${BASE_URL}/api/v0/partner/:uuid/experiences/`,
                getDetailedExperience: `${BASE_URL}/api/v2/experiences/partner/:slug`,
                getReviews: `${BASE_URL}/api/v0/experiences/:id/reviews`,
            },

            // Booking Box
            bookingBox: {
                getVariants: `${BASE_URL}/api/v1/experiences/:slug/experience_variants`,
                variantCapacities: `${BASE_URL}/api/v2/experience_variants/:id/capacities`,
                variantAvailabilities: `${BASE_URL}/api/v2/experience_variants/:id/availabilities`,
                variantDaySlots: `${BASE_URL}/api/v2/experience_variants/:id/availabilities/day`,
                variantEmbedDaySlots: `${BASE_URL}/api/v2/experience_variants/:variantId/:embedId/availabilities/day`,
                variantAddons: `${BASE_URL}/api/v0/experience_variants/:id/addons`,
                createBooking: `${BASE_URL}/api/v1/experience_variants/:id/bookings/create`,
                createEmbedBooking: `${BASE_URL}/api/v1/experience_variants/:variantId/:embedId/bookings/create`,
                createOpenEmbedBooking: `${BASE_URL}/api/v2/embed/:embedId/experience_variants/:variantId/open-bookings/create`,
                getOpenBookingPrice: `${BASE_URL}/api/v2/embed/:embedId/experience_variants/:variantId/open-bookings`,
            },

            // Payment & Checkout
            checkout: {
                bookingRecap: `${BASE_URL}/payment/v2/bookings/checkout/:code/embed?channel=EMBED_PLUGIN`,
                openBookingRecap: `${BASE_URL}/api/v0/open-bookings/:code/checkout/`,
                customerInfo: `${BASE_URL}/payment/v2/bookings/checkout/:code/customer-info`,
                stripeCreateOrder: `${BASE_URL}/payment/v1/stripe/partner-direct-bookings/:embedId/orders/:code/create/`,
                stripeConfirmOrder: `${BASE_URL}/payment/v1/stripe/partner-direct-bookings/:embedId/orders/:intentId/confirm/`,
                paypalGenerateToken: `${BASE_URL}/payment/v2/paypal/client-token/`,
                paypalCreateOrder: `${BASE_URL}/payment/v2/paypal/partner-direct-bookings/:embedId/orders/:code/create/`,
                paypalConfirmOrder: `${BASE_URL}/payment/v2/paypal/partner-direct-bookings/:embedId/orders/:orderId/confirm/`,
                thankYouForBooking: `${BASE_URL}/payment/v3/bookings/pending/:code/`,
                cancelBooking: `${BASE_URL}/booking/v1/bookings/:code/checkout/delete`,
                openBookingRecipient: `${BASE_URL}/api/v0/open-bookings/:code/recipient-info`,
                openBookingCustomerInfo: `${BASE_URL}/api/v0/open-bookings/:code/buyer-info`,
                openBookingStripeCreateOrder: `${BASE_URL}/payment/v1/stripe/open_booking/:code/create/`,
                openBookingStripeConfirmOrder: `${BASE_URL}/payment/v1/stripe/open_booking/:intentId/confirm/`,
                openBookingPaypalCreateOrder: `${BASE_URL}/payment/v1/paypal/open-booking/:code/create/`,
                openBookingPaypalConfirmOrder: `${BASE_URL}/payment/v1/paypal/open-booking/:orderId/confirm/`,
                bookingDiscountValidation: `${BASE_URL}/payment/v1/bookings/:code/discounts/validate`,
                skipPayment: `${BASE_URL}/payment/v1/embed-config/:embed_id/orders/:id/discounted-confirm/`,
                partnerBookingPricing: `${BASE_URL}/payment/v0/bookings/:code/partner/price-details`,
            },

            // Thank-you
            bookingDetails: {
                sendEmailOfBookingRequest: `${BASE_URL}/booking/v0/bookings/customer/:code/share-info/`,
                sendEmailOfBookingDetails: `${BASE_URL}booking/v0/bookings/customer/:code/share-details/`,
            },
        },

        // frontend: {
        //     experiencePicker: {},
        //
        //     bookingBox: {
        //         variantCapacities: '/api/experience/:id/capacities',
        //         variantAvailabilities: '/api/experience/:id/availabilities',
        //         variantDaySlots: '/api/experience/:id/slots',
        //         variantAddons: '/api/experience/:id/addons',
        //         createBooking: '/api/experience/:id/booking',
        //         getOpenBookingPrice: '/api/experience/:id/open-booking-price',
        //         createOpenBooking: '/api/:accessToken/experience_variants/:id/open-booking/create',
        //     },
        //
        //     checkout: {
        //         openBookingRecap: '/api/open-booking/:code/checkout-info',
        //     },
        //
        //     bookingDetails: {},
        // },

        // Booking management
        // ...
    },
}

export { api }
