import clsx from 'clsx'
import { Fragment, useState } from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ChevronLeft, ChevronRight } from '../icons/icons'

interface CarouselProps {
    video?: string
    images: string[]
    arrowClasses?: {
        span?: string
        arrows?: string
    }
    eagerLoadFirstImage?: boolean
    hideArrows?: boolean
    showMobileArrows?: boolean
    buttonClasses?: {
        active?: string
        inactive?: string
    }
    containerClasses?: string
    outsideContainerClasses?: string
    id?: string | number
    showMoreSlideBody?: JSX.Element
}

export const defaultActiveClasses =
    'screen600:enabled:!opacity-100 group-hover:!opacity-100 group-hover:opacity-100 py-4 px-1.5 z-[5]'

export const spanClasses =
    'grid active:!scale-[0.97] hover:!scale-105 rounded-full border-2 screen600:!border-none screen600:enabled:!bg-gray-50/60 !bg-white border-zinc-300 aspect-square !h-8 !w-8 place-items-center'

export const arrowsClasses = 'h-[18px] w-[18px] screen600:h-[21px] screen600:w-[21px] text-zinc-700'

export const defaultInactiveClasses =
    'screen600:disabled:!opacity-30 group-hover:!opacity-30 text-gray-400 hover:disabled:!opacity-40'

export const defaultContainerClasses = 'bg-zinc-100 aspect-square'

function CarouselGallery({
    video,
    images,
    arrowClasses = {
        span: spanClasses,
        arrows: arrowsClasses,
    },
    buttonClasses = {
        active: defaultActiveClasses,
        inactive: defaultInactiveClasses,
    },
    eagerLoadFirstImage = true,
    hideArrows = false,
    showMobileArrows = false,
    containerClasses = defaultContainerClasses,
    outsideContainerClasses,
    id = 1,
    showMoreSlideBody,
}: CarouselProps) {
    const [isBeginning, setIsBeginning] = useState(true)
    const [isEnd, setIsEnd] = useState(false)

    return (
        <div className={clsx('group relative z-50 h-full w-full', outsideContainerClasses)}>
            {images.length > 1 && (
                <Fragment>
                    <button
                        id={'next' + id}
                        className={clsx(
                            buttonClasses.active,
                            'absolute right-1 top-1/2 -translate-y-1/2 py-2 transition',
                            isEnd ? 'hide' : ''
                        )}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <span className={arrowClasses.span}>
                            <ChevronRight className={arrowClasses.arrows} />
                        </span>
                    </button>

                    <button
                        id={'prev' + id}
                        className={clsx(
                            buttonClasses.active,
                            'absolute left-1 top-1/2 -translate-y-1/2 py-2 transition',
                            isBeginning ? 'hide' : ''
                        )}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <span className={arrowClasses.span}>
                            <ChevronLeft className={arrowClasses.arrows} />
                        </span>
                    </button>
                </Fragment>
            )}

            <Swiper
                pagination={{
                    dynamicBullets: true,
                    dynamicMainBullets: 3,
                    renderBullet: (_, className) => {
                        return `<span class="rounded-full transition ${className}"></span>`
                    },
                }}
                modules={[Pagination, Navigation]}
                navigation={{
                    nextEl: '#next' + id,
                    prevEl: '#prev' + id,
                    disabledClass: buttonClasses.inactive,
                }}
                slidesPerView={1}
                className={clsx('relative h-full w-full', containerClasses)}
                onSlideChange={(swiper) => {
                    setIsBeginning(swiper.isBeginning)
                    setIsEnd(swiper.isEnd)
                }}
            >
                {images.map((url, i) => (
                    <SwiperSlide key={i} className="relative flex h-full w-full items-center justify-center">
                        <img
                            loading={eagerLoadFirstImage && i === 0 ? 'eager' : 'lazy'}
                            className="h-full w-full object-cover"
                            src={url}
                            alt=""
                        />
                    </SwiperSlide>
                ))}
                {/* {showMoreSlideBody && (
                    <SwiperSlide key={images.length} className="relative h-full w-full">
                        {showMoreSlideBody}
                    </SwiperSlide>
                )} */}
            </Swiper>
        </div>
    )
}

export { CarouselGallery }
