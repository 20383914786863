import { createContext, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { BookingEmbedConfig, ExperiencePickerStep, ExperienceType } from '../types'

interface ExperiencePickerProps {
    config: BookingEmbedConfig
    handleGoToNextStep: () => void
    handleGoBack: () => void
}

interface ExperiencePickerContext extends ExperiencePickerProps {
    experience: ExperienceType | null
    setExperience: React.Dispatch<SetStateAction<ExperienceType | null>>
    experiences: ExperienceType[] | undefined
    step: ExperiencePickerStep
    setStep: React.Dispatch<SetStateAction<ExperiencePickerStep>>
}

const BaseContext = createContext<ExperiencePickerContext | null>(null)

export default BaseContext

export const EPProvider: React.FC<{
    children: ReactNode
    props: ExperiencePickerProps
}> = ({ children, props }) => {
    const { config, handleGoToNextStep, handleGoBack } = props

    const [experience, setExperience] = useState<ExperienceType | null>(
        // config.experiences?.length === 1 ? config.experiences[0] : null
        null
    )

    const [step, setStep] = useState<ExperiencePickerStep>(ExperiencePickerStep.HOME)

    let experiences: ExperienceType[] = config.experiences ?? []

    // useEffect(() => {
    //     if (experience) {
    //         handleGoToNextStep()
    //     }
    // }, [experience])

    // useEffect(() => {
    //     if (config.experiences && config.experiences.length === 1) {
    //         setExperience(config.experiences[0])
    //         handleGoToNextStep()
    //     }
    // }, [config])

    return (
        <BaseContext.Provider
            value={{
                config,
                handleGoToNextStep,
                experience,
                setExperience,
                experiences,
                step,
                setStep,
                handleGoBack
            }}
        >
            {children}
        </BaseContext.Provider>
    )
}

export const useExperiencePickerContext = () => {
    const baseContext = useContext(BaseContext)

    if (!baseContext) {
        throw new Error('baseContext has to be used within <BaseProvider>')
    }

    return baseContext
}
