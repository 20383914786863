import dayjs from 'dayjs'
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { safeHtml } from 'html5parser'
import { Dispatch, Fragment, SetStateAction } from 'react'
import parse from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import {
    AddonRequestType,
    Allocation,
    AllocationRule,
    CheckoutFlowType,
    COBookingInfo,
    COProductBooking,
    IBBAddon,
    IJsonAllocation,
    Languages,
    locales,
    Prices,
} from '../types'
import { VAT_MULTIPLIER } from '../BookingBox/BBRecap'
import { api } from '../api'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)

const standardTimeZone = 'Europe/Rome'

// export const createShadow = (embedId: string, styleSheet?: string) => {
//     const div = document.createElement('div')
//     div.className = `HolidoitEmbed${embedId}`
//     const shadow = div.attachShadow({ mode: 'open' })
//     if (styleSheet) {
//         const styleElement = Object.assign(document.createElement('style'), {
//             innerText: styleSheet,
//         })
//         shadow.appendChild(styleElement)
//     }
//     document.body.appendChild(div)
//     return shadow
// }

export function isLocalhost() {
    if (typeof window === 'undefined') return false
    return (
        window.location.hostname === 'localhost' ||
        window.location.hostname.includes('ngrok') ||
        window.location.hostname === '127.0.0.1'
    )
}

// Function to get the current domain, stripping subdomains except in certain cases
export const getCurrentDomain = () => {
    if (typeof window === 'undefined') {
        return 'no window'
    } else {
        const hostname = window.location.hostname
        const parts = hostname.split('.').reverse() // Reverse to start checking from the TLD
        if (parts != null && parts.length > 1) {
            let domain = parts[1] + '.' + parts[0] // Basic domain parts, e.g., example.com

            // Handle special cases for country code TLDs like .co.uk, .com.au
            if (parts.length > 2 && parts[1].match(/^(co|com|org|net|gov|edu)$/i)) {
                domain = parts[2] + '.' + domain
            }
            return domain
        }
        return hostname
    }
}

export function debugLog(...args: any[]) {
    if (isLocalhost()) {
        console.log(...args)
    }
}

export function toParams(obj: Record<string, any>, explodeArrays: boolean = false): string {
    if (!obj) {
        return ''
    }

    function handleVal(val: any): string {
        // if (dayjs.isDayjs(val)) {
        //     return encodeURIComponent(val.format('YYYY-MM-DD'))
        // }
        val = typeof val === 'object' ? JSON.stringify(val) : val
        return encodeURIComponent(val)
    }

    return Object.entries(obj)
        .filter((item) => item[1] != undefined && item[1] != null)
        .reduce(
            (acc, [key, val]) => {
                /**
                 *  query parameter arrays can be handled in two ways
                 *  either they are encoded as a single query parameter
                 *    a=[1, 2] => a=%5B1%2C2%5D
                 *  or they are "exploded" so each item in the array is sent separately
                 *    a=[1, 2] => a=1&a=2
                 **/
                if (explodeArrays && Array.isArray(val)) {
                    val.forEach((v) => acc.push([key, v]))
                } else {
                    acc.push([key, val])
                }

                return acc
            },
            [] as [string, any][]
        )
        .map(([key, val]) => `${key}=${handleVal(val)}`)
        .join('&')
}

// https://stackoverflow.com/questions/25421233/javascript-removing-undefined-fields-from-an-object
export function objectClean<T extends Record<string | number | symbol, unknown>>(obj: T): T {
    const response = { ...obj }
    Object.keys(response).forEach((key) => {
        if (response[key] === undefined) {
            delete response[key]
        }
    })
    return response
}

export function getCookie(name: string): string | null {
    let cookieValue: string | null = null
    if (document.cookie && document.cookie !== '') {
        for (let cookie of document.cookie.split(';')) {
            cookie = cookie.trim()
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                break
            }
        }
    }
    return cookieValue
}

export function setToken(access_token: string, expires_in?: number): void {
    document.cookie = `_holidoit_partner_access_token=${access_token}; path=/; max-age=${
        expires_in ?? 3600 * 24 * 30
    }; samesite=strict`
}

export async function getJSONOrThrow(response: Response): Promise<any> {
    try {
        return await response.json()
    } catch (e) {
        return { statusText: response.statusText, status: response.status }
    }
}

export const normalizeUrl = (url: string): string => {
    if (url.indexOf('http') !== 0) {
        if (!url.startsWith('/')) {
            url = '/' + url
        }

        url = url + (url.indexOf('?') === -1 && url[url.length - 1] !== '/' ? '/' : '')
    }
    return url
}

export const prepareUrl = (url: string): string => {
    let output = normalizeUrl(url)

    // const sharingContext = {
    //     accessToken: 'abc',
    // }

    // if (sharingContext && sharingContext.accessToken) {
    //     output =
    //         output +
    //         (output.indexOf('?') === -1 ? '?' : '&') +
    //         encodeParams({
    //             sharing_access_token: sharingContext.accessToken,
    //         })
    // }

    return output
}

// returns the duration in the format "HH ore MM min" (e.g. "1 ora 30 min")
export const formatDuration = (d: string, t: CallableFunction) => {
    const hourMinutes = d.split('h')
    let duration = ''
    let ore = ''
    let minuti = ''
    if (hourMinutes[0] === '0') {
        minuti = `${hourMinutes[1]} min`
    } else if (hourMinutes[0] === '1') {
        ore = `${hourMinutes[0]} ${t('hour')}`
    } else {
        ore = `${hourMinutes[0]} ${t('hours')}`
    }
    if (hourMinutes[1] === '0') {
        minuti = ''
    } else {
        minuti = `${hourMinutes[1]} min`
    }
    duration = ore.concat(' ', minuti)
    return duration
}

// returns the date in the format "Weekday DD Month YYYY" (e.g. "Lunedì 10 Giugno 2021")
export const formatDate = (d: Date, language: string = 'it', timezone?: string) => {
    const tz = timezone ?? standardTimeZone
    const utcTime = dayjs.utc(d)
    const aux = dayjs.tz(utcTime, tz)
    const { months, weekdays } = locales[language]
    let strDay = weekdays![aux.day()]
    let strMonth = months![aux.month()]
    strDay = strDay[0].toUpperCase() + strDay.substring(1)
    strMonth = strMonth[0].toUpperCase() + strMonth.substring(1)
    return `${strDay.slice(0, 3)} ${aux.date()} ${strMonth} ${aux.year()}`
}

export const formatDate_withFormat = (d: Date, language: Languages, format: string) => {
    dayjs.locale(language)

    const utcTime = dayjs.utc(d)
    return dayjs.tz(utcTime).format(format)
}

/**
 *
 * @param d The date
 * @returns formatted as "27 agosto"
 */
export const dayExtendedMonthDate = (d: dayjs.Dayjs, language: string = 'it', timezone?: string) => {
    const { months, weekdays } = locales[language]
    const tz = timezone ?? standardTimeZone

    // Ensure `d` is converted to the correct timezone
    const aux = d.tz(tz)

    let strDay = weekdays![aux.day()]
    return `${strDay} ${aux.date()} ${months![aux.month()]}`
}

// returns the date in the format "DD Month" (e.g. "10 Giu")
export const formatDateReduced = (d: Date | string, language: string = 'it', timezone?: string) => {
    const tz = timezone ?? standardTimeZone
    const { months, weekdays } = locales[language]
    const utcTime = dayjs.utc(d)
    const aux = dayjs.tz(utcTime, tz)
    let strMonth = months![aux.month()].substring(0, 3)
    strMonth = strMonth[0].toUpperCase() + strMonth.slice(1).toLowerCase()
    strMonth = strMonth.substring(0, 3)
    return aux.format('DD') + ' ' + strMonth
}

// returns the date in the format "DD Month" (e.g. "Lun 10 Giu")
export const formatDateDayReduced = (d: Date | string, language: string = 'it', timezone?: string) => {
    const tz = timezone ?? standardTimeZone
    const { months, weekdays } = locales[language]

    const utcTime = dayjs.utc(d)
    const aux = dayjs.tz(utcTime, tz)

    // Get a three-letter day of the week abbreviation, e.g., "Mon"
    let strDay = weekdays![aux.day()]
    let strDayOfWeek = strDay.substring(0, 3)
    strDayOfWeek = strDayOfWeek[0].toUpperCase() + strDayOfWeek.slice(1)

    // Get a three-letter month abbreviation and ensure it's properly formatted
    let strMonth = months![aux.month()].substring(0, 3).toLowerCase()
    strMonth = strMonth[0].toUpperCase() + strMonth.slice(1)

    // Combine formatted day of the week, date, and month
    return `${strDayOfWeek} ${aux.format('DD')} ${strMonth}`
}

// returns the time in the format "HH:MM" (e.g. "10:30")
export const formatTime = (d: Date, timezone?: string) => {
    const tz = timezone ?? standardTimeZone
    const utcTime = dayjs.utc(d)
    return dayjs.tz(utcTime, tz).format('H:mm')
}

export const formatEndingTime = (d: Date, duration: string, timezone?: string) => {
    const tz = timezone ?? standardTimeZone

    const utcTime = dayjs.utc(d)
    const aux = dayjs.tz(utcTime, tz)
    const [startingHour, startingMinutes] = aux.format('HH:mm').split(':').map(Number)
    const [durationHours, durationMinutes] = duration.split('h').map(Number)

    let endingHour = startingHour + durationHours
    let endingMinutes = startingMinutes + durationMinutes

    // Handle minute overflow
    if (endingMinutes >= 60) {
        endingHour += 1
        endingMinutes -= 60
    }

    // Handle hour overflow
    if (endingHour > 23) {
        endingHour -= 24
    }

    return `${String(endingHour).padStart(2, '0')}:${String(endingMinutes).padStart(2, '0')}`
}

// Booking Box

export function handleCurrentDate(currentDate: dayjs.Dayjs) {
    const startOfMonth = currentDate.startOf('month')
    const endOfMonth = currentDate.endOf('month')
    let startOfRange =
        startOfMonth.day() === 0
            ? startOfMonth.subtract(6, 'days')
            : startOfMonth.subtract(startOfMonth.day() - 1, 'days')
    const endOfRange = endOfMonth.day() === 0 ? endOfMonth.add(1, 'days') : endOfMonth.add(8 - endOfMonth.day(), 'days')
    return [startOfRange, endOfRange]
}

// function to set the range of dates
export function handleChangeCurrentDate(startOfRange: dayjs.Dayjs, endOfRange: dayjs.Dayjs) {
    if (
        startOfRange.toDate().getTime() === new Date().getTime() ||
        startOfRange.toDate().getTime() < new Date().getTime()
    ) {
        const today = new Date()
        //   const notice_period = experience.notice_period;
        // const numberOfDaysToAdd = (notice_period ?? 48) / 24;
        const numberOfDaysToAdd = 0
        const result = today.setDate(today.getDate() + numberOfDaysToAdd)
        const date_from = dayjs(new Date(result))
        startOfRange = date_from
    }

    // If endOfRange is in the next year, I set the dateTo to the last day of the year
    const dateTo =
        endOfRange.year() > startOfRange.year() ? `${startOfRange.year()}-12-31` : endOfRange.format('YYYY-MM-DD')

    const dateFrom = startOfRange.format('YYYY-MM-DD')

    return [dateFrom, dateTo]
}

export function handleChangeCurrentDateV2(currentDate: dayjs.Dayjs) {
    const dateFrom = currentDate.format('YYYY-MM-DD')
    const dateTo = currentDate.endOf('month').format('YYYY-MM-DD')
    return [dateFrom, dateTo]
}

// function to set the range of dates
export function handleDaysInRange(startOfRange: dayjs.Dayjs, endOfRange: dayjs.Dayjs) {
    const daysInRange_ = Array.from<Number, dayjs.Dayjs>({ length: endOfRange.diff(startOfRange, 'days') }, (_, i) =>
        startOfRange.add(i, 'days')
    )
    const res: dayjs.Dayjs[][] = []
    for (let i = 0; i < daysInRange_.length; i += 7) {
        const chunk = daysInRange_.slice(i, i + 7)
        res.push(chunk)
    }
    return res
}

const options = {
    replace: (node: any) => {
        if (node.name === 'li') {
            const text = node.children
                .map((child: { type: string; children: { data: any }[]; data: any }) =>
                    child.type === 'tag' ? child.children[0]?.data : child.data
                )
                .join('')

            return (
                <Fragment key={node.attribs.key}>
                    &bull;&nbsp;&nbsp;&nbsp;&nbsp;
                    {text}
                    <br />
                </Fragment>
            )
        }
    },
}

interface AllocationsDictionary {
    [key: number]: Allocation
}

export function validateAllocations(allocations: Allocation[], rules: AllocationRule[]): boolean {
    // Transform allocations array into a dictionary for faster lookup
    const allocationDict = allocations.reduce((acc, item) => {
        acc[item.id] = item
        return acc
    }, {} as AllocationsDictionary)

    // Check each rule to see if the allocations are valid
    for (let rule of rules) {
        let quantitySum = 0
        for (let participant_id of rule.participant_ids) {
            // If participant_id is not in allocationDict, default the quantity to 0
            quantitySum += allocationDict[participant_id]?.quantity || 0
        }
        // If the sum exceeds the maximum quantity, the allocations are invalid
        if (quantitySum > rule.max_quantity) {
            return false // Return false immediately upon finding an invalid case
        }
    }

    // If all rules are satisfied, the allocations are valid
    return true
}

export function isAllocationsFull(
    allocationToCheckId: number,
    actualAllocations: Allocation[],
    rules: AllocationRule[]
): boolean {
    const allocationIndex = actualAllocations.findIndex((allocation) => allocation.id === allocationToCheckId)

    if (allocationIndex === -1) {
        // If the allocation isn't found, it's safe to say it's not full since it doesn't exist.
        return false
    }

    // Create a new array with the same allocations
    let newAllocations = [...actualAllocations]

    // Adjust the quantity of the specific allocation to check
    let allocationToCheck = { ...newAllocations[allocationIndex] }
    allocationToCheck.quantity += 1 // Temporarily increase the quantity for the check
    newAllocations[allocationIndex] = allocationToCheck // Replace the allocation in the new array

    // Use the modified array for validation
    return !validateAllocations(newAllocations, rules)
}

export const getTotalParticipants = (allocations: Allocation[]) => {
    return allocations.reduce((total, allocation) => {
        return total + allocation.quantity
    }, 0)
}

export function stringifyParticipants(participants: Allocation[] | null): string {
    if (!participants) return ''

    return JSON.stringify(
        participants
            ?.filter((el) => el.quantity !== 0)
            .map((el) => ({
                type_id: el.id,
                quantity: el.quantity,
            })) ?? []
    )
}

export function formatAddons(addons: IBBAddon[]): AddonRequestType[] {
    return addons
        ?.filter((a) => a.id && a.qt)
        .map((a) => {
            return {
                id: a.id,
                qt: a.qt,
            }
        })
}

export const formatJsonAddon = (addons: any, quantity_attribute?: string) => {
    quantity_attribute = quantity_attribute ? quantity_attribute : 'quantity'
    let addons_string = ''
    addons.map((addon: any, index: number) => {
        const quantity = addon[quantity_attribute]
        addons_string += `${quantity} ${addon.name}`
        if (index < addons.length - 1) {
            addons_string += ', '
        }
    })
    return addons_string
}

// returns the string version of allocations
export const formatJsonAllocations = (allocations: IJsonAllocation[], language: string = 'it') => {
    const participantQuantities: { [key: string]: number } = {} // Define the type of participantQuantities

    try {
        for (let allocation of allocations) {
            for (let participant of allocation.participants ?? []) {
                // Iterate over participants, not their keys
                if (participantQuantities[participant.name] === undefined) {
                    participantQuantities[participant.name] = participant.quantity
                } else {
                    participantQuantities[participant.name] += participant.quantity
                }
            }
        }
    } catch (err) {
        return ''
    }

    return Object.entries(participantQuantities)
        .map(([name, quantity]) => `${quantity} ${quantity > 1 ? pluralize(name, language) : name}`)
        .join(', ')
}

export function formatAndStrigifyAddons(addons: IBBAddon[]): string {
    return JSON.stringify(
        addons
            ?.filter((a) => a.id && a.qt)
            .map((a) => {
                return {
                    id: a.id,
                    qt: a.qt,
                }
            })
    )
}

export function prepareParticipantsForCreateBooking(
    participants: Allocation[] | null
): { type_id: number; quantity: number }[] {
    if (!participants) {
        return []
    } else {
        return (
            participants
                ?.filter((el) => el.quantity !== 0)
                .map((el) => ({
                    type_id: el.id,
                    quantity: el.quantity,
                })) ?? []
        )
    }
}

export const getParticipantsString = (allocations: Allocation[]) => {
    const participants: any = []

    if (allocations) {
        allocations.map((allocation) => {
            if (allocation.quantity === 1) {
                participants.push(`${allocation.quantity} ${allocation.name}`)
            } else {
                participants.push(`${allocation.quantity} ${pluralizeItalian(allocation.name!)}`)
            }
        })
    }
    return participants.join(', ')
}

export function pluralize(singularName: string, language: string = 'it') {
    if (language === 'en') {
        return pluralizeEnglish(singularName)
    } else {
        return pluralizeItalian(singularName)
    }
}

export function pluralizeItalian(singularName: string) {
    // remove any leading or trailing whitespace
    const str = singularName.trim()
    // split the string into an array of words
    const words = str.split(/\s+/)

    if (words.length > 1) {
        return singularName
    }

    const lastLetter: string = singularName.charAt(singularName.length - 1)

    if (lastLetter === 'e' && singularName.charAt(singularName.length - 2) !== 'i') {
        return singularName.slice(0, -1) + 'i'
    } else if (lastLetter === 'o') {
        return singularName.slice(0, -1) + 'i'
    } else if (lastLetter === 'a') {
        return singularName.slice(0, -1) + 'e'
    } else if (lastLetter === 'e') {
        return singularName.slice(0, -1) + 'i'
    } else if (lastLetter === 'i') {
        return singularName.slice(0, -1) + 'i'
    }
    return singularName
}

export function pluralizeEnglish(singularName: string) {
    // auto generated
    if (typeof singularName !== 'string') {
        throw new TypeError('Expected a string')
    }

    // Common irregular nouns
    const irregularNouns = {
        child: 'children',
        man: 'men',
        woman: 'women',
        tooth: 'teeth',
        foot: 'feet',
        person: 'people',
        mouse: 'mice',
        goose: 'geese',
        ox: 'oxen',
    }

    // Check for irregular nouns
    if (irregularNouns[singularName.toLowerCase()]) {
        return irregularNouns[singularName.toLowerCase()]
    }

    // Rules for pluralization
    const endsWith = singularName.slice(-1)
    const endsWithTwo = singularName.slice(-2)

    if (endsWith === 'y' && !['a', 'e', 'i', 'o', 'u'].includes(singularName.slice(-2, -1))) {
        return singularName.slice(0, -1) + 'ies'
    } else if (['s', 'sh', 'ch', 'x', 'z'].includes(endsWith) || ['sh', 'ch'].includes(endsWithTwo)) {
        return singularName + 'es'
    } else if (endsWith === 'f') {
        return singularName.slice(0, -1) + 'ves'
    } else if (endsWithTwo === 'fe') {
        return singularName.slice(0, -2) + 'ves'
    } else {
        return singularName + 's'
    }
}

export function customRound(value: number): number {
    // Round to one decimal place first
    let roundedToTenth = Math.round(value * 10) / 10
    // Round the result to two decimal places
    return Math.round(roundedToTenth * 100) / 100
}

export const minimumPriceText = (
    entity: 'holidoit' | 'partner',
    price: { holidoit: number; partner: number },
    t: CallableFunction,
    isGroup: boolean = false,
    isFixed: boolean = false,
    textOverride?: { holidoit: string; partner: string },
    currencySimbol: string = '€'
) => {
    const standardTextBit =
        entity === 'holidoit' && !price.holidoit
            ? ''
            : entity === 'partner' && !price.partner
              ? ''
              : (!isFixed ? t('from') + ' ' : '') +
                Number(entity === 'holidoit' ? price.holidoit : price.partner).toFixed(0) +
                currencySimbol +
                (!isGroup ? ' ' + t('perPerson') : ' ' + t('perGroup'))

    const overrideTextBit = entity === 'holidoit' ? textOverride.holidoit : textOverride.partner
    return overrideTextBit && overrideTextBit.length > 0 ? overrideTextBit : standardTextBit
}

export const parseHtmlStrict = (html: string, strict: boolean = true) =>
    safeHtml(html, {
        allowedTags: strict
            ? [
                  'p',
                  'ul',
                  'h2',
                  'h3',
                  'h4',
                  'h5',
                  'h6',
                  'div',
                  'hr',
                  'li',
                  'ol',
                  'br',
                  'col',
                  'colgroup',
                  'table',
                  'tbody',
                  'td',
                  'tfoot',
                  'th',
                  'thead',
                  'tr',
                  'a',
                  'span',
                  'u',
                  'p',
              ]
            : undefined,

        allowedAttrs: ['href', 'class', 'style', 'target'],
    })

// returns the price updated with the addons
export const totalPrice = (
    price: Prices,
    addons: IBBAddon[],
    customerFee: number,
    isHolidoitNetworkOperator: boolean = false
) => {
    let totalPrice = Number(isHolidoitNetworkOperator ? price.holidoit_price : price.partner_price)
    if (addons && addons.length > 0) {
        const addonPrice = addons.reduce((sum, addon) => {
            const addonUnitPrice = isHolidoitNetworkOperator ? addon.holidoit_price : addon.partner_price
            return sum + Number(addonUnitPrice) * (addon.qt ?? 0)
        }, 0)
        totalPrice += addonPrice
    }
    return customRound(totalPrice * (1 + (customerFee * VAT_MULTIPLIER) / 100))
}

export const totalDeposit = (
    price: Prices,
    addons: IBBAddon[],
    customerFee: number,
    isHolidoitNetworkOperator: boolean = false,
    depositPercentage: number
) => {
    // todo: the method doesn't  work for isHolidoitNetworkOperator == true,
    //  at the moment we ignore this scenario

    // total price
    let totalHolidoitPrice = totalPrice(price, addons, customerFee, isHolidoitNetworkOperator)

    let totalPartnerPrice = Number(price.partner_price)
    if (addons && addons.length > 0) {
        const addonPrice = addons.reduce((sum, addon) => {
            const addonUnitPrice = addon.partner_price
            return sum + Number(addonUnitPrice) * (addon.qt ?? 0)
        }, 0)
        totalPartnerPrice += addonPrice
    }
    const fee = totalHolidoitPrice - totalPartnerPrice
    const deposit = totalPartnerPrice * (depositPercentage / 100) + fee
    return customRound(deposit)
}

// --------- Checkout -----------

export function hasCheckoutPage(checkoutFlowType: CheckoutFlowType): boolean {
    return checkoutFlowType === CheckoutFlowType.CHECKOUT_PAGE
}

export function hasCheckout(checkoutFlowType: CheckoutFlowType): boolean {
    return checkoutFlowType !== CheckoutFlowType.NONE
}

export function isInlineCheckout(checkoutFlowType: CheckoutFlowType): boolean {
    return checkoutFlowType === CheckoutFlowType.INLINE
}

export const convertToCOProductBooking: (_: COBookingInfo) => COProductBooking | null = (booking) => {
    if (!booking) return null

    return {
        title: booking?.info?.experience_variant?.experience.name,
        images: [booking?.info?.experience_variant?.cover_image],
        location:
            booking?.info?.experience_variant?.location.city +
            ', ' +
            booking?.info?.experience_variant?.location?.region,
        timeBooking: `${dayjs.tz(new Date(booking?.info?.datetime_from)).format('HH:mm')} - ${dayjs
            .tz(new Date(booking?.info?.datetime_to))
            .format('HH:mm')}`,
        cost: booking?.holidoit_price,
        dateBooking: new Date(booking?.info?.datetime_from),
        cancelationDeadlineDays: (booking?.info?.experience_variant?.experience?.cancellation_period ?? 48) / 24,
        rating: booking?.info?.experience_variant?.experience.average_review,
        numRevisions: booking?.info?.experience_variant?.experience?.total_reviews,
        type: booking?.info?.experience_variant?.type,
        characteristics: {
            description: booking?.info?.experience_variant?.output_name,
            duration: booking?.info?.experience_variant?.formatted_duration,
        },
        expiration_time: new Date(booking?.checkout_expiration_at),
        additional_info: booking?.info?.experience_variant?.additional_info,
        experienceSlug: booking?.info?.experience_variant?.experience.slug,
        addons: booking?.json_addons,
        json_allocations: booking?.json_allocations,
        holidoit_price: booking?.holidoit_price,
        partner_price: booking?.partner_price,
    }
}

// ---------
// Booking storage

// To better track progress of the booking box and checkout,
// we keep track of the bookings the user created in localStorage, to:
// - [ ]  if the user did not create booking, take booking box from last selected options
// - [ ]  if created booking but not paid, recover booking and if time not expired (check date timestamp of when booking created) go to checkout with that booking
// - [ ]  if the user has paid, show a ‘make another booking’ button or ‘send me details again’ when returns to page

//   // Example usage
//   saveBookingCreated({ id: 1, experienceSlug: '...', timeStamp: new Date().toISOString(), bookingOptions: {...}, paymentOptions: {...} });
//   saveBookingPaid({ id: 2, timeStamp: new Date().toISOString() });

// Key for localStorage
export const userBookingsLocalStorageKey = '_holidoit_user_bookings'

interface Booking {
    code: number[]
    timeStamp: string
}

export interface UserBookings {
    bookings: Booking[]
}

// Function to update the user journey data
function updateUserBookings(bookingCode: string, newBooking: Booking | null): void {
    // Check if exists
    let rawUserBookings = localStorage.getItem(userBookingsLocalStorageKey)
    let userBookings: UserBookings

    if (rawUserBookings) {
        userBookings = JSON.parse(rawUserBookings)
    } else {
        userBookings = { bookings: [] }
    }

    // Update data if provided
    if (newBooking) {
        userBookings.bookings.push(newBooking)
    }

    localStorage.setItem(userBookingsLocalStorageKey, JSON.stringify(userBookings))
}

//   export function savePageVisited(newPage: Page): void {
//     updateUserBookings(null, newPage);
//   }

//   export function saveExperienceVisited(newExperience: ExperiencePage): void {
//     updateUserBookings(newExperience, null);
//   }

export function addLangParam(url: string, language: string = 'it') {
    const urlObj = new URL(url)
    urlObj.searchParams.set('lang', language)
    return urlObj.toString()
}

export function fetchWithLanguage(url: string, options: RequestInit & { language?: string } = { language: 'it' }) {
    const urlObj = addLangParam(url, options.language)
    return fetch(urlObj, options)
}

export function checkNullOrUndefined(value: any): boolean {
    return value === null || value === undefined
}

export function checkAnyUndefined(obj) {
    // Check each value in the object
    return Object.values(obj).some((value) => value === undefined || value === null || value === '')
}

export function parseAndFormatDurationISO8601(isoDuration: string, t: CallableFunction): string {
    dayjs.extend(duration)
    dayjs.extend(relativeTime)

    const dur = dayjs.duration(isoDuration)
    const parts: string[] = []
    if (dur.days() > 0) {
        parts.push(`${dur.days()} ${t(dur.days() === 1 ? 'timing.day' : 'timing.days').toLowerCase()}`)
    }
    if (dur.hours() > 0) {
        parts.push(`${dur.hours()} ${t(dur.hours() === 1 ? 'timing.hour' : 'timing.hours').toLowerCase()}`)
    }

    if (dur.minutes() > 0) {
        parts.push(`${dur.minutes()} ${t(dur.minutes() === 1 ? 'timing.minute' : 'timing.minutes').toLowerCase()}`)
    }
    return parts.join(' ')
}

export const capitalize = (s: string) => {
    return s[0].toUpperCase() + s.slice(1).toLowerCase()
}

export const parseAndRenderHtmlStrict = (html: string, strict: boolean = true) =>
    parse(
        sanitizeHtml(html, {
            ...(strict
                ? {
                      allowedTags: [
                          'p',
                          'ul',
                          'h2',
                          'h3',
                          'h4',
                          'h5',
                          'h6',
                          'div',
                          'hr',
                          'li',
                          'ol',
                          'br',
                          'col',
                          'colgroup',
                          'table',
                          'tbody',
                          'td',
                          'tfoot',
                          'th',
                          'thead',
                          'tr',
                      ],
                  }
                : {}),
        }),
        options
    )

export async function cancelCheckoutBooking(
    bookingCode: string,
    setBookingCode: Dispatch<SetStateAction<string | null>>
) {
    fetch(api.endpoints.backend.checkout.cancelBooking.replace(':code', bookingCode), {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((res) => {
        if (res.ok) {
            // Status code is in the range 200-299
            setBookingCode(null)
        }
    })
}
