import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonVariant } from 'src/lib/components/Button'
import { LoadingSpinner } from 'src/lib/components/LoadingSpinner'
import { ChevronRight } from 'src/lib/icons/icons'

const BookButton = ({
    isLoading,
    label,
    onClick,
    bookingCreationError,
}: {
    isLoading: boolean
    label: string
    onClick: any
    bookingCreationError: boolean
}) => {
    const { t } = useTranslation()

    return (
        <div className="w-full">
            <Button
                // variant={ButtonVariant.BLACK}
                variant={ButtonVariant.BLACK}
                disabled={isLoading}
                onClick={onClick}
                className="flex w-full items-center justify-center"
            >
                {!isLoading ? (
                    <Fragment>
                        <span>{label}</span>
                        <ChevronRight className="h-5 w-5" />
                    </Fragment>
                ) : (
                    // <Loading color="black" size="34px" />
                    <LoadingSpinner className="text-white" />
                )}
            </Button>
            {bookingCreationError && (
                <div className="mt-3 flex w-full justify-center text-[13px] text-red-500">
                    {t('errorBookingCreation')}
                </div>
            )}
            {/* <div className="mt-3 flex w-full justify-center text-[13px] text-gray-500 screen600:mb-0 screen600:text-[12.5px]">
                Non riceverai alcun addebito in questa fase
            </div> */}
        </div>
    )
}

export default BookButton

