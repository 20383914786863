import { createContext, ReactNode, useContext, useState } from 'react'

interface AnalyticsProviderProps {
    gtmCode?: string | null
}

interface AnalyticsContextProps extends AnalyticsProviderProps {
    useAnalytics: boolean
}

const BaseContext = createContext<AnalyticsContextProps | null>(null)

export default BaseContext

export const AnalyticsProvider: React.FC<{
    children: ReactNode
    props: AnalyticsProviderProps
}> = ({ children, props }) => {
    const { gtmCode } = props

    const [useAnalytics] = useState<boolean>(!!gtmCode)

    // useEffect(() => {
    //     if (gtmCode) {
    //         const scriptContent = `
    //         (function(w,d,s,l,i){
    //           w[l]=w[l]||[];
    //           w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
    //           var f=d.getElementsByTagName(s)[0],
    //           j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
    //           j.async=true;
    //           j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    //           f.parentNode.insertBefore(j,f);
    //         })(window,document,'script','dataLayer','${gtmCode}');
    //   `;
    //
    //         // Create and append the script element
    //         const script = document.createElement("script");
    //         script.id = "google-tag-manager";
    //         script.async = true;
    //         script.textContent = scriptContent;
    //
    //         document.body.appendChild(script);
    //
    //         return () => {
    //             const existingScript = document.getElementById("google-tag-manager");
    //             if (existingScript) {
    //                 document.body.removeChild(existingScript);
    //             }
    //         };
    //     }
    // }, [gtmCode]);

    return (
        <BaseContext.Provider
            value={{
                useAnalytics,
                gtmCode,
            }}
        >
            {children}
        </BaseContext.Provider>
    )
}

export const useAnalyticsContext = () => {
    const baseContext = useContext(BaseContext)
    if (!baseContext) {
        throw new Error('baseContext has to be used within <BaseProvider>')
    }
    return baseContext
}
