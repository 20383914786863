import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { api } from '../api'
import { useExperiencePickerContext } from '../ExperiencePicker/Context'
import {
    Allocation,
    BOOKING_BOX_TYPE,
    BookingEmbedConfig,
    ExperienceVariantType,
    IBBAddon,
    Slot,
    VariantCapacityResponse,
} from '../types'
import { debugLog, fetchWithLanguage } from 'src/lib/utils'
import { useTranslation } from 'react-i18next'

export interface BBProviderProps {
    config: BookingEmbedConfig
    handleGoBack?: () => void
    handleLastStepSuccess?: () => void
}

export enum BookingStep {
    Variant = 'variant',
    Participants = 'participants',
    Date = 'date',
    Slot = 'slot',
    Addons = 'addons',
    GiftAddons = 'gift_addons',
    Recap = 'recap',
}

export const BookingSteps: Record<string, number> = {
    [BookingStep.Variant]: 1,
    [BookingStep.Participants]: 2,
    [BookingStep.Date]: 3,
    [BookingStep.Slot]: 4,
    [BookingStep.Addons]: 4.5,
    [BookingStep.GiftAddons]: 4.6,
    [BookingStep.Recap]: 5,
}

interface BBContextType extends BBProviderProps {
    mode: BOOKING_BOX_TYPE
    setMode: Dispatch<SetStateAction<BOOKING_BOX_TYPE>>

    variants: ExperienceVariantType[] | null
    variantsCapacities:
        | {
              variantId: number
              capacities: VariantCapacityResponse
          }[]
        | null

    step: (typeof BookingSteps)[BookingStep]
    setStep: Dispatch<SetStateAction<(typeof BookingSteps)[BookingStep]>>

    selectedVariant: ExperienceVariantType | null
    setSelectedVariant: Dispatch<SetStateAction<ExperienceVariantType | null>>

    selectedParticipants: Allocation[] | null
    setSelectedParticipants: Dispatch<SetStateAction<Allocation[] | null>>

    selectedDate: string | null
    setSelectedDate: Dispatch<SetStateAction<string | null>>

    selectedSlot: Slot | null
    setSelectedSlot: Dispatch<SetStateAction<Slot | null>>

    selectedAddons: IBBAddon[] | null
    setSelectedAddons: Dispatch<SetStateAction<IBBAddon[] | null>>

    selectedAddonsGift: IBBAddon[] | null
    setSelectedAddonsGift: Dispatch<SetStateAction<IBBAddon[] | null>>

    bookingCode: string | null
    setBookingCode: Dispatch<SetStateAction<string | null>>

    discountCode: string | null
    setDiscountCode: Dispatch<SetStateAction<string | null>>
}

const BaseContext = createContext<BBContextType | null>(null)

export default BaseContext

export const BBProvider: React.FC<{
    children: ReactNode
    props: BBProviderProps
}> = ({ children, props }) => {
    const { config, handleLastStepSuccess, handleGoBack } = props

    const { experience } = useExperiencePickerContext()

    const [variants, setVariants] = useState<ExperienceVariantType[] | null>(null)
    // const [variants, setVariants] = useState<ExperienceVariantType[] | null>(config.variants ?? null)
    const [variantsCapacities] = useState<
        | {
              variantId: number
              capacities: VariantCapacityResponse
          }[]
        | null
    >(null)
    // >(config.variantsCapacities ?? null)

    const { i18n } = useTranslation()
    const language = i18n.language

    debugLog('language', language)
    debugLog('experience', experience)

    const [mode, setMode] = useState<BOOKING_BOX_TYPE>(BOOKING_BOX_TYPE.BOOKING)

    useEffect(() => {
        if (mode === BOOKING_BOX_TYPE.BOOKING) {
            if (!selectedVariant) {
                setStep(BookingSteps[BookingStep.Variant])
            } else {
                if (!selectedParticipants || selectedParticipants.length === 0) {
                    setStep(BookingSteps[BookingStep.Participants])
                } else {
                    debugLog('selectedDate', selectedDate)
                    debugLog('selectedSlot', selectedSlot)
                    if (!selectedDate) {
                        setStep(BookingSteps[BookingStep.Date])
                    } else {
                        if (!selectedSlot) {
                            setStep(BookingSteps[BookingStep.Slot])
                        } else {
                            setStep(BookingSteps[BookingStep.Recap])
                        }
                    }
                }
            }
        } else if (mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING) {
            if (!selectedVariant) {
                setStep(BookingSteps[BookingStep.Variant])
            } else {
                if (!selectedParticipants || selectedParticipants.length === 0) {
                    setStep(BookingSteps[BookingStep.Participants])
                } else {
                    setStep(BookingSteps[BookingStep.Recap])
                }
            }
        }
    }, [mode])

    // if there are no variants in the initial data, we fetch them
    const { data: apiVariants } = useSWR<ExperienceVariantType[]>(
        experience
            ? [api.endpoints.backend.bookingBox.getVariants.replace(':slug', experience.slug), language, experience]
            : null,
        ([url, language, experience]) =>
            fetchWithLanguage(url, { language })
                .then((res) => res.json())
                .then((data) => {
                    setVariants(data)
                    return data
                }),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    )

    // If there is only one variant, select it and skip the first step
    // UNCOMMENT BELOW TO SKIP VARIANT STEP IF THERE IS ONLY ONE VARIANT
    const [selectedVariant, setSelectedVariant] = useState<ExperienceVariantType | null>(
        // variants?.length === 1 ? variants[0] : null
        null
    )
    const [step, setStep] = useState(
        // variants?.length === 1 ? BookingSteps[BookingStep.Participants] : BookingSteps[BookingStep.Variant]
        BookingSteps[BookingStep.Variant]
    )

    // useEffect(() => {
    //     if (variants && variants.length === 1 && step === BookingSteps[BookingStep.Variant]) {
    //         setSelectedVariant(variants[0])
    //         setStep(BookingSteps[BookingStep.Participants])
    //     }
    // }, [variants])

    const [selectedParticipants, setSelectedParticipants] = useState<Allocation[] | null>(null)

    const [selectedDate, setSelectedDate] = useState<string | null>(null)

    const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null)

    const [selectedAddons, setSelectedAddons] = useState<IBBAddon[] | null>(null)
    const [selectedAddonsGift, setSelectedAddonsGift] = useState<IBBAddon[] | null>(null)

    // update slots when variant / participant / date change
    useEffect(() => {
        if (selectedVariant && selectedParticipants && selectedDate) {
            setSelectedSlot(null)
        }
    }, [selectedVariant, selectedParticipants, selectedDate])

    const [bookingCode, setBookingCode] = useState<string | null>(null)
    const [discountCode, setDiscountCode] = useState<string | null>(null)

    function resetAll() {
        setStep(BookingSteps[BookingStep.Variant])
        setVariants(null)
        setSelectedVariant(null)
        setSelectedParticipants(null)
        setSelectedAddons(null)
        setSelectedDate(null)
        setSelectedSlot(null)
        setMode(BOOKING_BOX_TYPE.BOOKING)
    }

    useEffect(() => {
        resetAll()
    }, [experience, config])

    // debugLog('BookingBox', {
    //     variants,
    //     apiVariants,
    //     variantsCapacities,
    //     step,
    //     selectedVariant,
    //     selectedParticipants,
    //     selectedDate,
    //     selectedSlot,
    //     selectedAddons,
    // })

    return (
        <BaseContext.Provider
            value={{
                mode,
                setMode,
                // Props
                config,
                variants,
                variantsCapacities,
                handleGoBack: handleGoBack ? handleGoBack : undefined,
                handleLastStepSuccess: handleLastStepSuccess ? handleLastStepSuccess : undefined,

                // State
                step,
                setStep,
                selectedVariant,
                setSelectedVariant,
                selectedParticipants,
                setSelectedParticipants,
                selectedDate,
                setSelectedDate,
                selectedSlot,
                setSelectedSlot,
                selectedAddons,
                setSelectedAddons,
                selectedAddonsGift,
                setSelectedAddonsGift,

                bookingCode,
                setBookingCode,

                discountCode,
                setDiscountCode,
            }}
        >
            {children}
        </BaseContext.Provider>
    )
}

export const useBBContext = () => {
    const baseContext = useContext(BaseContext)

    if (!baseContext) {
        throw new Error('baseContext has to be used within <BaseProvider>')
    }

    return baseContext
}
