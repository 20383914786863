import React, { Fragment } from 'react'
import { IBBAddon } from 'src/types'
import CategoryAddonIcon from '../icons/addons/CategoryAddonIcon'
export const AddonList: React.FC<{
    addons: IBBAddon[]
    className?: string
}> = (props) => {
    const { addons, className } = props
    const categories = new Set(addons?.map((addon) => addon.category_id))

    return (
        <Fragment>
            {Array.from(categories).map((addonCategory, index) => (
                <React.Fragment key={index}>
                    <span className="inline-block text-zinc-700">
                        <CategoryAddonIcon category_id={addonCategory!} className={className} />
                    </span>
                </React.Fragment>
            ))}
        </Fragment>
    )
}
