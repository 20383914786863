import { Fragment, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useExperiencePickerContext } from 'src/ExperiencePicker/Context'
import { CheckYesGreenIcon, ChevronLeft } from 'src/lib/icons/icons'
import { checkNullOrUndefined, debugLog, totalDeposit, totalPrice } from '../lib/utils'
import { BBAddons } from './BBAddons'
import { BBDate } from './BBDate'
import { BBParticipants } from './BBParticipants'
import { BBRecap } from './BBRecap'
import { BBSlot } from './BBSlot'
import { BBVariant } from './BBVariant'
import { BookingStep, BookingSteps, useBBContext } from './Context'
import { BBStepIndicator } from './components/StepIndicator'
import { BOOKING_BOX_TYPE, ExperiencePickerStep } from '../types'
import BookingBoxGiftAddons from './BookingBoxGiftAddons'
import OpenBookingButton from './components/OpenBookingButton'

interface BookingBoxFlowProps extends React.ComponentPropsWithoutRef<'div'> {
    setShow: (show: boolean) => void
    startingExperienceId: number | null
    setStartingExperienceId: (experienceId: number | null) => void
}

const BookingBoxFlow = ({ setShow, startingExperienceId, setStartingExperienceId }: BookingBoxFlowProps) => {
    const { handleGoBack, step, setStep, variants, selectedSlot, selectedAddons, config, mode } = useBBContext()

    const { experience, experiences, setExperience, setStep: setEPStep } = useExperiencePickerContext()

    const { t } = useTranslation()

    useEffect(() => {
        debugLog('startingExperienceId', startingExperienceId)
        if (startingExperienceId) {
            const selectedExperience = experiences.find((exp) => exp.id === startingExperienceId)
            if (selectedExperience) {
                setExperience(selectedExperience)
                if (selectedExperience.show_embed_product_page) {
                    setEPStep(ExperiencePickerStep.INFO)
                    handleGoBack()
                }
                setShow(true)
            }
            setStartingExperienceId(null)
        }
    }, [startingExperienceId])

    const totalPriceValue = useMemo(() => {
        if (
            mode == BOOKING_BOX_TYPE.BOOKING &&
            step === BookingSteps[BookingStep.Recap] &&
            experience &&
            !checkNullOrUndefined(experience?.deposit_percentage)
        ) {
            return totalPrice(
                selectedSlot.pricing,
                selectedAddons,
                config.customer_fee ?? 0,
                config.is_holidoit_network_operator
            )
        }
        return null
    }, [
        step,
        experience?.deposit_percentage,
        selectedSlot?.pricing?.holidoit_price,
        selectedSlot?.pricing?.partner_price,
        selectedAddons,
        config.customer_fee,
        config.is_holidoit_network_operator,
    ])

    const totalDepositValue = useMemo(() => {
        if (
            mode == BOOKING_BOX_TYPE.BOOKING &&
            step === BookingSteps[BookingStep.Recap] &&
            experience &&
            !checkNullOrUndefined(experience?.deposit_percentage)
        ) {
            return totalDeposit(
                selectedSlot.pricing,
                selectedAddons,
                config.customer_fee ?? 0,
                config.is_holidoit_network_operator,
                experience.deposit_percentage
            )
        }
        return null
    }, [
        step,
        experience?.deposit_percentage,
        selectedSlot?.pricing?.holidoit_price,
        selectedSlot?.pricing?.partner_price,
        selectedAddons,
        config.customer_fee,
        config.is_holidoit_network_operator,
    ])

    const isChristmasPeriod = [10, 11].includes(new Date().getMonth())

    return (
        <Fragment>
            {/* Go back handler */}
            {handleGoBack && experience && (
                <div className="flex h-[52px] items-center border-b border-zinc-300 bg-zinc-50 px-5 screen775:!h-[50px] screen775:!px-[17px]">
                    <div
                        className="flex w-full cursor-pointer items-center gap-2"
                        onClick={() => {
                            // if (experiences?.length === 1) {
                            //     return
                            // }
                            handleGoBack()
                        }}
                    >
                        <div className="relative !aspect-square !h-[32px] overflow-hidden rounded-[6px] screen600:!h-[23px]">
                            <img
                                loading="eager"
                                src={experience?.images[0].image.replace('/full/', '/450/') ?? ''}
                                className="h-full w-full rounded-[6px] object-cover"
                            />
                        </div>
                        <div className="line-clamp-2 space-y-1.5 truncate text-[14px] font-bold screen600:!text-[14px]">
                            <div>{experience?.name_partner_pages || experience?.name}</div>
                        </div>
                    </div>
                </div>
            )}

            <div className="dynamic-booking-box-height overflow-auto pb-5 screen775:!pb-[20px] screen775:!scrollbar-hide">
                <div className="mt-4 flex flex-row items-center justify-between gap-4 overflow-auto px-6 pt-2 screen775:!mt-[17px] screen775:!px-[17px]">
                    <div className="text-lg font-medium">
                        {/* gift step */}
                        {step === BookingSteps[BookingStep.Variant] &&
                            mode == BOOKING_BOX_TYPE.OPEN_DATE_BOOKING &&
                            t('giftBooking')}
                        {step === BookingSteps[BookingStep.Participants] &&
                            mode == BOOKING_BOX_TYPE.OPEN_DATE_BOOKING &&
                            t('giftBooking')}
                        {step === BookingSteps[BookingStep.GiftAddons] && t('bookingStepAddons')}

                        {/* booking step */}
                        {step === BookingSteps[BookingStep.Variant] &&
                            mode == BOOKING_BOX_TYPE.BOOKING &&
                            t('bookingStepVariant')}
                        {step === BookingSteps[BookingStep.Participants] &&
                            mode == BOOKING_BOX_TYPE.BOOKING &&
                            t('bookingStepParticipants')}
                        {step === BookingSteps[BookingStep.Addons] && t('bookingStepAddons')}

                        {/* common step */}
                        {step === BookingSteps[BookingStep.Date] && t('bookingStepDate')}
                        {step === BookingSteps[BookingStep.Slot] && t('bookingStepSlot')}
                        {step === BookingSteps[BookingStep.Recap] && t('bookingStepRecap')}
                    </div>

                    <div className="hide h-8 w-8 screen775:block" />
                    {experience?.embed_open_booking && <OpenBookingButton christmasHat={isChristmasPeriod} />}
                </div>

                {experience?.cancellation_period && mode == BOOKING_BOX_TYPE.BOOKING && (
                    <div className="mb-0.5 mt-2 flex items-center gap-2 px-6 text-[13.5px] text-emerald-600 screen775:!mt-[5px] screen775:!px-[17px]">
                        <CheckYesGreenIcon className="h-3.5 w-3.5" />
                        {t('freeCancellation', { hours: experience?.cancellation_period })}
                    </div>
                )}
                {experience &&
                    mode == BOOKING_BOX_TYPE.BOOKING &&
                    !checkNullOrUndefined(experience.deposit_percentage) && (
                        <div className="mb-0.5 mt-2 flex items-center gap-2 px-6 text-[13.5px] text-emerald-600 screen775:!mt-[5px] screen775:!px-[17px]">
                            <CheckYesGreenIcon className="h-3.5 w-3.5" />
                            {step !== BookingSteps[BookingStep.Recap] &&
                                t('depositExperienceBoxBeforeRecap', {
                                    deposit: Math.round(experience.deposit_percentage),
                                })}
                            {step === BookingSteps[BookingStep.Recap] &&
                                totalPriceValue !== null &&
                                totalDepositValue !== null &&
                                t('depositExperienceFromBoxRecap', {
                                    deposit: totalDepositValue.toFixed(2),
                                    rest: (totalPriceValue - totalDepositValue).toFixed(2),
                                })}
                        </div>
                    )}

                <div className="mt-3 px-6 screen775:!mt-[10px] screen775:!px-[17px]">
                    <BBStepIndicator />
                </div>

                <div className="mt-5 px-6 screen775:!mt-4 screen775:!px-[17px]">
                    {step === BookingSteps[BookingStep.Variant] && <BBVariant />}
                    {step === BookingSteps[BookingStep.Participants] && <BBParticipants />}
                    {step === BookingSteps[BookingStep.Date] && <BBDate />}
                    {step === BookingSteps[BookingStep.Slot] && <BBSlot />}
                    {step === BookingSteps[BookingStep.Addons] && <BBAddons />}
                    {step === BookingSteps[BookingStep.GiftAddons] && <BookingBoxGiftAddons />}
                    {step === BookingSteps[BookingStep.Recap] && <BBRecap />}
                </div>
            </div>
        </Fragment>
    )
}
export { BookingBoxFlow }
