import React from 'react'

export enum ButtonVariant {
    BLACK = 'black',
    YELLOW = 'yellow',
    BLACK_WHITE = 'black-white',
    WHITE = 'white',
    RED = 'red',
    WHITE_RED = 'white-red',
    GHOST = 'ghost',
}

export interface IButton extends React.ComponentPropsWithoutRef<'button'> {
    variant: ButtonVariant
}

const variantClasses = {
    [ButtonVariant.BLACK]:
        ' h-[54px] border rounded-[5px] text-white bg-black border border-black font-medium hover:!bg-black/90 disabled:opacity-80 min-h-[54px]',
    [ButtonVariant.YELLOW]:
        ' h-[54px] border rounded-[4px] text-zinc-900 bg-orange border-[1.5px] border-button font-medium hover:!bg-orange/90 disabled:opacity-80 min-h-[54px]',
    [ButtonVariant.BLACK_WHITE]:
        ' h-[54px] border rounded-[4px] text-white bg-black border border-black font-medium hover:bg-white hover:text-black focus:bg-black focus:text-white disabled:text-black disabled:bg-white !duration-150',
    [ButtonVariant.WHITE]:
        'h-[54px] border rounded-[4px] text-gray-700 border-gray-300 font-medium hover:text-gray-800 hover:bg-gray-50 hover:border-gray-400',
    [ButtonVariant.RED]:
        'h-[54px] border rounded-[4px] text-white hover:bg-red-700/90 bg-red-700 border-red-700 font-medium focus:text-white focus:bg-red-700',
    [ButtonVariant.WHITE_RED]:
        'h-[54px] border rounded-[4px] text-red-700 hover:bg-red-700 hover:text-white focus:text-white focus:bg-red-700 bg-white border-red-700 font-medium',
    [ButtonVariant.GHOST]: 'h-[54px] rounded-[4px] text-gray-700 font-medium hover:text-gray-800 hover:underline',
}

const Button: React.FC<IButton> = ({ children, variant, className, ...buttonProps }) => {
    return (
        <button
            {...buttonProps}
            className={`cursor-pointer text-[15px] font-bold transition-all duration-75 disabled:cursor-not-allowed screen600:text-[15px] ${variantClasses[variant]} ${className}`}
        >
            {children}
        </button>
    )
}

export default Button
