import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/it'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)

export enum HolidoitEmbedEventName {
    TOGGLE = 'holidoit-embed-toggle',
    OPEN = 'holidoit-embed-open',
    CLOSE = 'holidoit-embed-close',
}

export enum Languages {
    ITALIAN = 'it',
    ENGLISH = 'en',
}

export enum BOOKING_BOX_TYPE {
    BOOKING = 'BOOKING',
    OPEN_DATE_BOOKING = 'OPEN_DATE_BOOKING',
}

export interface FloatingButtonConfig {
    desktop: {
        width?: number
        height?: number
        borderRounding?: number
        textColor?: string
        textCase?: 'capitalize' | 'uppercase'
        fontSize?: number
        backgroundColor?: string
        rightPosition?: number
        leftPosition?: number
        topPosition?: number
        bottomPosition?: number
        fontWeight?: number
    }
    mobile?: {
        width?: number
        height?: number
        borderRounding?: number
        textColor?: string
        textCase?: 'capitalize' | 'uppercase'
        fontSize?: number
        backgroundColor?: string
        rightPosition?: number
        leftPosition?: number
        topPosition?: number
        bottomPosition?: number
        fontWeight?: number
    }
    zIndex?: number
    callToAction?: string
    italianCallToAction?: string
    englishCallToAction?: string
    rightIcon?: JSX.Element
    leftIcon?: JSX.Element
}

export interface BookingEmbedConfig {
    // THIS will be the default parameter passed
    //   to load the embed base settings
    embed_id?: string
    test?: boolean
    status?: 'active' | 'inactive'

    // ... base settings
    // sede: { id: string } (not for now)
    partner?: {
        display_name?: string | null
        email: string
        logo: string | null
        name: string
        phone_number: string | null
        vat_number: string | null
    }
    experiences?: ExperienceType[]
    // variants?: ExperienceVariantType[]
    // variantsCapacities?: {
    //     variantId: number
    //     capacities: EmbedVariantCapacities
    // }[]
    payment_locations?: string[] // 'online' | 'on_site'
    language?: Languages // default: 'it'
    authorized_domains?: string[] // security feature: which sites can use this embed?
    customer_fee?: number // default is 0, it's all on the partner -> forse non serve passarlo a frontend, ma bene averlo
    is_holidoit_network_operator?: boolean
    nocf?: number // Network operator compensation fee
    booking_type?: 'request' | 'instant' // prenotazione a richiesta è conferma immediata dell'orario?
    gtm_code?: string
}

export enum EmbedDestination {
    // HOLIDOIT = 'holidoit',
    SAAS = 'holidoit_saas',
    HOLIDOIT_DIRECT = 'holidoit_direct',
    WELFARE = 'holidoit_welfare',
    PARTNER_WEBSITE = 'partner_website',
}

export enum EmbedType {
    IFRAME = 'iframe',
    FLOATING_BUTTON = 'floating_button',
    ATTACHED_TO_BUTTON = 'attached_to_button',
    REACT_COMPONENT = 'react_component',
}

export enum BookingEmbedStep {
    EXPERIENCE = 'experience',
    BOOKING = 'booking',
    CHECKOUT = 'checkout',
}

export enum ExperiencePickerStep {
    HOME = 'home',
    INFO = 'info',
}

export enum CheckoutFlowType {
    INLINE = 'inline',
    CHECKOUT_PAGE = 'checkout_page',
    NONE = 'none',
}

export enum PaymentMethod {
    Card = 'stripe_card',
    Paypal = 'paypal',
    Stripe = 'stripe',
}

export interface PaymentErrorType {
    status: boolean
    message: string
}

export interface PartnerData {
    name?: string
    logo?: string
    email?: string
    vat_number?: string
    phone_number?: string
    // TODO: add fields below to API
    uuid?: string
    socialLinks?: PartnerSocialLink[]
}

export interface PartnerSocialLink {
    platform: 'instagram' | 'youtube' | 'website' | 'tiktok' | 'facebook'
    url: string
}

export interface ColorPalette {
    primary: string // 'black';
    secondary: string // 'yellow';
    tertiary: string // 'lightgray';
}

export interface CancellationPolicyRange {
    hours: number // e.g. 48 -> within 48 hours from experience date,
    percentage: number // ... 100 -> get 100% refund
}

export interface ExperienceType {
    id: number
    slug: string
    images: { image: string; cover_image: boolean }[]
    // TODO: cancellation_policy come range orario-%
    name: string
    city: string
    region: string
    destinations: string[] | []
    categories: { id: number; name: string }[]
    average_review: number | null
    total_reviews: number | null
    cancellation_period: number
    notice_period: number
    min_person_price: number | null // it is the price of Holidoit, not of the partner!
    // NEW
    min_person_price_partner: number | null
    min_price_is_group?: boolean
    min_price_is_fixed?: boolean
    price_one_liner?: string
    price_one_liner_partner?: string
    // end NEW
    lat: number
    lon: number
    video: string | null
    mobile_video: string | null
    deposit_percentage?: number
    instant_booking: boolean
    show_embed_product_page: boolean
    name_partner_pages?: string
    embed_open_booking: boolean
}

// v2
export interface IPartnerDetailedExperience {
    id: number
    name: string
    slug: string
    images: {
        image: string
        cover_image?: boolean
        thumbnail_image: string
    }[]
    average_review: number
    address: {
        city: string
        city_id: number
        region: string
        region_id: number
        region_slug: string
        province: string
        lat: string
        lon: string
        full_address?: string // v2
    }
    breadcrumb_slug?: string
    faqs?: {
        question: string
        answer: string
    }[]
    categories: {
        id: number
        name: string
        extended_name?: string
        description: string
    }[]
    map_description?: string
    description: string
    program: string
    video?: string
    included?: string[]
    not_included?: string[]
    total_reviews?: number | null
    min_person_price?: number | null
    meta_description?: string | null
    cancellation_period?: number
    notice_period?: number
    min_customers?: number
    max_customers?: number
    difficulty?: string
    difficulty_description?: string | null
    participants_description?: string | null
    is_open_booking_available?: boolean
    partner?: PartnerData // v2
}

export interface ExperienceVariantType {
    id: number
    output_name: string
    formatted_duration: string
    priority: number
    type?: TourType
    description: string
    // difficulty?: string;
    // location: string;
    min_customers?: number
    price_description?: string // Rich Text
    partner_price_description?: string // Rich Text
    addons?: IBBAddon[]
    first_available_month?: number
    instant_booking: boolean
}

export interface IPartnerExperienceAddons {
    results: IPartnerExperienceAddon[]
}

export interface IPartnerExperienceAddon {
    name: string
    category_id: number
    category_name: string
    description: string
    show_in_page: boolean
}

export interface ApiAvailabilitiesResponse {
    available_days: string[]
}

export interface IBBAddon {
    name: string
    id?: number
    holidoit_price?: string
    partner_price?: string
    description?: string
    is_mandatory_for_all?: boolean
    qt?: number
    category_id: number
}

export interface AddonRequestType {
    id?: number
    qt?: number
}

export interface AllocationRequestType {
    type_id: number
    quantity: number
}

export interface IJsonParticipant {
    id: number
    name: string
    quantity: number
}

export interface IJsonAllocation {
    type_id: number
    name: string
    quantity: number
    participants?: IJsonParticipant[] | []
}

/** @participants */

export interface ParticipantType {
    id: number // assuming `min_value=1` implies a positive integer, runtime validation needed
    name: string
    description: string | null // `allow_null=True` allows for `null` value
    suggested_quantity: number // number with which to init the quantity
}

export interface AllocationRule {
    max_quantity: number // assuming `min_value=1` implies a positive integer, runtime validation needed
    participant_ids: number[] // an array of integers
}

export interface VariantCapacityResponse {
    rules: AllocationRule[] // an array of `MaxQuantityAllocationDTO`
    participants: ParticipantType[] // an array of `ParticipantsDTO`
}

export interface Allocation extends ParticipantType {
    quantity: number
}

export interface SlotsResponse {
    slots: Slot[]
}

export interface Slot {
    starting_time: string
    pricing: Prices
    addons: IBBAddon[]
    available: boolean
}

export interface Prices {
    holidoit_price?: string | undefined
    partner_price?: string | undefined
}

// Checkout
export enum AdditionalInformationInputTypeChoices {
    TEXT = 'TEXT',
    SELECT = 'SELECT',
    NUMBER = 'NUMBER',
    CHECKBOX = 'CHECKBOX',
}

export interface COBookingInfo {
    code: string
    json_addons?: any
    info: {
        datetime_from: Date
        datetime_to: Date
        // to be removed
        meeting_time?: Date | null
        experience_variant: {
            instant_booking: boolean
            output_name: string
            cancellation?: number
            location: { city: string; region: string }
            type: string
            experience: {
                name: string
                average_review: number
                total_reviews: number
                partner: {
                    name: string
                    phone: string
                }
                slug: string
                cancellation_period?: number
            }
            formatted_duration: string
            cover_image: string
            additional_info?: {
                is_per_person: boolean
                input_type: AdditionalInformationInputTypeChoices
                name: string
                unit?: string
                description?: string
                choices?: string[]
                default?: string
                optional?: boolean
            }[]
        }
    }
    status: string
    holidoit_price: string
    partner_price: string
    checkout_expiration_at: string
    json_allocations?: any
    has_deposit: boolean
    deposit_holidoit_price?: string
}

// Only used in COProductsBox
export interface COProductBooking {
    addons?: any
    json_allocations?: any
    idExperience?: number
    bookingState?: 'normal' | 'canceled' | 'pending' | 'action_required'
    images: string | string[]
    title: string
    location: string
    locationInfo?: {
        coordinates: {
            lat: number
            lng: number
        }
        directionsInfo: string
    }
    cost: string
    dateBooking: Date
    timeBooking: string
    meetingTime?: string
    bookingSupplierContact?: {
        phone: string
        name: string
    }
    bookingClientContact?: {
        name: string
        phone: string
        email: string
    }

    rating?: number
    numRevisions?: number
    characteristics?: {
        duration?: string
        description?: string
    }
    cancelationDeadlineDays?: number
    type?: string
    expiration_time?: Date
    additional_info?: {
        is_per_person: boolean
        input_type: AdditionalInformationInputTypeChoices
        name: string
        unit?: string
        description?: string
        choices?: string[]
        default?: string
    }[]
    experienceSlug?: string
    holidoit_price?: string
    encrypted_code?: string
    partner_price?: string
}

export type COCustomerInfoForm = {
    first_name: string
    last_name: string
    email: string
    confirm_email: string
    phone?: string
    additional_info?: string
}

export enum TourType {
    PUBLIC = 'Di gruppo',
    PRIVATE = 'Privato',
    SELF_GUIDED = 'Senza guida',
}

export const locales = {
    it: {
        months: dayjs().locale('it').localeData().months(),
        weekdays: dayjs().locale('it').localeData().weekdays(),
    },
    en: {
        months: dayjs().locale('en').localeData().months(),
        weekdays: dayjs().locale('en').localeData().weekdays(),
    },
}

export interface IMinimalExperienceVariant {
    name?: string
    iso_formatted_duration: string
    price_description: string
    partner_price_description: string
}

export interface DetailedPartnerExperience {
    // DetailedPartnerExperiencePartner fields
    partner: {
        name: string
        logo: string | null
        email: string
        phone_number: string
        vat_number: string
        uuid: string
        display_name: string
        description: string
    }

    // DetailedPartnerExperience fields
    experience_name_partner_pages: string
    min_person_price_partner: number
    min_price_is_group: boolean
    min_price_is_fixed: boolean
    price_one_liner_partner: string
    catch_all_text_partner_pages: string
    catch_all_text_partner_pages_additional: string
    id: number
    name: string
    name_partner_pages?: string
    slug: string
    images: Array<{
        thumbnail_image: string
        image: string
        cover_image: boolean
    }>
    address: {
        city: string
        city_id: number
        region: string
        region_id: number
        region_slug: string
        lat: string
        lon: string
        province: string
        full_address: string
    }
    included?: string[]
    not_included?: string[]
    what_to_bring: string
    program: string
    program_partner_pages?: string
    price_one_liner: string
    min_person_price: number
    notice_period: string
    cancellation_period: string
    map_description: string
    channels: string[]
    participants_description?: string
    experience_variants: IMinimalExperienceVariant[]
}

export enum ReviewSource {
    HOLIDOIT = 'holidoit',
    PARTNER = 'partner',
    GOOGLE = 'google',
    FACEBOOK = 'facebook',
    TRIPADVISOR = 'tripadvisor',
    TRUSTPILOT = 'trustpilot',
    OTHER = 'other',
}

export interface IJsonAllocation {
    type_id: number
    name: string
    quantity: number
    participants?: IJsonParticipant[] | []
}

export interface IJsonAddon {
    id: number
    name: string
    category_id: number
    holidoit_price: string
    partner_price: string
    quantity: number
}

export enum VariantType {
    PUBLIC = 'PU',
    PRIVATE = 'PR',
    SELF_GUIDED = 'SG',
}

export enum SaleChannel {
    DIRECT = 'PP_CUSTOMER_BOOKING',
    HOLIDOIT = 'HOLIDOIT_COM',
    OPEN_BOOKING = 'HOLIDOIT_COM_OPEN',
    HOLIDOIT_NETWORK = 'HOLIDOIT_NETWORK',
}

export interface ReviewType {
    // title: string;
    author: string
    star: number
    comment: string
    answer: string | null
    date: Date | string //2023-02-09
    source: ReviewSource
    booking: null | {
        code: string
        info: {
            datetime_from: string
            datetime_to: string
            variant: {
                name: string | null
                duration: string // '01:30:00'
                type: VariantType
                iso_formatted_duration: string
            }
        }
        holidoit_price: string
        json_allocations: IJsonAllocation[]
        json_addons?: IJsonAddon[]
        language: Languages.ITALIAN | Languages.ENGLISH
        channel: SaleChannel
        // meta_data: string; // IApiExperienceReviewMetaData stringified
    }
    experience: {
        name: string
        slug: string
    }
    hide_full_name: boolean
    images: { image: string }[]
}

export type IRecipientInfo = {
    name: string
    email?: string
    message?: string
    sender?: string
    sendEmail?: boolean
    slug?: string
}

export enum DISCOUNT_TYPE {
    DISCOUNT_CODE = 'DISCOUNT_CODE',
    GIFT_CARD = 'GIFT_CARD',
    OPEN_BOOKING = 'OPEN_BOOKING',
}

export interface Coupon {
    code: string
    discount_type: DISCOUNT_TYPE
    value: number
    is_percentage: boolean
}

export interface GiftCardValidationSuccessResponse {
    results: Coupon[]
}

export enum DISCOUNT_ERROR_CODE {
    EXPIRED = 'EXPIRED',
    NOT_LOGGED_USER = 'NOT LOGGED USER',
    USAGE_LIMIT_REACHED = 'USAGE LIMIT REACHED',
    NOT_AVAILABLE = 'NOT AVAILABLE',
    EMPTY_BOOKING = 'EMPTY BOOKING',
    NOT_VALID_FOR_PRICE = 'NOT VALID FOR PRICE',
    MIN_PRICE_NOT_VALID = 'MIN PRICE NOT VALID',
    EXPERIENCE_NOT_VALID = 'EXPERIENCE NOT VALID',
    DUPLICATED = 'DUPLICATED',
    DISCOUNT_CODE_WITH_GIFTCARD = 'DISCOUNT CODE WITH GIFTCARD',
    NOT_FOUND = 'NOT FOUND',
    MULTIPLE_DISCOUNT_CODE = 'MULTIPLE DISCOUNT CODE',
    DISCOUNT_WITH_DEPOSIT_CANNOT_BE_USED = 'DISCOUNT WITH DEPOSIT CANNOT BE USED',
}

export interface GiftCardValidationError {
    discount_code: string
    discount_error_code: DISCOUNT_ERROR_CODE
    detail: string
}

export interface GiftCardValidationErrorResponse {
    errors: GiftCardValidationError[]
}

export interface ThanksBooking {
    customer_first_name: string
    customer_last_name: string
    customer_email: string
    customer_phone: string
    encrypted_code: string

    code: string
    datetime_from: Date
    datetime_to: Date

    used_vouchers: {
        code: string
        amount: number
    }[];
    experience_variant: string
    holidoit_price: number
    partner_price: number
    customer_fees: number
    partner_fees: number
    paid_online_by_customer: number
    to_be_paid_on_site: number
    total_discount_amount: number
    has_deposit: boolean
    deposit_percentage: number
}
