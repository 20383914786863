import { Fragment } from 'react'
import CategoryAddonAperitivo from './CategoryAddonAperitivo'
import CategoryAddonCena from './CategoryAddonCena'
import CategoryAddonPernottamento from './CategoryAddonPernottamento'
import CategoryAddonPicnic from './CategoryAddonPicnic'
import CategoryAddonPranzo from './CategoryAddonPranzo'
import CategoryAddonTagliere from './CategoryAddonTagliere'

export interface IconCategoryProps {
    category_id: number
    className?: string
}

export default function CategoryAddonIcon(props: IconCategoryProps) {
    const { category_id, className } = props
    switch (category_id) {
        case 1:
            // TODO MISSING ICON
            return <CategoryAddonPranzo className={className} />
        case 2:
            return <CategoryAddonAperitivo className={className} />
        case 3:
            // TODO MISSING ICON
            return <CategoryAddonPernottamento className={className} />
        case 4:
            // TODO MISSING ICON
            return <CategoryAddonCena className={className} />
        case 5:
            return <CategoryAddonPicnic className={className} />
        case 6:
            return <CategoryAddonTagliere className={className} />
        default:
            return <Fragment></Fragment>
    }
}
