import { PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { api } from 'src/api'
import { customRound, debugLog, fetchWithLanguage } from 'src/lib/utils'
import useSWRMutation from 'swr/mutation'
import { useCOContext } from '../../Context'
import { useBBContext } from '../../../BookingBox/Context'
import { BOOKING_BOX_TYPE } from '../../../types'
import { useExperiencePickerContext } from '../../../ExperiencePicker/Context'
import { computeBookingPriceWithCoupons } from '../../lib/priceUtils'
// It encapsulates the Stripe payment request button to enable Apple Pay and Google Pay
const AppleAndGooglePay = () => {
    const [stripeError, setStripeError] = useState(false)

    const {
        booking,
        customerInfo,
        peopleFeaturesNotes,
        setPaymentSuccess,
        setPaymentError,
        setCompletedBookingCode,
        config,
        openBooking,
        coupons,
    } = useCOContext()

    const { mode } = useBBContext()

    const { experience } = useExperiencePickerContext()

    const elements = useElements()
    const stripe = useStripe()

    const [paymentRequest, setPaymentRequest] = useState<any>(null)

    const { i18n, t } = useTranslation()
    const language = i18n.language

    // Create Stripe order
    const { trigger: triggerOrderInfo } = useSWRMutation(
        api.endpoints.backend.checkout.stripeCreateOrder,
        async (url: string, { arg: { bookingCode, payment_method_id } }: any) => {
            return fetchWithLanguage(url.replace(':embedId', config.embed_id).replace(':code', bookingCode), {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    bookingCode: bookingCode,
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    email: customerInfo.email,
                    phone: customerInfo.phone,
                    payment_method_id: payment_method_id,
                    discount_codes: coupons.map((coupon) => coupon.code),
                }),
            }).then((res) => {
                if (res.ok) {
                    return res.json()
                } else {
                    if (res.status === 404) {
                        setPaymentError({
                            status: true,
                            message: t('paymentError'),
                        })
                    } else {
                        setPaymentError({
                            status: true,
                            message: t('paymentError'),
                        })
                        setStripeError(true)
                    }
                    return res.status
                }
            })
        }
    )

    // Confirm Stripe order
    const { trigger: triggerConfirmOrder } = useSWRMutation(
        api.endpoints.backend.checkout.stripeConfirmOrder,
        async (url: string, { arg: { intentId, notes } }: any) => {
            return fetchWithLanguage(url.replace(':embedId', config.embed_id).replace(':intentId', intentId), {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    email: customerInfo.email,
                    phone: customerInfo.phone,
                    intentId: intentId,
                    ...(notes ? { notes } : {}),
                }),
            }).then((res) => res)
        }
    )

    // Create Stripe open booking order
    const { trigger: triggerOpenBookingCreateOpenBookingOrder } = useSWRMutation(
        api.endpoints.backend.checkout.openBookingStripeCreateOrder,
        async (url: string, { arg: { openBookingCode, payment_method_id } }: any) => {
            return fetchWithLanguage(url.replace(':code', openBookingCode), {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    bookingCode: openBookingCode,
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    email: customerInfo.email,
                    phone: customerInfo.phone,
                    payment_method_id: payment_method_id,
                }),
            }).then((res) => {
                if (res.ok) {
                    return res.json()
                } else {
                    if (res.status === 404) {
                        setPaymentError({
                            status: true,
                            message: t('paymentError'),
                        })
                    } else {
                        setPaymentError({
                            status: true,
                            message: t('paymentError'),
                        })
                        setStripeError(true)
                    }
                    return res.status
                }
            })
        }
    )

    // Confirm Stripe open booking order
    const { trigger: triggerConfirmOpenBookingOrder } = useSWRMutation(
        api.endpoints.backend.checkout.openBookingStripeConfirmOrder,
        async (url: string, { arg: { intentId, notes } }: any) => {
            return fetchWithLanguage(url.replace(':intentId', intentId), {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    email: customerInfo.email,
                    phone: customerInfo.phone,
                    intentId: intentId,
                    ...(notes ? { notes } : {}),
                }),
            }).then((res) => res)
        }
    )

    useEffect(() => {
        if (
            !stripe ||
            !elements ||
            paymentRequest ||
            (mode === BOOKING_BOX_TYPE.BOOKING && (!booking?.partner_price || !booking?.holidoit_price)) ||
            (mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING && (!openBooking || !openBooking.original_value))
        ) {
            return
        }
        let amount: string | number;
        if (mode === BOOKING_BOX_TYPE.BOOKING) {
            amount = Number(computeBookingPriceWithCoupons(booking, coupons)) * 100
        } else {
            amount = customRound(Number(openBooking.original_value) * 100)
        }

        const pr = stripe.paymentRequest({
            currency: 'eur',
            country: 'IT',
            total: {
                label: experience.name,
                amount: Math.trunc(Number(amount)),
            },
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true,
        })

        pr.canMakePayment().then((result) => {
            if (result) {
                setPaymentRequest(pr)
            }
        })

        pr.on('paymentmethod', async (e: any) => {
            // create a payment intent on the server

            const orderInfo =
                mode === BOOKING_BOX_TYPE.BOOKING
                    ? await triggerOrderInfo({
                          bookingCode: booking.code,
                          payment_method_id: e.paymentMethod.id,
                          email: customerInfo.email,
                          first_name: customerInfo.first_name,
                          last_name: customerInfo.last_name,
                          phone: customerInfo.phone,
                      })
                    : await triggerOpenBookingCreateOpenBookingOrder({
                          openBookingCode: openBooking.code,
                          payment_method_id: e.paymentMethod.id,
                          email: customerInfo.email,
                          first_name: customerInfo.first_name,
                          last_name: customerInfo.last_name,
                          phone: customerInfo.phone,
                      })

            if (orderInfo?.order_id) {
                const confirmOrder =
                    mode === BOOKING_BOX_TYPE.BOOKING
                        ? await triggerConfirmOrder({
                              email: customerInfo.email,
                              first_name: customerInfo.first_name,
                              last_name: customerInfo.last_name,
                              phone: customerInfo.phone,
                              intentId: orderInfo?.order_id!,
                              ...(peopleFeaturesNotes ? { notes: peopleFeaturesNotes } : {}),
                          })
                        : await triggerConfirmOpenBookingOrder({
                              email: customerInfo.email,
                              first_name: customerInfo.first_name,
                              last_name: customerInfo.last_name,
                              phone: customerInfo.phone,
                              intentId: orderInfo?.order_id!,
                              ...(peopleFeaturesNotes ? { notes: peopleFeaturesNotes } : {}),
                          })

                if (confirmOrder?.status === 404) {
                    setPaymentError({
                        status: true,
                        message: 'Errore durante il pagamento',
                    })
                    debugLog('404')
                    setStripeError(true)
                    e.complete('error')
                } else if (confirmOrder?.status === 503) {
                    setPaymentError({
                        status: true,
                        message: 'Errore durante il pagamento',
                    })
                    setStripeError(true)
                    e.complete('error')
                } else if (confirmOrder?.status === 200) {
                    setPaymentSuccess(true)
                    const code = mode === BOOKING_BOX_TYPE.BOOKING ? booking.code : openBooking.code
                    setCompletedBookingCode(code)
                    debugLog('success!! stripe payment completed')
                    e.complete('success')
                }
            }
        })
    }, [stripe, elements, customerInfo, booking])

    useEffect(() => {
        if (
            !stripe ||
            !elements ||
            (mode === BOOKING_BOX_TYPE.BOOKING && (!booking?.partner_price || !booking?.holidoit_price)) ||
            (mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING && (!openBooking || !openBooking.original_value))
        ) {
            return
        }

        if (elements && paymentRequest) {
            let amount: string | number = 0
            if (mode === BOOKING_BOX_TYPE.BOOKING) {
                amount = Number(computeBookingPriceWithCoupons(booking, coupons)) * 100
            } else {
                amount = customRound(Number(openBooking.original_value) * 100)
            }
            elements.getElement(PaymentRequestButtonElement)?.on('click', () => {
                paymentRequest.update({
                    total: {
                        label: experience.name,
                        amount: Math.trunc(Number(amount)),
                    }, // update the total amount
                })
            })
        }
    }, [
        elements,
        paymentRequest,
        booking?.partner_price,
        booking?.holidoit_price,
        openBooking?.original_value,
        coupons,
    ])

    return (
        <Fragment>
            {paymentRequest && (
                <div className="mb-[15px]">
                    <PaymentRequestButtonElement
                        options={{
                            paymentRequest,
                            style: {
                                paymentRequestButton: {
                                    height: '45px', // Adjust as needed
                                },
                            },
                        }}
                    />
                </div>
            )}
            {stripeError && <div className="my-2 text-[13px] text-red-600">{t('paymentError')}</div>}
        </Fragment>
    )
}

export { AppleAndGooglePay }
