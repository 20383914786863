import { useBBContext } from '../Context'
import { BOOKING_BOX_TYPE } from '../../types'
import CustomToggle from './ToggleSwitch'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ChristmasHatIcon } from '../../lib/icons/icons'

const OpenBookingButton = ({
    smaller = false,
    christmasHat = false,
}: {
    smaller?: boolean
    christmasHat?: boolean
}) => {
    const { mode, setMode } = useBBContext()
    const { t } = useTranslation()

    const changeMode = () => {
        setMode((prevMode) => {
            if (prevMode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING) {
                return BOOKING_BOX_TYPE.BOOKING
            }
            return BOOKING_BOX_TYPE.OPEN_DATE_BOOKING
        })
    }

    return (
        <Fragment>
            <div className="flex items-center gap-[7px] whitespace-nowrap screen600:gap-2">
                <div
                    className={`relative cursor-pointer text-[13px] ${
                        smaller ? 'screen600:text-[13.5px]' : 'screen600:text-[14px]'
                    }  text-zinc-400`}
                    onClick={() => {
                        setMode((prevMode) => {
                            if (prevMode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING) {
                                return BOOKING_BOX_TYPE.BOOKING
                            }
                            return BOOKING_BOX_TYPE.OPEN_DATE_BOOKING
                        })
                    }}
                >
                    {t('bookAsAGift')}
                </div>
                <div className={'relative'}>
                    <CustomToggle checked={mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING} onChange={changeMode} />
                    {christmasHat && (
                        <ChristmasHatIcon
                            className="absolute -right-[10.5px] -top-[10px] h-5 w-5 rotate-[20deg] cursor-pointer"
                            onClick={changeMode}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default OpenBookingButton
