import { Drawer } from 'antd'
import { DrawerStyles } from 'antd/es/drawer/DrawerPanel'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HolidoitBookingEmbed from './BookingEmbed'
import ViewportManager from './Checkout/ViewportManager'
import { useWindowSize } from './lib/hooks/useWindowSize'
import { debugLog } from './lib/utils'
import './styles.scss'
import { BookingEmbedConfig, FloatingButtonConfig, HolidoitEmbedEventName, Languages } from './types'

export function HolidoitEmbedDrawerWrapper({
    withFloatingButton,
    floatingButtonOptions,
    defaultEmbedId,
}: {
    withFloatingButton?: boolean
    floatingButtonOptions?: FloatingButtonConfig
    defaultEmbedId?: string
}) {
    const { width } = useWindowSize()
    const [show, setShow] = useState(false)

    const [language, setLanguage] = useState<string | null>(Languages.ITALIAN) // default
    const { i18n, t } = useTranslation()

    const handleChangeLanguageMessage = (language: string) => {
        const iframe = document.getElementById('holidoit-iframe') as HTMLIFrameElement | null

        // Message to be sent
        const message = {
            action: 'holidoitEmbedChangeLanguage',
            language: language,
        }

        if (iframe && iframe.contentWindow) {
            // Send message to the iframe
            iframe.contentWindow.postMessage(message, '*') // Consider specifying target origin
        } else {
            debugLog('holidoit-iframe not found or contentWindow is not accessible.')
        }

        // Send message to the same window
        window.postMessage(message, '*') // Consider specifying target origin
    }

    const changeLanguage = (lng: string, triggerMessage = false) => {
        i18n.changeLanguage(lng)
        setLanguage(lng)
        if (triggerMessage) {
            handleChangeLanguageMessage(lng)
        }
    }

    const [currentConfig, setCurrentConfig] = useState<BookingEmbedConfig | null>(
        defaultEmbedId && defaultEmbedId.length > 0 ? { embed_id: defaultEmbedId } : null
    )

    // debugLog('[HolidoitEmbed] configs', config)
    debugLog('[HolidoitEmbed] currentConfig', currentConfig)

    const [startingExperienceId, setStartingExperienceId] = useState<number | null>(null)

    useEffect(() => {
        const toggleListener = (event: CustomEvent) => {
            const { embed_id, test, experience_id } = event.detail
            if (typeof embed_id !== 'undefined' && embed_id.length > 0) {
                const config = { embed_id: embed_id, test }
                if (config) {
                    setCurrentConfig(config)
                }
            } else {
                debugLog('typeof i === undefined', 'setting config to [0] index')
                // setCurrentConfig(configs[0])
            }
            if (experience_id) {
                setStartingExperienceId(experience_id)
            }
            setShow((prev) => !prev)
        }

        const handleSelectExperience = (event: MessageEvent) => {
            if (event.data.action === 'holidoitExperienceSelected') {
                debugLog('Received holidoitExperienceSelected event with experience ID:', event.data.experienceId)
                setStartingExperienceId(event.data.experienceId)
                setShow(true)
            }
        }

        window.addEventListener(HolidoitEmbedEventName.TOGGLE, toggleListener as EventListener)
        window.addEventListener(HolidoitEmbedEventName.OPEN, () => setShow(true))
        window.addEventListener(HolidoitEmbedEventName.CLOSE, () => setShow(false))
        window.addEventListener('message', handleSelectExperience)

        return () => {
            window.removeEventListener(HolidoitEmbedEventName.TOGGLE, toggleListener as EventListener)
            window.removeEventListener(HolidoitEmbedEventName.OPEN, () => setShow(true))
            window.removeEventListener(HolidoitEmbedEventName.CLOSE, () => setShow(false))
            window.removeEventListener('message', handleSelectExperience)
        }
    }, [])

    const drawerStyles: DrawerStyles = {
        mask: { overflow: 'hidden' },
        wrapper: {
            fontFamily: 'DM Sans',
            background: 'transparent',
            boxShadow: 'none',
            overflow: 'hidden',
        },
        content: {
            background: 'transparent',
            boxShadow: 'none',
        },
        header: {
            display: 'none',
            overflow: 'hidden',
        },
        body: {
            paddingBottom: 0, // Set padding bottom to 0
            paddingTop: window.innerWidth < 755 ? 0 : undefined, // Set padding top to 0 for mobile
            letterSpacing: '0px'
        },
        footer: {
            display: 'none',
        },
    }

    function onClose() {
        setShow(false)
        // setCurrentConfig(null)
        debugLog('[HolidoitEmbed][onClose] called')
    }

    debugLog('[HolidoitEmbedDrawerWrapper] withFloatingButton', withFloatingButton)
    debugLog('[HolidoitEmbedDrawerWrapper] floatingButtonOptions', floatingButtonOptions)

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden !important'
        } else {
            document.body.style.overflow = ''
        }

        return () => {
            document.body.style.overflow = ''
        }
    }, [show])

    const callToAction =
        (language === Languages.ITALIAN && floatingButtonOptions?.italianCallToAction) ||
        (language === Languages.ENGLISH && floatingButtonOptions?.englishCallToAction) ||
        floatingButtonOptions?.callToAction ||
        t('bookNow');

    return (
        <Fragment>
            {withFloatingButton && defaultEmbedId && defaultEmbedId.length > 0 && (
                <button
                    style={{
                        display: show ? 'none' : 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.fontWeight ? floatingButtonOptions.mobile.fontWeight : 700}`
                                : `${floatingButtonOptions?.desktop?.fontWeight ? floatingButtonOptions.desktop.fontWeight : 700}`,
                        gap: '10px',
                        zIndex: floatingButtonOptions?.zIndex ? floatingButtonOptions.zIndex : 2147483647,
                        // zIndex: floatingButtonOptions?.zIndex ? floatingButtonOptions.zIndex : 1000000,
                        position: 'fixed',
                        bottom:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.bottomPosition ? floatingButtonOptions.mobile.bottomPosition + 'px' : '22px'}`
                                : `${floatingButtonOptions?.desktop?.bottomPosition ? floatingButtonOptions.desktop.bottomPosition + 'px' : '30px'}`,
                        right:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.rightPosition ? floatingButtonOptions.mobile.rightPosition + 'px' : '17px'}`
                                : `${floatingButtonOptions?.desktop?.rightPosition ? floatingButtonOptions.desktop.rightPosition + 'px' : '30px'}`,
                        left:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.leftPosition ? floatingButtonOptions.mobile.leftPosition + 'px' : 'auto'}`
                                : `${floatingButtonOptions?.desktop?.leftPosition ? floatingButtonOptions.desktop.leftPosition + 'px' : 'auto'}`,
                        top:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.topPosition ? floatingButtonOptions.mobile.topPosition + 'px' : 'auto'}`
                                : `${floatingButtonOptions?.desktop?.topPosition ? floatingButtonOptions.desktop.topPosition + 'px' : 'auto'}`,
                        background:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.backgroundColor ? floatingButtonOptions.mobile.backgroundColor : 'black'}`
                                : `${floatingButtonOptions?.desktop?.backgroundColor ? floatingButtonOptions.desktop.backgroundColor : 'black'}`,
                        borderRadius:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.borderRounding ? floatingButtonOptions.mobile.borderRounding : 30}px`
                                : `${floatingButtonOptions?.desktop?.borderRounding ? floatingButtonOptions.mobile.borderRounding : 30}px`,
                        width:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.width ? floatingButtonOptions.mobile.width : width - 34}px`
                                : `${floatingButtonOptions?.desktop?.width ? floatingButtonOptions.desktop.width : 190}px`,
                        height:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.height ? floatingButtonOptions.mobile.height : 50}px`
                                : `${floatingButtonOptions?.desktop?.height ? floatingButtonOptions.desktop.height : 56}px`,
                        color:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.textColor ? floatingButtonOptions.mobile.textColor : 'white'}`
                                : `${floatingButtonOptions?.desktop?.textColor ? floatingButtonOptions.desktop.textColor : 'white'}`,
                        padding: '10px 20px',
                        border: 'none',
                        cursor: 'pointer',
                        // fontFamily: 'DM Sans, system-ui, sans-serif', // will use the websites's default
                        textTransform:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.textCase === 'uppercase' ? 'uppercase' : floatingButtonOptions?.mobile?.textCase === 'capitalize' ? 'capitalize' : 'none'}`
                                : `${floatingButtonOptions?.desktop?.textCase === 'uppercase' ? 'uppercase' : floatingButtonOptions?.desktop?.textCase === 'capitalize' ? 'capitalize' : 'none'}`,
                        fontSize:
                            width && width < 775
                                ? `${floatingButtonOptions?.mobile?.fontSize ? floatingButtonOptions.mobile.fontSize + 'px' : '15.5px'}`
                                : `${floatingButtonOptions?.desktop?.fontSize ? floatingButtonOptions.desktop.fontSize + 'px' : '16px'}`,
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                    }}
                    onClick={() => setShow(true)}
                >
                    {floatingButtonOptions?.leftIcon ? (
                        floatingButtonOptions.leftIcon
                    ) : (
                        <svg
                            fill={'white'}
                            stroke={'white'}
                            xmlns="http://www.w3.org/2000/svg"
                            height={'19px'}
                            width={'19px'}
                            viewBox="0 0 448 512"
                        >
                            <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" />
                        </svg>
                    )}
                    {callToAction}
                    {floatingButtonOptions?.rightIcon ? floatingButtonOptions.rightIcon : null}
                </button>
            )}

            {/* effect to adjust viewport meta tag for mobile when the drawer is mounted and unmounted */}
            {show && <ViewportManager />}

            <Drawer
                id="holidoit-embed"
                className="notranslate reset-host-styles"
                placement={width && width < 775 ? 'bottom' : 'right'}
                height={'100dvh'}
                width={width && width < 775 ? '100vw' : '500px'}
                closable={true}
                forceRender
                mask={true}
                maskClosable={false}
                open={show}
                onClose={onClose}
                styles={drawerStyles}
                zIndex={2147483646}
                keyboard={false}
                // zIndex={2147483600}
            >
                <div
                    className={`dynamic-full-height relative mt-[5px] overflow-hidden rounded-md bg-white text-zinc-800 scrollbar-hide screen775:-m-6 screen775:-mb-9 screen775:-mt-9`}
                >
                    {currentConfig ? (
                        <Fragment>
                            <HolidoitBookingEmbed
                                currentConfig={currentConfig}
                                closeDrawer={onClose}
                                language={language}
                                changeLanguage={changeLanguage}
                                setShow={setShow}
                                startingExperienceId={startingExperienceId}
                                setStartingExperienceId={setStartingExperienceId}
                            />
                        </Fragment>
                    ) : (
                        <div className="loading-pulse h-10 w-full bg-zinc-100" />
                    )}
                </div>
                <div className="-mb-[5px] flex h-[3dvh] w-full items-center justify-between bg-transparent pt-[12px] screen775:!mb-0 screen775:hidden screen775:!h-0">
                    <div className="flex items-center gap-1 text-xs text-white/60">
                        <div className="">Powered by</div>
                        <a
                            href="https://business.holidoit.com/"
                            rel="noreferrer"
                            target="_blank"
                            className="cursor-pointer text-[13px] font-medium text-white/65 hover:text-white/70"
                        >
                            {/* <img
                                    // src="https://holidoit-public.s3.eu-south-1.amazonaws.com/holidoit-logo-white-transparent-background-gray.png++(7).png" // opacity 52 in white
                                    src="https://holidoit-public.s3.eu-south-1.amazonaws.com/holidoit-logo-white-transparent-background-gray.png++(8).png" // opacity 56 in white
                                    // src="https://holidoit-public.s3.eu-south-1.amazonaws.com/holidoit-logo-white-transparent-background-gray.png++(6).png" // opacity 60 in white
                                    width={62}
                                    height={25}
                                    className="object-cover"
                                /> */}
                            Holidoit
                        </a>
                    </div>
                    <div className="flex items-center gap-3 text-xs text-white/60 hover:text-white/70">
                        <a
                            href="https://www.holidoit.com/t/privacy-policy"
                            rel="noreferrer"
                            target="_blank"
                            className="cursor-pointer hover:!underline"
                        >
                            Privacy
                        </a>
                        <a
                            href="https://www.holidoit.com/t/embed"
                            rel="noreferrer"
                            target="_blank"
                            className="cursor-pointer hover:text-white/70 hover:!underline"
                        >
                            {t('terms')}
                        </a>
                    </div>
                </div>
            </Drawer>
        </Fragment>
    )
}
