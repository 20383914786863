import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckoutStepId, useCOContext } from 'src/Checkout/Context'
import { api } from 'src/api'
import {
    AddonRequestType,
    Allocation,
    AllocationRequestType,
    BOOKING_BOX_TYPE,
    BookingEmbedConfig,
    ExperienceVariantType,
    IBBAddon,
    Slot,
} from 'src/types'
import {
    debugLog,
    fetchWithLanguage,
    formatAndStrigifyAddons,
    formatDate,
    formatDuration,
    formatEndingTime,
    formatTime,
    getParticipantsString,
    parseHtmlStrict,
    prepareParticipantsForCreateBooking,
    stringifyParticipants,
    totalPrice,
} from '../lib/utils'
import { useBBContext } from './Context'
import BookButton from './components/CreateBookingButton'
import { InfoBox } from './components/InfoBox'
import useSWR from 'swr'
import LoadingDots from '../lib/components/LoadingDots'

export interface CreateBookingRequestBody {
    allocations: AllocationRequestType[] | null
    slot: string
    addons: AddonRequestType[]
}

export interface CreateOpenBookingRequestBody {
    allocations: AllocationRequestType[] | null
    addons: AddonRequestType[]
}

export interface CreateBookingResponse {
    address: string // 'SS301, 23041, Livigno (SO)';
    code: string // 'EKI7IWW0MA';
    country: string // 'Italy';
    datetime_from: string // '2024-03-24T14:00:00+01:00';
    datetime_to: string // '2024-03-24T14:45:00+01:00';
    holidoit_price: string // '80.00';
    partner_price: string // '80.00';
    name: string | null
}

export const VAT_MULTIPLIER = 1.22

const BookingTotalPrice = ({
    config,
    selectedSlot,
    selectedAddons,
}: {
    config: BookingEmbedConfig
    selectedSlot: Slot
    selectedAddons: IBBAddon[]
}) => {
    const { t } = useTranslation()
    return (
        <Fragment>
            {selectedSlot?.pricing && (
                <span>
                    {totalPrice(
                        selectedSlot.pricing,
                        selectedAddons,
                        config.customer_fee ?? 0,
                        config.is_holidoit_network_operator
                    ).toFixed(2)}{' '}
                    €
                </span>
            )}
            {(config.customer_fee ?? 0) > 0 && (
                <span className="-mt-[2px] whitespace-nowrap text-[12px] font-normal text-gray-400 screen775:!text-[12px]">
                    {Number(config.customer_fee * VAT_MULTIPLIER).toFixed(1) ?? 0 * 100}%{' '}
                    {t('serviceCosts').toLowerCase()}
                </span>
            )}
        </Fragment>
    )
}

const OpenBookingTotalPrice = ({
    config,
    selectedVariant,
    selectedParticipants,
    selectedAddonsGift,
}: {
    config: BookingEmbedConfig
    selectedVariant: ExperienceVariantType
    selectedParticipants: Allocation[]
    selectedAddonsGift: IBBAddon[]
}) => {

    const {
        data: openBookingPrice,
        error: openBookingPriceError,
        isLoading: openBookingPriceIsLoading,
        isValidating: openBookingPriceIsValidating,
    } = useSWR<{ holidoit_price: string }>(
        `${api.endpoints.backend.bookingBox.getOpenBookingPrice
            .replace(':embedId', config.embed_id)
            .replace(':variantId', selectedVariant?.id.toString() ?? '')
            .concat(
                `?allocations=${stringifyParticipants(selectedParticipants)}`
            )}&addons=${formatAndStrigifyAddons(selectedAddonsGift ?? [])}`,
        (url) =>
            fetch(url).then((res) => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    return null
                }
            }),
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            keepPreviousData: true,
        }
    )
    return (
        <Fragment>
            {(openBookingPriceIsLoading || openBookingPriceIsValidating || !openBookingPrice) && <LoadingDots />}
            {openBookingPrice && (
                <span className="">{Number(parseFloat(openBookingPrice.holidoit_price)).toFixed(2)} €</span>
            )}
        </Fragment>
    )
}

const BBRecap = () => {
    const {
        config,
        bookingCode,
        setStep,
        selectedVariant,
        selectedParticipants,
        selectedDate,
        selectedSlot,
        selectedAddons,
        handleLastStepSuccess,
        setBookingCode,
        selectedAddonsGift,
        mode,
        setDiscountCode
    } = useBBContext()
    const { setStep: setCheckoutStep, checkoutSteps } = useCOContext()

    const [bookingCreationInProgress, setBookingCreationInProgress] = useState<boolean>(false)
    const [bookingCreationError, setBookingCreationError] = useState<boolean>(false)

    const { i18n, t } = useTranslation()
    const language = i18n.language

    const isPartner = true
    // config.embedSettings?.destination !== EmbedDestination.SAAS &&
    // config.embedSettings?.destination !== EmbedDestination.WELFARE

    async function goToPayment() {
        setBookingCreationInProgress(true)
        if (mode === BOOKING_BOX_TYPE.BOOKING) {
            if (
                !selectedVariant?.id ||
                !selectedParticipants?.length ||
                selectedParticipants?.length < 1 ||
                !selectedSlot?.starting_time
            ) {
                debugLog('insufficient data to create a booking')
                return
            }

            const body: CreateBookingRequestBody = {
                allocations: prepareParticipantsForCreateBooking(selectedParticipants),
                slot: selectedSlot.starting_time,
                addons: selectedAddons ?? [],
            }

            debugLog('[BBRecap] booking_to_delete', bookingCode)

            const res = await fetchWithLanguage(
                api.endpoints.backend.bookingBox.createEmbedBooking
                    .replace(':variantId', selectedVariant.id.toString())
                    .replace(':embedId', config.embed_id)
                    .concat(
                        isPartner
                            ? `?${bookingCode && bookingCode.length > 0 ? `booking_to_delete=${bookingCode}` : ''}`
                            : ''
                    ),
                {
                    language,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                }
            )

            if (res.status !== 200) {
                setBookingCreationError(true)
                setBookingCreationInProgress(false)
            } else {
                const data: CreateBookingResponse = await res.json()
                debugLog('[BBRecap] order data', data)
                // Save the booking code in the context
                setBookingCode(data.code)
                setCheckoutStep(checkoutSteps[CheckoutStepId.YOUR_DATA])
                debugLog('[BBRecap] Booking creation successful. Go to checkout -> data:', data)
                handleLastStepSuccess && handleLastStepSuccess()
                debugLog('[BBRecap] handleLastStepSuccess done!')
            }
        } else if (mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING) {
            if (
                !selectedVariant?.id ||
                !selectedParticipants?.length ||
                selectedParticipants?.length < 1
            ) {
                debugLog('insufficient data to create a booking')
                return
            }

            const body: CreateOpenBookingRequestBody = {
                allocations: prepareParticipantsForCreateBooking(selectedParticipants),
                addons: selectedAddonsGift ?? [],
            }

            const res = await fetchWithLanguage(
                api.endpoints.backend.bookingBox.createOpenEmbedBooking
                    .replace(':variantId', selectedVariant.id.toString())
                    .replace(':embedId', config.embed_id),
                {
                    language,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                }
            )
            if (res.status !== 200) {
                setBookingCreationError(true)
                setBookingCreationInProgress(false)
            } else {
                const data: CreateBookingResponse = await res.json()
                debugLog('[BBRecap] order data', data)
                // Save the booking code in the context
                setDiscountCode(data.code)
                setCheckoutStep(checkoutSteps[CheckoutStepId.RECIPIENT_DATA])
                debugLog('[BBRecap] Open Booking creation successful. Go to checkout -> data:', data)
                handleLastStepSuccess && handleLastStepSuccess()
                debugLog('[BBRecap] handleLastStepSuccess done!')
            }
        }
    }

    if (mode == BOOKING_BOX_TYPE.BOOKING && !selectedSlot) setStep((s) => s - 1)

    return (
        <Fragment>
            <div className="flex flex-col justify-between gap-2 screen775:!pb-[82px]">
                {selectedVariant && selectedParticipants && (
                    <div className="flex flex-col gap-2">
                        <InfoBox>
                            <div className="flex flex-row items-center gap-2">
                                {formatDuration(selectedVariant?.formatted_duration, t)}
                                <span className="ml-[2px]">{' • '}</span>
                                <div className="ml-[2px] text-[14.5px]">
                                    <span>{t(selectedVariant.type)}</span>
                                </div>
                                <span className="ml-[2px]">{selectedVariant.output_name && ' • '}</span>

                                {selectedVariant.output_name && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: parseHtmlStrict(selectedVariant.output_name),
                                        }}
                                    />
                                )}
                            </div>
                        </InfoBox>

                        <InfoBox>{getParticipantsString(selectedParticipants)}</InfoBox>

                        <Fragment>
                            {mode === BOOKING_BOX_TYPE.BOOKING && selectedDate && selectedSlot && (
                                <Fragment>
                                    <InfoBox>{formatDate(new Date(selectedDate), language)}</InfoBox>
                                    <InfoBox>
                                        <span>
                                            {formatTime(new Date(selectedSlot.starting_time))} {' - '}{' '}
                                            {formatEndingTime(
                                                new Date(selectedSlot.starting_time),
                                                selectedVariant.formatted_duration!
                                            )}
                                        </span>
                                    </InfoBox>
                                    {selectedAddons && selectedAddons.length > 0 && (
                                        <InfoBox>
                                            <span>
                                                {selectedAddons.map((a, index) => (
                                                    <p key={index}>{a.qt + ' ' + a.name}</p>
                                                ))}
                                            </span>
                                        </InfoBox>
                                    )}
                                </Fragment>
                            )}
                            {mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING && (
                                <Fragment>
                                    {selectedAddonsGift && selectedAddonsGift.length > 0 && (
                                        <InfoBox>
                                            <span>
                                                {selectedAddonsGift.map((a, index) => (
                                                    <p key={index}>{a.qt + ' ' + a.name}</p>
                                                ))}
                                            </span>
                                        </InfoBox>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    </div>
                )}
                <div
                    className={clsx(
                        'absolute bottom-0 left-0 flex w-full items-center justify-center gap-3 border-t-[1.5px] border-zinc-300 bg-white px-6 py-5 shadow-top screen775:!px-5 screen775:!py-4',
                        bookingCreationError ? '' : 'max-h-[94px]'
                    )}
                >
                    <div className="flex h-full w-[154px] flex-col gap-[2px] text-[16.5px] font-medium text-zinc-800 screen775:!text-[16px]">
                        <span
                            className={clsx(
                                (config?.customer_fee ?? 0) > 0 ? '-mb-[1.5px] text-[13px]' : 'text-[13.5px]',
                                'uppercase text-zinc-500/95'
                            )}
                        >
                            {t('total')}
                        </span>
                        {mode === BOOKING_BOX_TYPE.BOOKING && (
                            <BookingTotalPrice
                                config={config}
                                selectedSlot={selectedSlot}
                                selectedAddons={selectedAddons}
                            />
                        )}
                        {mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING && (
                            <OpenBookingTotalPrice
                                selectedVariant={selectedVariant}
                                selectedParticipants={selectedParticipants}
                                selectedAddonsGift={selectedAddonsGift}
                                config={config}
                            />
                        )}
                    </div>

                    {handleLastStepSuccess && (
                        <BookButton
                            isLoading={bookingCreationInProgress}
                            label={t('continue')}
                            onClick={(e: { preventDefault: () => void }) => {
                                e.preventDefault()
                                setBookingCreationInProgress(true)
                                setBookingCreationError(false)
                                goToPayment()
                            }}
                            bookingCreationError={bookingCreationError}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export { BBRecap }
