import { Fragment, useEffect } from 'react'
import { useExperiencePickerContext } from './Context'
import { ExperienceList } from './components/ExperienceList'
import { ExperiencePickerStep } from '../types'
import { ExperienceInfo } from './components/ExperienceInfo'
import { debugLog } from '../lib/utils'

interface ExperiencePickerProps extends React.ComponentPropsWithoutRef<'div'> {
    setShow: (show: boolean) => void
    startingExperienceId: number | null
    setStartingExperienceId: (experienceId: number | null) => void
}

const ExperiencePicker = ({
    setShow,
    startingExperienceId,
    setStartingExperienceId,
}: ExperiencePickerProps): JSX.Element => {
    const { step, experience, experiences, setStep, setExperience, handleGoToNextStep, handleGoBack } =
        useExperiencePickerContext()
    debugLog('ExperiencePicker', { step, experience, experiences })

    // add listener for iframe
    useEffect(() => {
        debugLog('startingExperienceId', startingExperienceId)
        if (startingExperienceId) {
            const selectedExperience = experiences.find((exp) => exp.id === startingExperienceId)
            if (selectedExperience) {
                setExperience(selectedExperience)
                debugLog('show_embed_product_page', selectedExperience.show_embed_product_page)
                if (selectedExperience.show_embed_product_page) {
                    setStep(ExperiencePickerStep.INFO)
                    handleGoBack()
                } else {
                    handleGoToNextStep()
                    setStep(ExperiencePickerStep.HOME)
                }
                setShow(true)
            }
            setStartingExperienceId(null)
        }
    }, [startingExperienceId])

    // useEffect(() => {
    //     if (experiences.length === 1) {
    //         setExperience(experiences[0])
    //         setStep(ExperiencePickerStep.INFO)
    //     }
    // }, [experiences, setExperience, setStep]) // Run this effect when experiences change

    return (
        <Fragment>
            {(!experience || step === ExperiencePickerStep.HOME) && <ExperienceList setShow={setShow} />}
            {experience && experience.show_embed_product_page && step === ExperiencePickerStep.INFO && (
                <ExperienceInfo experience={experience} />
            )}
        </Fragment>
    )
}

export { ExperiencePicker }
