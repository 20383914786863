import { useEffect, useState, useRef } from 'react'

export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)
    const isFirstRun = useRef(true) // useRef to track the first run

    useEffect(() => {
        // If it's the first run, set the value immediately and update the flag
        if (isFirstRun.current) {
            setDebouncedValue(value)
            isFirstRun.current = false
            return
        }

        // For subsequent runs, use the timeout
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return debouncedValue
}
