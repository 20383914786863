import { Fragment, useEffect, useRef, useState } from 'react'
import useSWR from 'swr'

import { ApiError } from './ApiError'
import { BookingBoxFlow } from './BookingBox/BookingBox'
import { BBProvider, BookingStep, BookingSteps, useBBContext } from './BookingBox/Context'
import { CheckoutStepId, COProvider, useCOContext } from './Checkout/Context'
import { EPProvider, useExperiencePickerContext } from './ExperiencePicker/Context'
import { ExperiencePicker } from './ExperiencePicker/ExperiencePicker'
import { api } from './api'
import { cancelCheckoutBooking, debugLog, fetchWithLanguage, getCurrentDomain } from './lib/utils'
import { BOOKING_BOX_TYPE, BookingEmbedConfig, BookingEmbedStep, ExperiencePickerStep } from './types'

import clsx from 'clsx'
import { ArrowLeftIcon, GlobeIcon, XMarkIcon } from './lib/icons/icons'
import { COFlow } from './Checkout/Checkout'
import { AnalyticsProvider } from './Analytics/Context'

interface BookingEmbedProps extends React.ComponentPropsWithoutRef<'div'> {
    currentConfig: BookingEmbedConfig
    closeDrawer: () => void
    language: string
    changeLanguage: (lng: string, triggerMessage: boolean) => void
    setShow: (show: boolean) => void
    startingExperienceId: number | null
    setStartingExperienceId: (startingExperienceId: number | null) => void
}

const ENV = import.meta.env.ENVIRONMENT ?? 'development'

const WHITELISTED_DOMAINS = [
    'holidoit.com',
    'www.holidoit.com',
    'app.holidoit.com',
    'app.holido.it',
    'holido.it',
    'development.beta.holidoit.com',
    'staging.beta.holidoit.com',
    ...(ENV === 'production' ? [] : ['localhost', 'ngrok.app']),
]

const GoBackArrowLeftIcon: React.FC<{
    bookingEmbedStep: BookingEmbedStep
    setBookingEmbedStep: (step: BookingEmbedStep) => void
    completedBookingCode: string | null
}> = ({ bookingEmbedStep, setBookingEmbedStep, completedBookingCode }) => {
    const { step: coStep, setStep: setCOStep, checkoutSteps } = useCOContext()
    const { bookingCode, setBookingCode, step: bbStep, setStep: setBBStep, mode, selectedVariant } = useBBContext()
    const { step: epStep, setStep: setEPStep, experience } = useExperiencePickerContext()
    return (
        <div
            className={clsx(
                'cursor-pointer rounded bg-white p-1.5 hover:!bg-zinc-50 screen600:!p-[8px]',
                // &&currentFullConfig.experiences.length > 1
                bookingEmbedStep === BookingEmbedStep.BOOKING ||
                    bookingEmbedStep === BookingEmbedStep.CHECKOUT ||
                    (bookingEmbedStep === BookingEmbedStep.EXPERIENCE && epStep === ExperiencePickerStep.INFO)
                    ? 'opacity-100'
                    : 'opacity-0',
                completedBookingCode && completedBookingCode.length > 0 && '!hidden'
            )}
            onClick={() => {
                // TODO split better
                if (bookingEmbedStep === BookingEmbedStep.BOOKING) {
                    if (bbStep === BookingSteps[BookingStep.Variant]) {
                        if (experience && experience.show_embed_product_page) {
                            setEPStep(ExperiencePickerStep.INFO)
                        } else {
                            setEPStep(ExperiencePickerStep.HOME)
                        }
                        setBookingEmbedStep(BookingEmbedStep.EXPERIENCE)
                        return
                    } else if (
                        [
                            BookingSteps[BookingStep.Participants],
                            BookingSteps[BookingStep.Date],
                            BookingSteps[BookingStep.Slot],
                        ].includes(bbStep)
                    ) {
                        setBBStep(bbStep - 1)
                        return
                    } else if (
                        [BookingSteps[BookingStep.GiftAddons], BookingSteps[BookingStep.Addons]].includes(bbStep)
                    ) {
                        if (mode === BOOKING_BOX_TYPE.BOOKING) {
                            setBBStep(BookingSteps[BookingStep.Slot])
                        } else {
                            setBBStep(BookingSteps[BookingStep.Participants])
                        }
                        return
                    } else if (bbStep === BookingSteps[BookingStep.Recap]) {
                        if (selectedVariant?.addons?.length > 0) {
                            if (mode === BOOKING_BOX_TYPE.BOOKING) {
                                setBBStep(BookingSteps[BookingStep.Addons])
                            } else {
                                setBBStep(BookingSteps[BookingStep.GiftAddons])
                            }
                            return
                        } else {
                            if (mode === BOOKING_BOX_TYPE.BOOKING) {
                                setBBStep(BookingSteps[BookingStep.Slot])
                            } else {
                                setBBStep(BookingSteps[BookingStep.Participants])
                            }
                            return
                        }
                    }
                    if (bbStep === BookingSteps[BookingStep.Recap]) {
                        setBBStep(BookingSteps[BookingStep.Addons])
                        return
                    }
                } else if (bookingEmbedStep === BookingEmbedStep.CHECKOUT) {
                    if (coStep.id === checkoutSteps[CheckoutStepId.YOUR_DATA].id) {
                        if (mode === BOOKING_BOX_TYPE.BOOKING) {
                            setBookingEmbedStep(BookingEmbedStep.BOOKING)
                            cancelCheckoutBooking(bookingCode, setBookingCode)
                        } else {
                            setCOStep(checkoutSteps[CheckoutStepId.RECIPIENT_DATA])
                        }
                        return
                    } else if (coStep.id === checkoutSteps[CheckoutStepId.PAYMENT].id) {
                        setCOStep(checkoutSteps[CheckoutStepId.YOUR_DATA])
                        return
                    } else if (coStep.id === checkoutSteps[CheckoutStepId.RECIPIENT_DATA].id) {
                        setBBStep(BookingSteps[BookingStep.Recap])
                        setBookingEmbedStep(BookingEmbedStep.BOOKING)
                        return
                    }
                } else if (bookingEmbedStep === BookingEmbedStep.EXPERIENCE && epStep === ExperiencePickerStep.INFO) {
                    setBookingEmbedStep(BookingEmbedStep.EXPERIENCE)
                    setEPStep(ExperiencePickerStep.HOME)
                    return
                } else {
                    return
                }
            }}
        >
            <ArrowLeftIcon className="h-[19px] w-[19px]" />
        </div>
    )
}

const DrawerCloserIcon: React.FC<{
    closeDrawer: () => void
    bookingEmbedStep: BookingEmbedStep
    setBookingEmbedStep: (step: BookingEmbedStep) => void
}> = ({ closeDrawer, bookingEmbedStep, setBookingEmbedStep }) => {
    // Component logic and JSX here
    const { bookingCode, setBookingCode } = useBBContext()
    const { completedBookingCode } = useCOContext()

    const onClick = () => {
        if (bookingEmbedStep === BookingEmbedStep.CHECKOUT && !completedBookingCode && bookingCode) {
            setBookingEmbedStep(BookingEmbedStep.BOOKING)
            cancelCheckoutBooking(bookingCode, setBookingCode)
        }
        closeDrawer()
    }
    return (
        <div onClick={onClick} className="cursor-pointer rounded bg-white p-1.5 hover:!bg-zinc-50 screen600:!p-[8px]">
            <XMarkIcon className="h-[19px] w-[19px]" />
        </div>
    )
}

const HolidoitBookingEmbed: React.FC<BookingEmbedProps> = ({
    currentConfig,
    closeDrawer,
    language,
    changeLanguage,
    setShow,
    startingExperienceId,
    setStartingExperienceId,
}) => {
    const [currentFullConfig, setCurrentFullConfig] = useState<BookingEmbedConfig | null>(null)
    const languageSet = useRef(false) // Ref to track if the language has been set

    // if there is the embed ID, load settings via API. Otherwise, use initial props
    const { data: _embedConfig, isLoading: isLoadingEmbedConfig } = useSWR<BookingEmbedConfig>(
        currentConfig && currentConfig.embed_id
            ? [api.endpoints.backend.embed.getConfig.replace(':embedId', currentConfig.embed_id), language]
            : null,
        async ([url, language]) => {
            const res = await fetchWithLanguage(url, { language })
            const data = await res.json()
            if (!languageSet.current) {
                changeLanguage(data.language, false)
                languageSet.current = true
            }
            return data
        },
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    )
    const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false)

    debugLog('[HolidoitBookingEmbed] currentFullConfig', currentFullConfig)

    // Enable if you want to render only an HolidoitBookingEmbed even for sites with multiple embeds
    useEffect(() => {
        if (!_embedConfig || currentConfig.embed_id !== _embedConfig.embed_id) {
            setCurrentFullConfig(null)
        } else {
            setCurrentFullConfig(_embedConfig)
        }
    }, [currentConfig, _embedConfig, isLoadingEmbedConfig])

    const [bookingEmbedStep, setBookingEmbedStep] = useState<BookingEmbedStep>(BookingEmbedStep.EXPERIENCE)
    const [completedBookingCode, setCompletedBookingCode] = useState<string | null>(null)

    let isAuthorized: boolean = false
    const currentDomain = getCurrentDomain()
    debugLog('[HolidoitBookingEmbed] currentDomain', currentDomain)

    // add listener for iframe
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.action === 'holidoitIFrameChangeLanguage') {
                const language = event.data.language
                changeLanguage(language, false)
            }
        }
        window.addEventListener('message', handleMessage)
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [changeLanguage])

    if (_embedConfig?.embed_id) {
        if (_embedConfig.authorized_domains.includes(currentDomain) || WHITELISTED_DOMAINS.includes(currentDomain)) {
            isAuthorized = true
        }
    }

    if (!_embedConfig?.embed_id && !isLoadingEmbedConfig)
        return (
            <div className="space-y-6">
                <div className="flex h-12 w-full items-center justify-between border-b border-zinc-300 bg-white px-3">
                    <div />

                    <div
                        onClick={closeDrawer}
                        className="cursor-pointer rounded bg-white p-1.5 hover:!bg-zinc-50 screen600:!p-[8px]"
                    >
                        <XMarkIcon className="h-[19px] w-[19px]" />
                    </div>
                </div>
                <div className="px-6 screen600:px-4">
                    <ApiError message="Embed ID not found" />
                </div>
            </div>
        )

    return (
        <div className="">
            {_embedConfig?.embed_id && !isAuthorized && !isLoadingEmbedConfig ? (
                <div>
                    <div className="flex h-12 w-full items-center justify-between border-b border-zinc-300 bg-white px-3">
                        <div />

                        <div className="flex items-center gap-1.5">
                            <div
                                onClick={closeDrawer}
                                className="cursor-pointer rounded bg-white p-1.5 hover:!bg-zinc-50 screen600:!p-[8px]"
                            >
                                <XMarkIcon className="h-[19px] w-[19px]" />
                            </div>
                        </div>
                    </div>
                    <div className="p-6">
                        <ApiError message="This domain is not authorized to receive bookings for this embed ID." />
                    </div>
                </div>
            ) : !currentFullConfig || isLoadingEmbedConfig ? (
                <div className="loading-pulse flex flex-col gap-3 p-6">
                    <div className="h-32 w-full rounded bg-zinc-50" />
                    <div className="h-32 w-full rounded bg-zinc-50" />
                </div>
            ) : (
                <AnalyticsProvider
                    props={{
                        gtmCode: currentFullConfig.gtm_code,
                    }}
                >
                    <EPProvider
                        props={{
                            config: currentFullConfig,
                            handleGoToNextStep: () => setBookingEmbedStep(BookingEmbedStep.BOOKING),
                            handleGoBack: () => setBookingEmbedStep(BookingEmbedStep.EXPERIENCE),
                        }}
                    >
                        <BBProvider
                            props={{
                                config: currentFullConfig,
                                handleGoBack: () => setBookingEmbedStep(BookingEmbedStep.EXPERIENCE),
                                handleLastStepSuccess: () => setBookingEmbedStep(BookingEmbedStep.CHECKOUT),
                            }}
                        >
                            <COProvider
                                props={{
                                    config: currentFullConfig,
                                    test: currentConfig.test,
                                    handleGoBack: () => setBookingEmbedStep(BookingEmbedStep.BOOKING),
                                    handleGoToExperienceStep: () => setBookingEmbedStep(BookingEmbedStep.EXPERIENCE),

                                    completedBookingCode: completedBookingCode,
                                    setCompletedBookingCode: setCompletedBookingCode,
                                }}
                            >
                                <Fragment>
                                    <div className="flex h-12 w-full items-center justify-between border-b border-zinc-300 bg-white px-3">
                                        <div className="flex items-center gap-1.5 text-zinc-800">
                                            <GoBackArrowLeftIcon
                                                bookingEmbedStep={bookingEmbedStep}
                                                setBookingEmbedStep={setBookingEmbedStep}
                                                completedBookingCode={completedBookingCode}
                                            />
                                        </div>

                                        <div className="hide items-center gap-1 text-xs text-zinc-300 screen775:flex">
                                            <div className="">Powered by</div>
                                            <a
                                                href="https://business.holidoit.com/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="text-[12.5px] font-medium text-zinc-300"
                                            >
                                                {/* <img
                                    // src="https://holidoit-public.s3.eu-south-1.amazonaws.com/holidoit-logo-white-transparent-background-gray.png++(7).png" // opacity 52 in white
                                    src="https://holidoit-public.s3.eu-south-1.amazonaws.com/holidoit-logo-white-transparent-background-gray.png++(8).png" // opacity 56 in white
                                    // src="https://holidoit-public.s3.eu-south-1.amazonaws.com/holidoit-logo-white-transparent-background-gray.png++(6).png" // opacity 60 in white
                                    width={62}
                                    height={25}
                                    className="object-cover"
                                /> */}
                                                Holidoit
                                            </a>
                                        </div>

                                        <div className="flex items-center gap-1.5">
                                            {bookingEmbedStep !== BookingEmbedStep.CHECKOUT && (
                                                <div
                                                    onClick={() => setIsLanguageModalVisible((prev) => !prev)}
                                                    className="cursor-pointer rounded bg-white p-1.5 hover:!bg-zinc-50 screen600:!p-[8px]"
                                                >
                                                    <GlobeIcon className="h-[19px] w-[19px] fill-none text-zinc-800" />
                                                </div>
                                            )}

                                            <DrawerCloserIcon
                                                closeDrawer={closeDrawer}
                                                bookingEmbedStep={bookingEmbedStep}
                                                setBookingEmbedStep={setBookingEmbedStep}
                                            />
                                        </div>
                                    </div>
                                    <div className="dynamic-body-height relative overflow-auto scrollbar-hide">
                                        <Fragment>
                                            {bookingEmbedStep === BookingEmbedStep.EXPERIENCE && (
                                                <ExperiencePicker
                                                    setShow={setShow}
                                                    startingExperienceId={startingExperienceId}
                                                    setStartingExperienceId={setStartingExperienceId}
                                                />
                                            )}
                                            {bookingEmbedStep === BookingEmbedStep.BOOKING && (
                                                <BookingBoxFlow
                                                    setShow={setShow}
                                                    startingExperienceId={startingExperienceId}
                                                    setStartingExperienceId={setStartingExperienceId}
                                                />
                                            )}
                                            {bookingEmbedStep === BookingEmbedStep.CHECKOUT && (
                                                <COFlow
                                                    setShow={setShow}
                                                    startingExperienceId={startingExperienceId}
                                                    setStartingExperienceId={setStartingExperienceId}
                                                />
                                            )}
                                        </Fragment>
                                    </div>
                                    {isLanguageModalVisible && (
                                        <div
                                            className="absolute inset-0 z-[100] overflow-hidden bg-black bg-opacity-40 p-3 pt-5"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setIsLanguageModalVisible(false)
                                            }}
                                        >
                                            <div
                                                className="flex flex-col gap-3 rounded bg-white p-4 px-5"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <div className="flex items-center justify-between gap-1.5">
                                                    <h2 className="text-lg font-medium">Choose a language</h2>
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setIsLanguageModalVisible(false)
                                                        }}
                                                        className="w-fit cursor-pointer p-2 hover:!bg-zinc-50 screen600:!p-[8px]"
                                                    >
                                                        <XMarkIcon className="h-[19px] w-[19px]" />
                                                    </div>
                                                </div>
                                                <div className="flex justify-center gap-2 pb-2">
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            changeLanguage('en', true)
                                                            setIsLanguageModalVisible(false)
                                                        }}
                                                        className={clsx(
                                                            'w-full rounded border p-2',
                                                            language === 'en' && 'border-gray-800 bg-zinc-50'
                                                        )}
                                                    >
                                                        English
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            changeLanguage('it', true)
                                                            setIsLanguageModalVisible(false)
                                                        }}
                                                        className={clsx(
                                                            'w-full rounded border p-2',
                                                            language === 'it' && 'border-gray-800 bg-zinc-50'
                                                        )}
                                                    >
                                                        Italiano
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            </COProvider>
                        </BBProvider>
                    </EPProvider>
                </AnalyticsProvider>
            )}
        </div>
    )
}

export default HolidoitBookingEmbed
