import React from 'react'
import { useCOContext } from '../../Context'
import { Fragment } from 'preact/compat'
import { useTranslation } from 'react-i18next'
import { GiftCardInputBox } from './GiftCardInput'
import { Coupon, DISCOUNT_TYPE } from '../../../types'
import { XMarkIcon } from '../../../lib/icons/icons'
import Divider from '../../../lib/components/Divider'
import {
    computeAmountToUseFromVoucher,
    computeBookingPriceWithCoupons,
    computeBookingPriceWithVoucher,
    computeDiscountAmount,
} from '../../lib/priceUtils'

export interface CheckoutFormProps extends React.ComponentPropsWithoutRef<'form'> {}

export const PaymentRecap: React.FC<CheckoutFormProps> = () => {
    const { booking, coupons, setCoupons } = useCOContext()

    const removeCoupon = (coupon: Coupon) => {
        // console.log('Remove coupon: ', coupon);
        if (coupons.length > 0 && coupons.includes(coupon)) {
            setCoupons((prevCoupons) => prevCoupons.filter((c) => c !== coupon))
        }
    }

    const { t } = useTranslation()
    const discounts = coupons.filter((coupon) => coupon.discount_type === DISCOUNT_TYPE.DISCOUNT_CODE)
    const vouchers = coupons.filter((coupon) => coupon.discount_type === DISCOUNT_TYPE.OPEN_BOOKING)

    return booking ? (
        <Fragment>
            <Divider className="my-2.5" />
            <div className="flex items-center justify-between text-[14.5px] font-medium">
                <span className="font-bold">{t('totalImport')}</span>
                <span className="font-bold">{Number(booking.holidoit_price).toFixed(2)} €</span>
            </div>
            {discounts && discounts.length > 0 && (
                <Fragment>
                    {discounts.map((discount, index) => (
                        <div key={index} className="flex items-center justify-between gap-2 text-[15px] font-normal">
                            <div className="flex items-center justify-between">
                                <span className="text-black">{discount.code}</span>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        removeCoupon(discount)
                                    }}
                                    className="ml-2 rounded-full bg-gray-50 p-1.5 hover:bg-gray-100"
                                >
                                    <XMarkIcon className="h-3 w-3" />
                                </button>
                            </div>
                            <span className="text-black">
                                - {Number(computeDiscountAmount(booking.partner_price, discount)).toFixed(2)} €
                            </span>
                        </div>
                    ))}
                </Fragment>
            )}

            {!(vouchers && vouchers.length > 0) && booking.has_deposit && (
                <Fragment>
                    <Divider className="my-2.5" />
                    <div className="flex items-center justify-between text-[14.5px] font-medium">
                        <span className="underline">{t('toBePaidOnSite')}</span>
                        <span>
                            {(Number(booking.holidoit_price) - Number(booking.deposit_holidoit_price)).toFixed(2)} €
                        </span>
                    </div>
                </Fragment>
            )}

            {vouchers && vouchers.length > 0 && (
                <Fragment>
                    {coupons.map((coupon, index) => (
                        <Fragment>
                            <div
                                key={index}
                                className="flex items-center justify-between gap-2 text-[15px] font-normal"
                            >
                                <div className="flex items-center justify-between">
                                    <span className="text-black">{coupon.code}</span>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            removeCoupon(coupon)
                                        }}
                                        className="ml-2 rounded-full bg-gray-50 p-1.5 hover:bg-gray-100"
                                    >
                                        <XMarkIcon className="h-3 w-3" />
                                    </button>
                                </div>
                                <span className="text-black">
                                    -
                                    {Number(
                                        computeAmountToUseFromVoucher(booking.holidoit_price, coupons, coupon)
                                    ).toFixed(2)}{' '}
                                    €
                                </span>
                            </div>
                        </Fragment>
                    ))}
                </Fragment>
            )}
            {(booking.has_deposit || coupons.length > 0) && (
                <Fragment>
                    <Divider className="my-2.5" />
                    <div className="flex items-center justify-between text-[14.5px] font-medium">
                        {t('youPay')}
                        <span>{computeBookingPriceWithCoupons(booking, coupons).toFixed(2)} €</span>
                    </div>
                </Fragment>
            )}
            <Divider className="my-2.5" />
            {computeBookingPriceWithVoucher(booking, vouchers) > 0 && (!discounts || discounts.length == 0) && (
                <div className="flex items-center justify-between">
                    <GiftCardInputBox />
                </div>
            )}
        </Fragment>
    ) : (
        <Fragment></Fragment>
    )
}
