import React, { useEffect } from 'react'

interface ViewportMetaDetails {
    viewportMeta: HTMLMetaElement
    originalContent: string | null
    created: boolean
}

const ViewportManager: React.FC = () => {
    useEffect(() => {
        // Function to modify or create the viewport meta tag
        const manageViewport = (): ViewportMetaDetails => {
            let viewportMeta = document.querySelector('meta[name="viewport"]') as HTMLMetaElement
            let created = false // Flag to check if we created the tag

            if (!viewportMeta) {
                // If viewport meta tag does not exist, create one
                viewportMeta = document.createElement('meta')
                viewportMeta.name = 'viewport'
                document.getElementsByTagName('head')[0].appendChild(viewportMeta)
                created = true
            }

            // Remember the original content to restore it on unmount
            const originalContent = viewportMeta.getAttribute('content')

            // Substitute the viewport meta tag's content with the specified settings
            const newContent = 'width=device-width, initial-scale=1, maximum-scale=1'
            viewportMeta.setAttribute('content', newContent)

            return { viewportMeta, originalContent, created }
        }

        // Manage the viewport on mount
        const { viewportMeta, originalContent, created } = manageViewport()

        // Cleanup function to revert changes on unmount
        return () => {
            if (created) {
                // If the tag was created by this component, remove it
                viewportMeta.parentNode?.removeChild(viewportMeta)
            } else {
                // Otherwise, revert to the original content
                if (originalContent !== null) {
                    // Check needed to satisfy TypeScript null check
                    viewportMeta.setAttribute('content', originalContent)
                }
            }
        }
    }, [])

    return null // This component does not render anything
}

export default ViewportManager
