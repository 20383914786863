import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import { AddonList } from 'src/lib/components/AddonList'
import LoadingDots from 'src/lib/components/LoadingDots'
import RadioBox from 'src/lib/components/RadioBox'
import useSWR from 'swr'
import { api } from '../api'
import { fetchWithLanguage, formatDate, formatTime, stringifyParticipants } from '../lib/utils'
import { SlotsResponse } from '../types'
import { BookingStep, BookingSteps, useBBContext } from './Context'

const BBSlot = () => {
    const {
        setStep,
        selectedVariant,
        selectedParticipants,
        selectedDate,
        selectedSlot,
        setSelectedSlot,
        setSelectedAddons,
    } = useBBContext()

    const handler = useSwipeable({
        onSwipedRight: () => {
            setStep(BookingSteps[BookingStep.Date])
        },
    })

    const { i18n } = useTranslation()
    const language = i18n.language

    async function fetcher(url: string) {
        const response = await fetchWithLanguage(url, {
            language,
            method: 'GET',
        })
        const data = await response.json().then((res) => {
            const slots = res.slots as SlotsResponse['slots']
            if (slots && slots.length === 1 && slots[0].addons?.length === 0) {
                setSelectedSlot(slots[0])
                setStep(BookingSteps[BookingStep.Recap])
            }
            return slots
        })
        return data
    }

    const url = `${api.endpoints.backend.bookingBox.variantDaySlots
        .replace(':id', selectedVariant?.id?.toString() ?? '')
        .concat(`?date=${selectedDate}&allocations=${stringifyParticipants(selectedParticipants)}`)}`

    // Ottengo gli slot per la variante e le allocazioni e il giorno selezionate nello step precedente
    const {
        data: slots,
        isLoading,
        isValidating,
    } = useSWR<SlotsResponse['slots']>(url, fetcher, {
        revalidateOnFocus: false,
        keepPreviousData: true,
        revalidateIfStale: false,
    })

    const { t } = useTranslation()

    // debugLog('[BBSlot] slots', slots)

    return (
        <div className="h-auto min-h-[50dvh] overflow-y-auto pb-5" {...handler}>
            <div className="mt-1 text-[14.5px] font-medium">
                {!isLoading && !isValidating && slots && slots.length !== 0 && (
                    <Fragment>
                        {selectedDate && <span>{formatDate(new Date(selectedDate), language)}</span>}
                        <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                        <span className="text-emerald-500">{t('available')}</span>
                    </Fragment>
                )}
            </div>
            <div className="mt-1 text-[14.5px] font-medium">
                {!isLoading && !isValidating && slots && slots.length === 0 && (
                    <Fragment>
                        {selectedDate && <span>{formatDate(new Date(selectedDate), language)}</span>}
                        <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                        <span className="text-red-700">{t('noAvailableSlots')}</span>
                    </Fragment>
                )}
            </div>

            {(isValidating || isLoading) && (
                <Fragment>
                    <div className="flex h-[140px] w-full items-center justify-center rounded-[4px] bg-white pb-8 screen600:h-[200px] screen600:pb-4">
                        <div className="flex h-[58px] w-[60px] items-center justify-center rounded-[5px] bg-zinc-100">
                            <LoadingDots />
                        </div>
                    </div>
                </Fragment>
            )}

            {!isLoading && !isValidating && slots && slots.length > 0 && (
                <div className="mt-3">
                    <div className="flex flex-col gap-[9px] screen600:gap-2">
                        {slots.map((slot, index) => (
                            <div
                                key={index}
                                className={`group/radio flex h-[52px] w-full items-center rounded-[4px] border bg-white py-1 text-[15px]  ${
                                    selectedSlot === slot ? 'border-zinc-600' : 'border-zinc-300'
                                } ${slot.available ? 'opacity-100 hover:!border-zinc-600' : 'opacity-50'}`}
                            >
                                <RadioBox
                                    id={`radio-booking-choose-time-${index}`}
                                    name={`radio-booking-choose-time-${index}`}
                                    value={slot.starting_time.substring(11, 16)}
                                    checked={selectedSlot === slot}
                                    disabled={!slot.available}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setSelectedSlot(slot)
                                        if (slot.addons?.length > 0) {
                                            setSelectedAddons(
                                                slot.addons.map((a) => {
                                                    return {
                                                        ...a,
                                                        price: a.holidoit_price,
                                                        qt: 0,
                                                    }
                                                })
                                            )
                                            setStep(BookingSteps[BookingStep.Addons])
                                        } else {
                                            setSelectedAddons(null)
                                            setStep(BookingSteps[BookingStep.Recap])
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSelectedSlot(slot)
                                        if (slot.addons?.length > 0) {
                                            setSelectedAddons(
                                                slot.addons.map((a) => {
                                                    return {
                                                        ...a,
                                                        price: a.holidoit_price,
                                                        qt: 0,
                                                    }
                                                })
                                            )
                                            setStep(BookingSteps[BookingStep.Addons])
                                        } else {
                                            setSelectedAddons(null)
                                            setStep(BookingSteps[BookingStep.Recap])
                                        }
                                    }}
                                    slot={slot}
                                    cont={
                                        <div className="flex max-w-[175px] items-center gap-2 truncate text-[14.5px] leading-5 ">
                                            <span className={!slot.available ? 'line-through' : ''}>
                                                {formatTime(new Date(slot.starting_time))}
                                            </span>
                                            {!slot.available && (
                                                <div className="rounded bg-red-50 p-1.5 py-[4px] text-[11.5px] !font-medium uppercase text-red-700">
                                                    {t('notAvailable')}
                                                </div>
                                            )}
                                            {slot.addons && slot.available && slot.addons.length > 0 && (
                                                <Fragment>
                                                    <span className="mx-0.5 text-[13px] text-gray-400">•</span>
                                                    <AddonList
                                                        addons={slot.addons}
                                                        className="h-[14px] w-[14px] !text-gray-400"
                                                    />
                                                    <span className="text-[13px] text-gray-400">{t('optional')}</span>
                                                </Fragment>
                                            )}
                                        </div>
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
export { BBSlot }
