import dayjs from 'dayjs'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import { useExperiencePickerContext } from 'src/ExperiencePicker/Context'
import { api } from 'src/api'
import LoadingDots from 'src/lib/components/LoadingDots'
import { ChevronLeft, ChevronRight } from 'src/lib/icons/icons'
import { ApiAvailabilitiesResponse, locales } from 'src/types'
import useSWR from 'swr'
import {
    fetchWithLanguage,
    handleChangeCurrentDateV2,
    handleCurrentDate,
    handleDaysInRange,
    stringifyParticipants,
} from '../lib/utils'
import { BookingStep, BookingSteps, useBBContext } from './Context'

const BBDate = () => {
    const { setStep, selectedVariant, selectedParticipants, selectedDate, setSelectedDate } = useBBContext()

    const { experience } = useExperiencePickerContext()

    const handler = useSwipeable({
        onSwipedRight: () => {
            setStep(BookingSteps[BookingStep.Participants])
        },
    })

    // today is used to prevent the user to go back to past months
    const notice_period = experience.notice_period ?? 48

    let oggi = new Date()
    if (selectedVariant?.first_available_month) {
        if (selectedVariant.first_available_month < oggi.getMonth() + 1) {
            /* The first availability is in the following year */
            oggi = new Date(oggi.getFullYear() + 1, selectedVariant.first_available_month - 1, 1)
        } else if (selectedVariant.first_available_month > oggi.getMonth() + 1) {
            oggi = new Date(oggi.getFullYear(), selectedVariant.first_available_month - 1, 1)
        }
    }

    const notice_period_in_days = (notice_period ?? 48) / 24

    if (oggi.getDate() > new Date(oggi.getFullYear(), oggi.getMonth() + 1, 0 - notice_period_in_days).getDate()) {
        oggi.setMonth(oggi.getMonth() + 1, 1)
    }

    // Create a const today with the date of oggi + notice_period


    const result = oggi.setDate(oggi.getDate())
    const today = new Date(result)
    // currentDate is set when the user changes month
    const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(
        selectedDate ? dayjs(selectedDate).set('D', 1) : dayjs(today)
    )
    const [startOfRange, endOfRange] = handleCurrentDate(currentDate)
    const [dateFrom, dateTo] = handleChangeCurrentDateV2(currentDate)
    // OLD CODE
    // const [dateFrom, dateTo] = handleChangeCurrentDate(startOfRange, endOfRange)
    const daysInRange = handleDaysInRange(startOfRange, endOfRange)

    // OLD next month dates
    // const nextCurrentDate = currentDate.clone().add(1, 'month')
    // const [startOfRangeNextMonth, endOfRangeNextMonth] = handleCurrentDate(nextCurrentDate)
    // const [dateFromNextMonth, dateToNextMonth] = handleChangeCurrentDate(startOfRangeNextMonth, endOfRangeNextMonth)

    const nextCurrentDate = currentDate.clone().add(1, 'month').set('date', 1)
    const [dateFromNextMonth, dateToNextMonth] = handleChangeCurrentDateV2(nextCurrentDate)

    const [waitFetchNextMonth, setWaitFetchNextMonth] = useState(true)

    const { i18n, t } = useTranslation()
    const language = i18n.language

    // API fetcher
    async function fetcher(url: string) {
        const response = await fetchWithLanguage(url, {
            language,
            method: 'GET',
        })
        const json = await response.json()
        return json
    }

    const url = `${api.endpoints.backend.bookingBox.variantAvailabilities
        .replace(':id', selectedVariant?.id?.toString() ?? '')
        .concat(`?date_from=${dateFrom}&date_to=${dateTo}&allocations=${stringifyParticipants(selectedParticipants)}`)}`

    // // next month url
    const nextMonthUrl = `${api.endpoints.backend.bookingBox.variantAvailabilities
        .replace(':id', selectedVariant?.id?.toString() ?? '')
        .concat(
            `?date_from=${dateFromNextMonth}&date_to=${dateToNextMonth}&allocations=${stringifyParticipants(
                selectedParticipants
            )}`
        )}`

    // Ottengo le disponibilità per la variante e le allocazioni selezionate nello step precedente
    const {
        data: days,
        isLoading,
        isValidating,
    } = useSWR<ApiAvailabilitiesResponse>(url, fetcher, {
        revalidateOnFocus: false,
        keepPreviousData: true,
        revalidateIfStale: false,
    })

    // // prefetch next month days
    const { data: nextMonthDays } = useSWR<ApiAvailabilitiesResponse>(
        waitFetchNextMonth ? null : nextMonthUrl,
        fetcher,
        {
            revalidateOnFocus: false,
            keepPreviousData: true,
            revalidateIfStale: false,
        }
    )

    const isDateNotPast = (theDate: dayjs.Dayjs) => {
        const now = dayjs(new Date()).startOf('day')
        theDate.startOf('day')
        return theDate >= now
    }

    const isDateAvailable = (theDate: dayjs.Dayjs) => {
        if (!days?.available_days) {
            return false
        } else {
            return days.available_days
                .map((d) => dayjs(d))
                .find((el) => {
                    return el.isSame(theDate, 'day')
                })
        }
    }

    // create an animation for the loading dots even when the data for dates is available but the user is switching month in the calendar
    // the loading dots will be shown for 0.3 seconds
    const [showLoadingDots, setShowLoadingDots] = useState(true)
    useEffect(() => {
        setShowLoadingDots(true)

        const handler = setTimeout(() => {
            setShowLoadingDots(false)
        }, 200)

        return () => {
            clearTimeout(handler)
        }
    }, [currentDate])

    useEffect(() => {
        setWaitFetchNextMonth(false)
    }, [])

    return (
        <Fragment>
            <div className={`relative h-auto w-full overflow-y-auto pb-5 text-[14px] leading-[19px]`} {...handler}>
                <Fragment>
                    {(isLoading || showLoadingDots) && (
                        <div className="absolute left-[calc(50%-20px)] top-[calc(40%+30px)] flex h-[58px] w-[60px] items-center justify-center rounded-[5px] bg-zinc-100">
                            <LoadingDots />
                        </div>
                    )}

                    {currentDate && (
                        <Fragment>
                            <div className="mt-[13px] w-full screen775:!mt-[9px]">
                                <div>
                                    <div className="border-b-[1.5px] border-neutral-95 pb-[20px] text-center">
                                        <div className="relative flex w-full items-center justify-center">
                                            <button
                                                disabled={
                                                    (currentDate.month() === today.getMonth() &&
                                                    currentDate.year() === today.getFullYear()
                                                        ? true
                                                        : false) ||
                                                    isValidating ||
                                                    isLoading
                                                    // showLoadingDots
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    if (currentDate.month() > 0) {
                                                        setCurrentDate(
                                                            currentDate
                                                                .clone()
                                                                .set('date', 1)
                                                                .set('month', currentDate.month() - 1)
                                                        )
                                                    } else {
                                                        setCurrentDate(
                                                            currentDate
                                                                .clone()
                                                                .set('date', 1)
                                                                .set('month', 11)
                                                                .set('year', currentDate.year() - 1)
                                                        )
                                                    }
                                                }}
                                                className="absolute left-0 top-1/2 -translate-y-1/2 cursor-pointer rounded-[3px] bg-zinc-50 p-2 hover:cursor-pointer enabled:hover:!bg-zinc-100/90 disabled:!cursor-not-allowed disabled:text-gray-300 disabled:opacity-50"
                                            >
                                                <ChevronLeft className="h-[18px] w-[18px]" />
                                            </button>
                                            <div className="text-[16px] font-medium capitalize leading-[21px]">
                                                {locales[language].months[currentDate.month()] +
                                                    ' ' +
                                                    currentDate.year()}
                                            </div>
                                            <button
                                                disabled={isLoading || isValidating}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    if (currentDate.month() < 11) {
                                                        setCurrentDate(
                                                            currentDate
                                                                .clone()
                                                                .set('date', 1)
                                                                .set('month', currentDate.month() + 1)
                                                        )
                                                    } else {
                                                        setCurrentDate(
                                                            currentDate
                                                                .clone()
                                                                .set('date', 1)
                                                                .set('month', 0)
                                                                .set('year', currentDate.year() + 1)
                                                        )
                                                    }
                                                }}
                                                className="absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer rounded-[3px] bg-zinc-50 p-2 hover:cursor-pointer enabled:hover:!bg-zinc-100/90 disabled:!cursor-not-allowed disabled:text-gray-300 disabled:opacity-50"
                                            >
                                                <ChevronRight className="h-[18px] w-[18px]" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-7 gap-[4px] screen775:!gap-[3px]">
                                    {[t('MON'), t('TUE'), t('WED'), t('THU'), t('FRI'), t('SAT'), t('SUN')].map(
                                        (g, index) => (
                                            <div
                                                className="text-neutral aspect-square border-neutral-80 text-[11.5px] font-medium leading-[42px] text-zinc-500"
                                                key={index}
                                            >
                                                <div className="flex h-full w-full items-center justify-center">
                                                    {g}
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {daysInRange.map((week, index) => {
                                        return week.map((d, dIndex) => (
                                            <div
                                                key={dIndex}
                                                className={`text-[15px] ${
                                                    currentDate.month() !== d.month() || !isDateNotPast(d)
                                                        ? 'text-neutral border-neutral-80'
                                                        : ''
                                                }`}
                                            >
                                                <div className="flex h-full w-full items-center justify-center">
                                                    {currentDate.month() === d.month() &&
                                                        isDateNotPast(d) &&
                                                        isDateAvailable(d) && (
                                                            <button
                                                                disabled={
                                                                    !isDateAvailable(d) ||
                                                                    !isDateNotPast(d) ||
                                                                    isLoading ||
                                                                    isValidating
                                                                }
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setSelectedDate(d.format('YYYY-MM-DD'))
                                                                    setStep(BookingSteps[BookingStep.Slot])
                                                                }}
                                                                className={`flex aspect-square w-full cursor-pointer items-center justify-center rounded-[4px] hover:cursor-pointer ${
                                                                    selectedDate === d.format('YYYY-MM-DD')
                                                                        ? ' bg-sky-100 font-medium text-black'
                                                                        : 'hover:!bg-sky-100'
                                                                }`}
                                                            >
                                                                {d.date()}
                                                            </button>
                                                        )}
                                                    {(currentDate.month() !== d.month() ||
                                                        !isDateNotPast(d) ||
                                                        !isDateAvailable(d)) && (
                                                        <button
                                                            disabled={
                                                                currentDate.month() !== d.month() ||
                                                                !isDateAvailable(d) ||
                                                                !isDateNotPast(d) ||
                                                                isLoading ||
                                                                isValidating
                                                            }
                                                            className={`cursor-pointer ${
                                                                (!isDateNotPast(d) || !isDateAvailable(d)) &&
                                                                'line-through'
                                                            } 
                                                                    ${
                                                                        currentDate.month() == d.month() &&
                                                                        !isDateAvailable(d) &&
                                                                        !isLoading &&
                                                                        !isValidating &&
                                                                        'aspect-square w-full bg-gray-50 text-error-excluded-red opacity-90'
                                                                    }
                                                                    ${
                                                                        currentDate.month() == d.month() &&
                                                                        !isDateNotPast(d) &&
                                                                        !isLoading &&
                                                                        !isValidating &&
                                                                        'hover:!bg-yellow-50'
                                                                    }
                                                                    ${
                                                                        currentDate.month() == d.month() &&
                                                                        isDateNotPast(d) &&
                                                                        !isDateAvailable(d) &&
                                                                        !isLoading &&
                                                                        !isValidating &&
                                                                        '!cursor-not-allowed !bg-red-100 !text-red-700 line-through !opacity-40'
                                                                    }
                                                                    ${
                                                                        currentDate.month() !== d.month() &&
                                                                        'aspect-square w-full !cursor-default !bg-white !text-gray-light !no-underline !opacity-70'
                                                                    }
                                                                    flex aspect-square w-full items-center justify-center rounded-[5px] text-gray-light`}
                                                        >
                                                            {d.date()}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    })}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            </div>

            {/* Preloaded days */}
            <div className="hidden">{nextMonthDays?.available_days?.map((d) => <div key={d}>{d}</div>)}</div>
        </Fragment>
    )
}
export { BBDate }
