import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { api } from '../api'
import { useBBContext } from '../BookingBox/Context'
import { debugLog, fetchWithLanguage, getJSONOrThrow } from '../lib/utils'
import {
    BookingEmbedConfig,
    COBookingInfo,
    COCustomerInfoForm,
    Coupon,
    IBBAddon,
    IJsonAllocation,
    IRecipientInfo,
    PaymentErrorType,
    PaymentMethod,
} from '../types'
import { useTranslation } from 'react-i18next'

interface CheckoutStep {
    id: number
    name: string
    title: string
    onClick?: () => void
}

// Add this at the top of the file with other interfaces/types
export enum CheckoutStepId {
    BOOKING = 1,
    RECIPIENT_DATA = 1.5,
    YOUR_DATA = 2,
    PAYMENT = 3,
}

export interface CheckoutConfig {
    config: BookingEmbedConfig
}

export interface CheckoutContextProps extends CheckoutConfig {
    handleGoBack?: () => void
    handleGoToExperienceStep?: () => void
    test?: boolean

    completedBookingCode: string | null
    setCompletedBookingCode: Dispatch<SetStateAction<string | null>>
}

interface OpenBookingInfo {
    code: string
    original_value: number
    partner_price: number
    status: string
    error?: string
    json_allocations: IJsonAllocation[]
    json_addons: IBBAddon[]
}

interface CheckoutContextType extends CheckoutContextProps {
    booking: COBookingInfo | null
    loadingBooking: boolean
    setBooking: Dispatch<SetStateAction<COBookingInfo | null>>

    checkoutSteps: Record<CheckoutStepId, CheckoutStep>
    step: CheckoutStep
    setStep: Dispatch<SetStateAction<CheckoutStep>>

    paypalToken: { token: string } | undefined

    customerInfo: COCustomerInfoForm
    setCustomerInfo: Dispatch<SetStateAction<COCustomerInfoForm>>
    onCustomerInfoSubmit: (_: COCustomerInfoForm) => void
    onOpenBookingCustomerInfoSubmit: (_: COCustomerInfoForm) => void
    isLoadingCustomerInfo: boolean
    isLoadingOpenBookingCustomerInfo: boolean
    peopleFeaturesNotes: string | null
    setPeopleFeaturesNotes: Dispatch<SetStateAction<string | null>>

    paymentMethod: PaymentMethod | null
    setPaymentMethod: Dispatch<SetStateAction<PaymentMethod | null>>

    paymentSuccess: boolean
    setPaymentSuccess: Dispatch<SetStateAction<boolean>>

    paymentError: PaymentErrorType | null
    setPaymentError: Dispatch<SetStateAction<PaymentErrorType | null>>

    openBooking: OpenBookingInfo | null

    // Recipient information
    recipientInfo: IRecipientInfo
    setRecipientInfo: Dispatch<SetStateAction<IRecipientInfo>>

    coupons: Coupon[]
    setCoupons: Dispatch<SetStateAction<Coupon[]>>
}

const BaseContext = createContext<CheckoutContextType | null>(null)

export default BaseContext

const emptyCustomerInfos = {
    first_name: '',
    last_name: '',
    email: '',
    confirm_email: '',
    phone: '',
    additional_info: '',
}

export const COProvider: React.FC<{
    children: ReactNode
    props: CheckoutContextProps
}> = ({ children, props }) => {
    const {
        config,
        handleGoBack,
        handleGoToExperienceStep,
        test = false,
        completedBookingCode,
        setCompletedBookingCode,
    } = props

    const { bookingCode, discountCode } = useBBContext()

    const [booking, setBooking] = useState<COBookingInfo | null>(null)
    const [openBooking, setOpenBooking] = useState<OpenBookingInfo | null>(null)

    const [coupons, setCoupons] = useState<Coupon[]>([]) // validated with gift card API

    const { i18n, t } = useTranslation()
    const language = i18n.language

    const checkoutSteps: Record<CheckoutStepId, CheckoutStep> = {
        [CheckoutStepId.BOOKING]: {
            id: CheckoutStepId.BOOKING,
            name: t('booking'),
            title: '',
            onClick: handleGoBack,
        },
        [CheckoutStepId.RECIPIENT_DATA]: {
            id: CheckoutStepId.RECIPIENT_DATA,
            name: t('recipientData'),
            title: t('recipientDataTitle'),
            onClick: () => setStep(checkoutSteps[CheckoutStepId.RECIPIENT_DATA])
        },
        [CheckoutStepId.YOUR_DATA]: {
            id: CheckoutStepId.YOUR_DATA,
            name: t('yourData'),
            title: t('bookingRequest'),
            onClick: () => setStep(checkoutSteps[CheckoutStepId.YOUR_DATA]),
        },
        [CheckoutStepId.PAYMENT]: {
            id: CheckoutStepId.PAYMENT,
            name: t('payment'),
            title: t('completePayment'),
        },
    }

    const [step, setStep] = useState<CheckoutStep>(checkoutSteps[CheckoutStepId.BOOKING])

    const {
        data: bookingInfo,
        error: errorBookingInfo,
        isLoading: loadingBooking,
    } = useSWR(
        bookingCode ? api.endpoints.backend.checkout.bookingRecap.replace(':code', bookingCode) : null,
        (url) => fetchWithLanguage(url, { language }).then((res) => res.json()),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    )

    useEffect(() => {
        if (!bookingCode) {
            return
        }
        if (errorBookingInfo || bookingInfo?.error) {
            // Handle Error
            debugLog('error while getting booking info')
            return
        } else {
            setBooking(bookingInfo)
        }
    }, [errorBookingInfo, bookingInfo, bookingCode])

    // API to get open booking info
    const { data: openBookingInfo, error: openBookingInfoError } = useSWR<OpenBookingInfo>(
        discountCode ? api.endpoints.backend.checkout.openBookingRecap.replace(':code', discountCode as string) : null,
        (url) => fetchWithLanguage(url, { language }).then((res) => res.json()),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    )

    useEffect(() => {
        if (!discountCode) {
            return
        }
        if (openBookingInfoError || openBookingInfo?.error) {
            // Handle Error
            debugLog('error while getting booking info')
            return
        } else {
            setOpenBooking(openBookingInfo)
        }
    }, [openBookingInfoError, openBookingInfo, discountCode])

    const [customerInfo, setCustomerInfo] = useState<COCustomerInfoForm>(emptyCustomerInfos)
    const [recipientInfo, setRecipientInfo] = useState<IRecipientInfo>(null)

    // Request to load booking created
    const { trigger: triggerCustomerInfo, isMutating: isLoadingCustomerInfo } = useSWRMutation(
        bookingCode ? api.endpoints.backend.checkout.customerInfo.replace(':code', bookingCode) : null,
        async (url: string, { arg: { email, first_name, last_name, phone, notes } }: any) => {
            return fetchWithLanguage(`${url}`, {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PUT',

                body: JSON.stringify({
                    email,
                    first_name,
                    last_name,
                    phone,
                    ...(notes ? { notes } : {}),
                }),
            }).then((response) => {
                debugLog('response customer info api', response)
                return response.status
            })
        }
    )

    const { trigger: triggerOpenBookingCustomerInfo, isMutating: isLoadingOpenBookingCustomerInfo } = useSWRMutation(
        discountCode ? api.endpoints.backend.checkout.openBookingCustomerInfo.replace(':code', discountCode) : null,
        async (url: string, { arg: { email, first_name, last_name, phone, notes } }: any) => {
            return fetchWithLanguage(`${url}`, {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PUT',
                body: JSON.stringify({
                    email,
                    first_name,
                    last_name,
                    phone,
                    ...(notes ? { notes } : {}),
                }),
            }).then((response) => {
                debugLog('response customer info api', response)
                return response.status
            })
        }
    )

    const [peopleFeaturesNotes, setPeopleFeaturesNotes] = useState<string | null>(null)

    const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false)
    const [paymentError, setPaymentError] = useState<PaymentErrorType | null>(null)

    // Request to confirm customer details and move on to payment step
    const onCustomerInfoSubmit = async (customerInfo: COCustomerInfoForm) => {
        const response = await triggerCustomerInfo({
            first_name: customerInfo.first_name,
            last_name: customerInfo.last_name,
            email: customerInfo.email,
            phone: customerInfo.phone,
            notes: customerInfo.additional_info,
        }).then((res) => {
            return res
        })
        setCustomerInfo({
            first_name: customerInfo.first_name,
            last_name: customerInfo.last_name,
            email: customerInfo.email,
            phone: customerInfo.phone,
            additional_info: customerInfo.additional_info,
            confirm_email: customerInfo.confirm_email,
        })
        if (response === 204) {
            setStep(checkoutSteps[CheckoutStepId.PAYMENT])
        } else {
            handleGoBack()
        }
    }

    const onOpenBookingCustomerInfoSubmit = async (customerInfo: COCustomerInfoForm) => {
        const response = await triggerOpenBookingCustomerInfo({
            first_name: customerInfo.first_name,
            last_name: customerInfo.last_name,
            email: customerInfo.email,
        }).then((res) => {
            return res
        })
        setCustomerInfo({
            first_name: customerInfo.first_name,
            last_name: customerInfo.last_name,
            email: customerInfo.email,
            confirm_email: customerInfo.confirm_email,
        })
        if (response === 204) {
            setStep(checkoutSteps[CheckoutStepId.PAYMENT])
        } else {
            handleGoBack()
        }
    }

    const { data: paypalToken } = useSWR<{ token: string }>(
        api.endpoints.backend.checkout.paypalGenerateToken,
        (url: string) => {
            return fetchWithLanguage(`${url}`, {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            }).then((res) => getJSONOrThrow(res))
        },
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    )

    debugLog('[COProvider] paypalToken', paypalToken)

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null)

    // When the config changes, re-initialise the checkout and go to the experience step
    useEffect(() => {
        setStep(checkoutSteps[CheckoutStepId.BOOKING])
        setPaymentSuccess(false)
        setPaymentMethod(null)
        handleGoToExperienceStep()
    }, [config])

    useEffect(() => {
       setCoupons([])
    }, [booking])

    return (
        <BaseContext.Provider
            value={{
                config,
                handleGoBack,
                handleGoToExperienceStep,
                completedBookingCode,
                setCompletedBookingCode,

                booking,
                loadingBooking,
                setBooking,
                checkoutSteps,
                step,
                setStep,
                coupons,
                setCoupons,

                paypalToken,

                customerInfo,
                setCustomerInfo,
                onCustomerInfoSubmit,
                onOpenBookingCustomerInfoSubmit,
                isLoadingCustomerInfo,
                isLoadingOpenBookingCustomerInfo,

                peopleFeaturesNotes,
                setPeopleFeaturesNotes,

                paymentMethod,
                setPaymentMethod,

                paymentSuccess,
                setPaymentSuccess,
                paymentError,
                setPaymentError,

                test,
                recipientInfo,
                setRecipientInfo,
                openBooking,
            }}
        >
            {children}
        </BaseContext.Provider>
    )
}

export const useCOContext = () => {
    const baseContext = useContext(BaseContext)

    if (!baseContext) {
        throw new Error('baseContext has to be used within <BaseProvider>')
    }

    return baseContext
}
