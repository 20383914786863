import { ChevronDownIcon, MinusIcon, PlusIcon } from '../../../lib/icons/icons'
import { AdditionalInformationInputTypeChoices } from '../../../types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { checkNullOrUndefined, debugLog, parseHtmlStrict } from '../../../lib/utils'
import clsx from 'clsx'
import { Checkbox } from '../../../lib/components/Checkbox'

type AdditionalInformationProps = {
    inputType: AdditionalInformationInputTypeChoices // Assuming AdditionalInformationInputTypeChoices is already defined elsewhere
    name: string
    unit?: string
    description?: string
    choices?: string[]
    value?: string
    defaultValue?: string
    updateInfo: (key: string, value: string, index?: number) => void
    index?: number
    className?: string
    optional?: boolean
    isSubmitted?: boolean
}

// Corrected function with the proper parameter type
export const additionalInformationFactory: React.FC<AdditionalInformationProps> = ({
    inputType,
    name,
    unit,
    description,
    defaultValue,
    choices,
    value,
    updateInfo,
    index,
    className,
    optional = false,
    isSubmitted,
}) => {
    const { t } = useTranslation()
    const isError =
        isSubmitted &&
        !optional &&
        (AdditionalInformationInputTypeChoices.CHECKBOX === inputType
            ? value !== t('accepted')
            : checkNullOrUndefined(value))

    switch (inputType) {
        case AdditionalInformationInputTypeChoices.TEXT:
            return (
                <TextInput
                    name={name}
                    value={value}
                    setCurrentData={updateInfo}
                    index={index}
                    className={className}
                    description={description}
                    optional={optional}
                    isError={isError}
                />
            )
        case AdditionalInformationInputTypeChoices.SELECT:
            return (
                <SelectInput
                    name={name}
                    value={value}
                    choices={choices}
                    setCurrentData={updateInfo}
                    index={index}
                    className={className}
                    description={description}
                    optional={optional}
                    isError={isError}
                />
            )
        case AdditionalInformationInputTypeChoices.NUMBER:
            return (
                <NumericInput
                    value={value}
                    name={name}
                    unit={unit}
                    step={5}
                    setCurrentData={updateInfo}
                    defaultValue={defaultValue}
                    index={index}
                    className={className}
                    description={description}
                    optional={optional}
                    isError={isError}
                />
            )
        case AdditionalInformationInputTypeChoices.CHECKBOX:
            return (
                <CheckboxInput
                    value={value}
                    name={name}
                    setCurrentData={updateInfo}
                    index={index}
                    className={className}
                    description={description}
                    optional={optional}
                    isError={isError}
                />
            )

        default:
            return null // Fallback if the type is not recognized
    }
}

function canBeNumber(value?: string | number): boolean {
    // Check if the value is a number
    if (typeof value === 'number') {
        return !isNaN(value) // Check if it's a valid number (not NaN)
    }

    // Check if the value is a non-empty string that can be converted to a valid number
    if (typeof value === 'string') {
        return value.trim() !== '' && !isNaN(Number(value))
    }

    // Return false if the value is neither a string nor a number
    return false
}

interface NumericInputProps {
    name: string
    unit?: string
    step?: number
    value: string
    defaultValue: string
    setCurrentData: (key: string, value: string, index?: number) => void
    index?: number
    className?: string
    description?: string
    optional: boolean
    isError: boolean
}

const NumericInput: React.FC<NumericInputProps> = ({
    name,
    value,
    setCurrentData,
    unit = '',
    step = 5,
    defaultValue,
    index,
    className,
    description,
    optional = false,
    isError,
}) => {
    if (!value) {
        const actualValue = value ?? defaultValue ?? 0
        const checkedValue = canBeNumber(actualValue) ? Number(actualValue) : 0
        setCurrentData(name, checkedValue.toString(), index)
    }

    return (
        <Fragment>
            <div
                key={name}
                className={clsx(`flex select-none flex-row items-center justify-between rounded-[4px] border bg-white px-4 py-2 pr-3 ${className}`,
                    isError ? 'border-red-500' : 'border-zinc-200')}
            >
                <div className="flex flex-row items-center gap-3">
                    <span>{name}</span>
                </div>
                <div className="flex flex-row items-center gap-3">
                    <div
                        onClick={(e) => {
                            e.preventDefault()
                            const actualValue = value ?? defaultValue ?? 0
                            const checkedValue = canBeNumber(actualValue) ? Number(actualValue) : 0
                            setCurrentData(name, (checkedValue - step).toString(), index)
                        }}
                        className="cursor-pointer rounded-[5px] border border-zinc-200 p-1 transition-all duration-100 ease-in-out hover:!border-zinc-300 hover:!bg-zinc-50 disabled:cursor-not-allowed"
                    >
                        <MinusIcon className="h-[18px] w-[18px]" />
                    </div>
                    <span className="w-[50px] text-center">{value + (unit ?? '')}</span>
                    <div
                        onClick={(e) => {
                            e.preventDefault()
                            const actualValue = value ?? defaultValue ?? 0
                            const checkedValue = canBeNumber(actualValue) ? Number(actualValue) : 0
                            setCurrentData(name, (checkedValue + step).toString(), index)
                        }}
                        className="cursor-pointer rounded-[5px] border border-zinc-200 p-1 transition-all duration-100 ease-in-out hover:!border-zinc-300 hover:!bg-zinc-50 disabled:cursor-not-allowed"
                    >
                        <PlusIcon className="h-[18px] w-[18px]" />
                    </div>
                </div>
            </div>
            {description && (
                <div
                    className="-mt-1 mb-1 text-[11px] leading-tight text-zinc-500"
                    dangerouslySetInnerHTML={{
                        __html: parseHtmlStrict(description),
                    }}
                />
            )}
        </Fragment>
    )
}

interface TextInputProps {
    name: string
    value: string
    defaultValue?: string
    setCurrentData: (key: string, value: string, index?: number) => void
    index?: number
    className?: string
    description?: string
    optional: boolean
    isError: boolean
}

const TextInput: React.FC<TextInputProps> = ({
    name,
    value,
    setCurrentData,
    defaultValue,
    index,
    className,
    description,
    optional = false,
    isError,
}) => {
    return (
        <div className="relative">
            <label
                htmlFor={name}
                className={clsx(
                    'pointer-events-none absolute left-3.5 top-1 text-[11.5px] font-medium ',
                    isError ? 'text-red-400' : 'text-zinc-500'
                )}
            >
                {name ?? ''}
                {!optional && <span className="ml-1 text-red-500">*</span>}
            </label>
            <input
                className={clsx(
                    `h-[44px] w-full cursor-text rounded border  bg-white px-3.5 pt-[15px] text-sm text-zinc-900  placeholder-zinc-500 focus:outline-none ${className}`,
                    isError
                        ? 'border-red-500 placeholder-red-400'
                        : 'border-zinc-200  placeholder-zinc-500 focus:border-zinc-900'
                )}
                type="text"
                id={name}
                value={value ?? defaultValue ?? ''}
                onChange={(e) => setCurrentData(name, e.target.value, index)} // Pass both name and current value to setCurrentData
            />
            {description && (
                <div
                    className="mb-1 ml-2 mt-1 text-[11px] leading-tight text-zinc-500"
                    dangerouslySetInnerHTML={{
                        __html: parseHtmlStrict(description),
                    }}
                />
            )}
        </div>
    )
}

interface SelectInputProps {
    name: string
    value: string
    choices: string[]
    setCurrentData: (key: string, value: string, index?: number) => void
    index?: number
    className?: string
    description?: string
    optional: boolean
    isError: boolean
}

const SelectInput: React.FC<SelectInputProps> = ({
    name,
    value,
    choices,
    setCurrentData,
    index,
    className,
    description,
    optional = false,
    isError,
}) => {
    const { t } = useTranslation()

    return (
        <Fragment>
            <div className="relative">
                <label
                    htmlFor={name}
                    className={clsx(
                        'pointer-events-none absolute left-3.5 top-1 text-[11.5px] font-medium',
                        isError ? 'text-red-400' : 'text-zinc-500'
                    )}
                >
                    {name ?? ''}
                    {!optional && <span className="ml-1 text-red-500">*</span>}
                </label>
                <div className="pointer-events-none absolute right-2.5 top-1/2 -translate-y-1/2 text-gray-700">
                    <ChevronDownIcon className="h-[18px] w-[18px]" />
                </div>
                <select
                    className={clsx(
                        `flex h-[48px] w-full cursor-pointer items-center rounded border  bg-white px-3.5 pt-[15px] text-[14.5px]  focus:outline-none`,
                        className,
                        isError
                            ? 'border-red-500 text-red-400 placeholder-red-400'
                            : 'border-zinc-200 text-zinc-900 placeholder-zinc-500'
                    )}
                    id={name}
                    value={value ?? ''}
                    onChange={(e) => setCurrentData(name, e.target.value, index)} // Pass both name and current value to setCurrentData
                >
                    <option value="" disabled hidden>
                        {t('selectOption')}
                    </option>
                    {choices.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            {description && (
                <div
                    className="-mt-1 mb-1 text-[11px] leading-tight text-zinc-500"
                    dangerouslySetInnerHTML={{
                        __html: parseHtmlStrict(description),
                    }}
                />
            )}
        </Fragment>
    )
}

interface CheckboxInputProps {
    name: string
    value: string
    setCurrentData: (key: string, value: string, index?: number) => void
    index?: number
    className?: string
    description?: string
    optional: boolean
    isError: boolean
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
    name,
    value,
    setCurrentData,
    index,
    className,
    description,
    optional,
    isError,
}) => {
    const { t } = useTranslation()
    const inputId = index ? `${name}-${index}` : name
    const isChecked = value === t('accepted')

    const setChecked = (name: string, flag: boolean, index?: number) => {
        const updatedValue = flag ? t('accepted') : t('unaccepted')
        setCurrentData(name, updatedValue, index)
    }

    return (
        <div className="mt-4 flex items-center">
            <Checkbox
                name={inputId}
                onChange={() => setChecked(name, !isChecked, index)}
                checked={isChecked}
                isError={isError}
                id={inputId}
            />
            {description && (
                <label
                    htmlFor={inputId}
                    className={clsx(
                        'prose ms-2 inline-flex text-sm font-medium',
                        isError ? 'text-red-500' : 'text-gray-900'
                    )}
                    dangerouslySetInnerHTML={{
                        __html:
                            parseHtmlStrict(modifyAnchorTags(description)) +
                            (!optional ? '<span class="ml-1 text-red-500">*</span>' : ''),
                    }}
                />
            )}
        </div>
    )
}

function modifyAnchorTags(htmlString) {
    const dom = new DOMParser().parseFromString(htmlString, 'text/html');
    const links = dom.querySelectorAll('a');

    links.forEach((link) => {
        // Add classes
        link.classList.add('cursor-pointer');
        // Add target and rel attributes
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'nofollow noopener');
    });

    return dom.body.innerHTML;
}
