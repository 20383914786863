import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import useSWRMutation from 'swr/mutation'
import { api } from 'src/api'
import { Loading } from 'src/lib/components/LoadingCircle'
import LoadingDots from 'src/lib/components/LoadingDots'
import { debugLog, fetchWithLanguage } from 'src/lib/utils'
import { useCOContext } from '../../Context'
import { useTranslation } from 'react-i18next'
import { useBBContext } from '../../../BookingBox/Context'
import { BOOKING_BOX_TYPE } from '../../../types'

export interface IPaypalPaymentSection extends React.ComponentPropsWithoutRef<'div'> {
    onPaypalPaymentCompleted: () => void
}

const PaypalGoldButton: React.FC<IPaypalPaymentSection> = () => {
    const {
        config,
        customerInfo,
        peopleFeaturesNotes,
        booking,
        loadingBooking,
        setPaymentSuccess,
        setPaymentError,
        paymentError,
        setCompletedBookingCode,
        openBooking,
        coupons,
    } = useCOContext()

    const { mode } = useBBContext()

    const { i18n, t } = useTranslation()
    const language = i18n.language

    const {
        trigger: confirmPaypalOrder,
        error: errorPaypalConfirmOrder,
        isMutating: loadingConfirmPaypalOrder,
    } = useSWRMutation(api.endpoints.backend.checkout.paypalConfirmOrder, (url: string, { arg: { orderId } }: any) => {
        return fetchWithLanguage(`${url.replace(':embedId', config.embed_id).replace(':orderId', orderId)}`, {
            language,
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',

            body: JSON.stringify({
                email: customerInfo.email,
                first_name: customerInfo.first_name,
                last_name: customerInfo.last_name,
                phone: customerInfo.phone,
                notes: peopleFeaturesNotes ?? undefined,
            }),
        })
    })

    const {
        trigger: confirmPaypalOpenBookingOrder,
        error: errorPaypalConfirmOpenBookingOrder,
        isMutating: loadingConfirmPaypalOpenBookingOrder,
    } = useSWRMutation(
        api.endpoints.backend.checkout.openBookingPaypalConfirmOrder,
        (url: string, { arg: { orderId } }: any) => {
            return fetchWithLanguage(`${url.replace(':orderId', orderId)}`, {
                language,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',

                body: JSON.stringify({
                    email: customerInfo.email,
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    phone: customerInfo.phone,
                    notes: peopleFeaturesNotes ?? undefined,
                }),
            })
        }
    )

    const [{ isPending }] = usePayPalScriptReducer()

    if (isPending || loadingBooking)
        return (
            <div className="flex h-[45px] w-full items-center justify-center rounded-[4px] bg-[#ffc439]">
                <Loading color="black" />
            </div>
        )

    if (mode === BOOKING_BOX_TYPE.BOOKING && !booking && !isPending && !loadingBooking)
        return <Fragment>Error! No booking</Fragment>
    if (mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING && !openBooking) return <Fragment>Error! No open booking</Fragment>

    const codeListRef = useRef(coupons.map((coupon) => coupon.code))

    useEffect(() => {
        codeListRef.current = coupons.map((coupon) => coupon.code)
    }, [coupons])

    const paypalHostedFieldsProvider = useMemo(
        () => (
            <PayPalButtons
                fundingSource="paypal"
                style={{ color: 'gold', height: 45 }}
                createOrder={function () {
                    debugLog('paypal create order started')

                    if (mode === BOOKING_BOX_TYPE.BOOKING) {
                        return fetchWithLanguage(
                            `${api.endpoints.backend.checkout.paypalCreateOrder.replace(':embedId', config.embed_id).replace(':code', booking!.code)}`,
                            {
                                language,
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    discount_codes: codeListRef.current,
                                }),
                            }
                        )
                            .then((response) => response.json())
                            .then((data) => {
                                const paypalOrderId = data.order_id
                                debugLog('[PayalGoldButton][createOrder] data', data)
                                // setPaypalOrderId(paypalOrderId)
                                // this function must return a Promise returning the paypal order ID (a string)
                                return paypalOrderId
                            })
                    } else {
                        return fetchWithLanguage(
                            `${api.endpoints.backend.checkout.openBookingPaypalCreateOrder.replace(':code', openBooking.code)}`,
                            {
                                language,
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            }
                        )
                            .then((response) => response.json())
                            .then((data) => {
                                const paypalOrderId = data.order_id
                                debugLog('[PayalGoldButton][openBookingCreateOrder] data', data)
                                // setPaypalOrderId(paypalOrderId)
                                // this function must return a Promise returning the paypal order ID (a string)
                                return paypalOrderId
                            })
                    }
                }}
                onApprove={async (data) => {
                    if (mode === BOOKING_BOX_TYPE.BOOKING) {
                        return confirmPaypalOrder({
                            orderId: data.orderID,
                        }).then((res) => {
                            if (res!.status === 200) {
                                setPaymentSuccess(true)
                                setCompletedBookingCode(booking.code)
                                debugLog('success!! paypal payment completed')
                            } else if (res!.status === 404) {
                                setPaymentError({
                                    status: true,
                                    message: t('paymentError'),
                                })
                                debugLog('paypal confirm payment error, 404')
                            } else {
                                setPaymentError({
                                    status: true,
                                    message: t('paymentError'),
                                })
                                debugLog('paypal confirm payment error')
                            }
                        })
                    } else {
                        // mode === BOOKING_BOX_TYPE.OPEN_BOOKING
                        return confirmPaypalOpenBookingOrder({
                            orderId: data.orderID,
                        }).then((res) => {
                            if (res!.status === 200) {
                                setPaymentSuccess(true)
                                setCompletedBookingCode(openBooking.code)
                                debugLog('success!! paypal payment completed')
                            } else if (res!.status === 404) {
                                setPaymentError({
                                    status: true,
                                    message: t('paymentError'),
                                })
                                debugLog('paypal confirm payment error, 404')
                            } else {
                                setPaymentError({
                                    status: true,
                                    message: t('paymentError'),
                                })
                                debugLog('paypal confirm payment error')
                            }
                        })
                    }
                }}
            />
        ),
        [config, booking, coupons, openBooking]
    )

    return (
        <Fragment>
            <div className="h-[45px] w-full rounded-[4px] bg-[#ffc439]">{paypalHostedFieldsProvider}</div>

            {(loadingConfirmPaypalOrder || loadingConfirmPaypalOpenBookingOrder) && (
                <div className="fixed inset-0 z-[100000] flex h-full w-full items-center justify-center bg-black bg-opacity-20">
                    <LoadingDots color="black" />
                </div>
            )}

            {(errorPaypalConfirmOrder || paymentError?.status || errorPaypalConfirmOpenBookingOrder) && (
                <div className="mt-2 text-sm text-red-600">
                    {paymentError?.message ?? t('paymentError')}. {t('tryAgain')}
                </div>
            )}
        </Fragment>
    )
}

export { PaypalGoldButton }
