// CustomToggle.tsx
import React from 'react'

interface CustomToggleProps {
    /** Current state of the toggle */
    checked: boolean
    /** Callback function when toggle state changes */
    onChange: () => void
    /** Disables the toggle when true */
    disabled?: boolean
    /** Reduces the size of the toggle when true */
    smaller?: boolean
    /** Optional className for additional styling */
    className?: string
}

const CustomToggle: React.FC<CustomToggleProps> = ({ checked, onChange, disabled = false, className = '' }) => {
    const handleClick = (): void => {
        if (!disabled) {
            onChange()
        }
    }

    return (
        <div className={`relative inline-block ${className}`}>
            <button
                type="button"
                role="switch"
                aria-checked={checked}
                aria-disabled={disabled}
                tabIndex={disabled ? -1 : 0}
                disabled={disabled}
                onClick={handleClick}
                className={`
                    h-4 w-6
                    rounded-full
                    border-2
                    border-transparent
                    outline-none
                    transition-colors
                    duration-200
                    focus-visible:ring-2
                    focus-visible:ring-blue-500
                    focus-visible:ring-offset-2
                    disabled:cursor-not-allowed
                    disabled:opacity-50
                    ${checked ? 'bg-gray-800' : 'border-gray-200 bg-gray-300'}
                `}
            >
                <span
                    className={`
                        block
                        h-3 w-3
                        transform
                        rounded-full
                        bg-white
                        shadow-lg
                        transition-transform
                        duration-200
                        ${checked ? 'translate-x-2' : 'translate-x-0'}
                    `}
                />
            </button>
        </div>
    )
}

export default CustomToggle
