import clsx from 'clsx'
import { Fragment, useEffect, useRef } from 'react'
import { CheckoutStepId, useCOContext } from '../../Context'
import { BOOKING_BOX_TYPE } from '../../../types'
import { useBBContext } from '../../../BookingBox/Context'

const COStepper = (): JSX.Element => {
    const { mode } = useBBContext()

    return mode === BOOKING_BOX_TYPE.BOOKING ? <BookingCOStepper /> : <OpenBookingCOStepper />
}

const BookingCOStepper = (): JSX.Element => {
    const arrRefLabels = useRef<HTMLDivElement[]>([])
    const arrRefMarkers = useRef<HTMLDivElement[]>([])
    const refCompletedStepsBar = useRef<HTMLDivElement>(null)

    const { step, checkoutSteps } = useCOContext()

    const { mode } = useBBContext()

    useEffect(() => {
        const l = arrRefLabels.current.length
        // arrRefLabels.current[0].style.left = '0%'
        // arrRefLabels.current[1].style.left = '50%'
        // arrRefLabels.current[l - 1].style.right = '0%'
        // arrRefLabels.current.forEach((val, index) => {
        //     if (index > 0 && index < l - 1) {
        //         val.style.left = (index * 100) / (l - 1) + '%'
        //         val.style.transform = 'translateX(-100%)'
        //     }
        // })
        if (refCompletedStepsBar.current) {
            refCompletedStepsBar.current.style.width = `${step.id === CheckoutStepId.BOOKING ? '0' : step.id === CheckoutStepId.YOUR_DATA ? '50' : '100'}%`
        }

        arrRefMarkers.current[0].style.left = '0%'
        arrRefMarkers.current[l - 1].style.right = '0%'
        arrRefMarkers.current.forEach((val, index) => {
            if (index > 0 && index < l - 1) {
                val.style.left = (index * 100) / (l - 1) + '%'
                val.style.transform = 'translateX(-90%) translateY(-50%)'
            }
        })
    }, [step])

    return (
        <Fragment>
            <div className="relative h-[2.5px] w-full !bg-[#DDDEFF] text-[14.5px] font-medium text-zinc-500">
                <div ref={refCompletedStepsBar} className="absolute left-0 h-[100%] !bg-emerald-600"></div>
                {Object.values(checkoutSteps).map((possibleStep, index) =>
                    mode === BOOKING_BOX_TYPE.BOOKING && possibleStep.id === CheckoutStepId.RECIPIENT_DATA ? (
                        ''
                    ) : (
                        <div key={index} className="relative">
                            <div
                                ref={(e) => {
                                    if (e) {
                                        const i = arrRefLabels.current.findIndex((el) => el.innerText === e.innerText)
                                        if (i < 0) {
                                            arrRefLabels.current.push(e)
                                        } else {
                                            arrRefLabels.current.splice(i, 1, e)
                                        }
                                    }
                                }}
                                onClick={possibleStep.onClick ?? undefined}
                                className={clsx(
                                    'absolute bottom-[16px] text-[13.5px]',
                                    possibleStep.onClick && 'cursor-pointer',
                                    index === 0 ? 'left-0' : index === 1 ? 'left-1/2 -translate-x-[40%]' : 'right-0'
                                )}
                            >
                                {possibleStep.name}
                            </div>
                            {/* Barra sulla destra (da mostrare solo per i primi due step) */}
                            <div
                                ref={(e) => {
                                    if (e) {
                                        arrRefMarkers.current.push(e)
                                    }
                                }}
                                onClick={possibleStep.onClick ?? undefined}
                                className={`${
                                    possibleStep.onClick && 'cursor-pointer'
                                } absolute top-1/2 flex h-[23px] w-[23px] -translate-y-1/2 items-center justify-center rounded-full border-[2px]  ${
                                    step.id - 1 < index ? 'border-[#DDDEFF]' : '!border-emerald-600'
                                } ${step.id - 1 > index ? '!bg-emerald-600' : 'bg-white'}   `}
                            >
                                {step.id - 1 === index && (
                                    <div className="h-[15px] w-[15px] rounded-full bg-green-100"></div>
                                )}
                                {step.id - 1 > index && (
                                    <svg
                                        width="12"
                                        height="9"
                                        viewBox="0 0 12 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.59961 4.55L4.89961 7.85L10.3996 1.25"
                                            style={{ fill: 'none' }}
                                            stroke="white"
                                            strokeWidth="1.7"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                            </div>
                        </div>
                    )
                )}
            </div>
        </Fragment>
    )
}
export const OpenBookingCOStepper = (): JSX.Element => {
    const arrRefLabels = useRef<HTMLDivElement[]>([])
    const arrRefMarkers = useRef<HTMLDivElement[]>([])
    const refCompletedStepsBar = useRef<HTMLDivElement>(null)

    const { step, checkoutSteps } = useCOContext()

    useEffect(() => {
        const l = arrRefLabels.current.length
        if (refCompletedStepsBar.current) {
            switch (step.id) {
                case CheckoutStepId.RECIPIENT_DATA:
                    refCompletedStepsBar.current.style.width = '33%'
                    break
                case CheckoutStepId.YOUR_DATA:
                    refCompletedStepsBar.current.style.width = '66%'
                    break
                case CheckoutStepId.PAYMENT:
                    refCompletedStepsBar.current.style.width = '100%'
                    break
                default:
                    refCompletedStepsBar.current.style.width = '0%'
                    break
            }
        }
        arrRefMarkers.current[0].style.left = '0%'
        arrRefMarkers.current[l - 1].style.right = '0%'
        arrRefMarkers.current.forEach((val, index) => {
            if (index > 0 && index < l - 1) {
                val.style.left = (index * 100) / (l - 1) + '%'
                val.style.transform = 'translateX(-90%) translateY(-50%)'
            }
        })
    }, [step])

    return (
        <Fragment>
            <div className="relative h-[2.5px] w-full !bg-[#DDDEFF] text-[14.5px] font-medium text-zinc-500">
                <div ref={refCompletedStepsBar} className="absolute left-0 h-[100%] !bg-emerald-600"></div>
                {Object.values(checkoutSteps)
                    .sort((a, b) => a.id - b.id)
                    .map((possibleStep, index) => (
                        <div key={index} className="relative">
                            <div
                                ref={(e) => {
                                    if (e) {
                                        const i = arrRefLabels.current.findIndex((el) => el.innerText === e.innerText)
                                        if (i < 0) {
                                            arrRefLabels.current.push(e)
                                        } else {
                                            arrRefLabels.current.splice(i, 1, e)
                                        }
                                    }
                                }}
                                onClick={possibleStep.onClick ?? undefined}
                                className={clsx(
                                    'absolute bottom-[16px] text-[13.5px]',
                                    possibleStep.onClick && 'cursor-pointer',
                                    {
                                        'left-0': index === 0,
                                        'left-[33%] -translate-x-[20%]': index === 1,
                                        'left-[66%] -translate-x-1/2': index === 2,
                                        'right-0': index === 3,
                                    }
                                )}
                            >
                                {possibleStep.name}
                            </div>
                            {/* Barra sulla destra (da mostrare solo per i primi tre step) */}
                            <div
                                ref={(e) => {
                                    if (e) {
                                        arrRefMarkers.current.push(e)
                                    }
                                }}
                                onClick={possibleStep.onClick ?? undefined}
                                className={`${
                                    possibleStep.onClick && 'cursor-pointer'
                                } absolute top-1/2 flex h-[23px] w-[23px] -translate-y-1/2 items-center justify-center rounded-full border-[2px]  ${
                                    step.id < possibleStep.id ? 'border-[#DDDEFF]' : '!border-emerald-600'
                                } ${step.id > possibleStep.id ? '!bg-emerald-600' : 'bg-white'}   `}
                            >
                                {possibleStep.id === step.id && (
                                    <div className="h-[15px] w-[15px] rounded-full bg-green-100"></div>
                                )}
                                {possibleStep.id < step.id && (
                                    <svg
                                        width="12"
                                        height="9"
                                        viewBox="0 0 12 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.59961 4.55L4.89961 7.85L10.3996 1.25"
                                            style={{ fill: 'none' }}
                                            stroke="white"
                                            strokeWidth="1.7"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </Fragment>
    )
}

export { COStepper }
