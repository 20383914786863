export default function CategoryAddonTagliere({
    className = 'w-[14px] h-[14px] screen600:w-[10px] screen600:h-[14px]',
}: {
    className?: string
}) {
    return (
        <svg
            className={className}
            fill="none"
            height="64px"
            width="64px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 477.169 477.169"
            transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"
        >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
                {' '}
                <path d="M334.03,0H143.139c-21.618,0-39.206,17.587-39.206,39.206v251.448c0,21.618,17.588,39.206,39.206,39.206h62.645 l-7.269,107.827c-0.566,9.874,2.974,19.389,9.966,26.793c7.615,8.064,18.588,12.689,30.104,12.689s22.489-4.625,30.104-12.689 c6.992-7.404,10.532-16.919,9.961-26.868l-7.264-107.752h62.645c21.618,0,39.206-17.587,39.206-39.206V39.206 C373.236,17.587,355.648,0,334.03,0z M358.236,290.654c0,13.347-10.859,24.206-24.206,24.206h-70.667 c-2.077,0-4.061,0.861-5.479,2.378c-1.418,1.517-2.144,3.554-2.004,5.626l7.799,115.681c0.327,5.71-1.767,11.263-5.896,15.636 c-4.794,5.077-11.792,7.988-19.198,7.988s-14.404-2.912-19.198-7.988c-4.129-4.373-6.223-9.926-5.901-15.561l7.804-115.756 c0.14-2.072-0.586-4.109-2.004-5.626c-1.418-1.517-3.402-2.378-5.479-2.378h-70.667c-13.347,0-24.206-10.858-24.206-24.206V39.206 c0-13.347,10.859-24.206,24.206-24.206H334.03c13.347,0,24.206,10.858,24.206,24.206V290.654z M246.084,418.004v18.779 c0,4.142-3.358,7.5-7.5,7.5s-7.5-3.358-7.5-7.5v-18.779c0-4.142,3.358-7.5,7.5-7.5S246.084,413.862,246.084,418.004z M320.079,287.4 c0,4.142-3.358,7.5-7.5,7.5h-21.967c-4.142,0-7.5-3.358-7.5-7.5s3.358-7.5,7.5-7.5h21.967 C316.721,279.9,320.079,283.258,320.079,287.4z M262.528,287.4c0,4.142-3.358,7.5-7.5,7.5H164.59c-4.142,0-7.5-3.358-7.5-7.5 s3.358-7.5,7.5-7.5h90.438C259.17,279.9,262.528,283.258,262.528,287.4z"></path>{' '}
            </g>
        </svg>
    )
}
