import { ArrowUpIcon, CheckIcon, MinusIcon, PlusIcon } from '@radix-ui/react-icons'
import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import Button, { ButtonVariant } from 'src/lib/components/Button'
import { ChevronDownIcon, ChevronRight } from 'src/lib/icons/icons'
import { getTotalParticipants, parseHtmlStrict } from 'src/lib/utils'
import { BookingStep, BookingSteps, useBBContext } from './Context'
import { useTranslation } from 'react-i18next'

const BBAddons = () => {
    const { setStep, selectedAddons, setSelectedAddons, selectedParticipants, config } = useBBContext()

    const [expandedAddonsId, setExpandedAddonsId] = useState<string[]>([])

    // it can't be greater than the number of selected participants
    const maxQuantity = getTotalParticipants(selectedParticipants!)

    const handler = useSwipeable({
        onSwipedRight: () => {
            setStep(BookingSteps[BookingStep.Slot])
        },
    })
    
    const { t } = useTranslation();


    // debugLog('[BBAddons] selectedSlot', selectedSlot)
    // debugLog('[BBAddons] selectedAddons', selectedAddons)

    return (
        <Fragment>
            <div className="flex flex-col justify-between pb-1.5 screen775:!h-full screen775:!pb-[85px]" {...handler}>
                <div className="flex h-full flex-col justify-start gap-2">
                    <div className="mt-1 pl-1 text-[15px] font-medium text-zinc-700">{t("optional")}:</div>
                    {selectedAddons &&
                        selectedAddons.map((a, index) => (
                            <div
                                key={index}
                                className={`flex select-none items-center justify-between rounded-[4px] border border-zinc-300 px-3.5 py-3 text-[15px] screen600:py-3.5
                                            ${expandedAddonsId.indexOf(a.id!.toString()) >= 0 && '!border-zinc-900 '}`}
                            >
                                {/* NOT EXPANDED */}
                                <div className="flex w-full flex-col">
                                    <div className="flex w-full flex-row place-content-between">
                                        {!a.is_mandatory_for_all && (
                                            <Fragment>
                                                <div className="flex items-center">
                                                    <div className="flex flex-row gap-3 pl-1 ">
                                                        <div className="gap-0.25 flex flex-col">
                                                            <div className="max-w-[151px] truncate text-[14.5px] leading-5">
                                                                {a.name}
                                                            </div>
                                                            <div className="max-w-[175px] truncate text-[12.5px] leading-[16px] text-gray-500">
                                                                +
                                                                {config.is_holidoit_network_operator
                                                                    ? a.holidoit_price
                                                                    : a.partner_price}{' '}
                                                                €
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {a.description !== '' && (
                                                        <button
                                                            className="ml-1.5 flex cursor-pointer items-center justify-center rounded-full p-2 hover:!bg-gray-100"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                const i = expandedAddonsId.indexOf(a.id!.toString())

                                                                if (i >= 0) {
                                                                    const aux = [...expandedAddonsId]
                                                                    aux.splice(i, 1)
                                                                    setExpandedAddonsId([...aux])
                                                                } else {
                                                                    setExpandedAddonsId([
                                                                        ...expandedAddonsId,
                                                                        a.id!.toString(),
                                                                    ])
                                                                }
                                                            }}
                                                        >
                                                            <ChevronDownIcon
                                                                className={` transition-all duration-100 ${
                                                                    expandedAddonsId.indexOf(a.id!.toString()) >= 0
                                                                        ? 'rotate-180'
                                                                        : ''
                                                                }  h-[16px] w-[16px]`}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                                {/* QUANTITY PICKER */}
                                                <div className="flex items-center gap-[14.5px] leading-[22px]">
                                                    <button
                                                        className="cursor-pointer rounded-[4px] border border-zinc-100 p-1.5 text-zinc-800 transition-all duration-100 ease-in-out hover:!border-zinc-200 hover:!bg-zinc-100/70 hover:!text-black disabled:cursor-not-allowed disabled:!text-zinc-400 disabled:opacity-40"
                                                        disabled={a.qt === 0 ? true : false}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            const i = selectedAddons?.findIndex((el) => el.id === a.id)
                                                            if (i! >= 0) {
                                                                const aux = [...selectedAddons!]
                                                                aux[i!].qt = aux[i!].qt! - 1
                                                                setSelectedAddons([...aux])
                                                            }
                                                        }}
                                                    >
                                                        <MinusIcon className="h-[18px] w-[18px]" />
                                                    </button>
                                                    <span className="relative w-[20px] text-center">{a.qt}</span>
                                                    <button
                                                        disabled={
                                                            selectedAddons
                                                                .filter((addon) => addon.category_id === a.category_id)
                                                                ?.map((addon) => addon.qt ?? 0)
                                                                .reduce((acc, curr) => (acc ?? 0) + curr!)! >=
                                                            maxQuantity
                                                                ? true
                                                                : false
                                                        }
                                                        className="cursor-pointer rounded-[4px] border border-zinc-100 p-1.5 text-zinc-800 transition-all duration-100 ease-in-out hover:!border-zinc-200 hover:!bg-zinc-100/70 hover:!text-black disabled:cursor-not-allowed disabled:!text-zinc-400 disabled:opacity-40"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            const i = selectedAddons?.findIndex((el) => el.id === a.id)
                                                            if (i! >= 0) {
                                                                const aux = [...selectedAddons!]
                                                                aux[i!].qt = aux[i!].qt! + 1
                                                                setSelectedAddons([...aux])
                                                            }
                                                        }}
                                                    >
                                                        <PlusIcon className="h-[18px] w-[18px]" />
                                                    </button>
                                                </div>
                                            </Fragment>
                                        )}
                                        {/* CASE ADDONS ARE MANDATORY FOR ALL PARTICIPANTS */}
                                        {a.is_mandatory_for_all && (
                                            <Fragment>
                                                <div className="flex w-full place-content-between items-center">
                                                    <div className="flex gap-1">
                                                        <div className="flex flex-row gap-3 pl-1 ">
                                                            <div className="gap-0.25 flex flex-col">
                                                                <div className="max-w-[175px] truncate text-[14.5px] leading-5">
                                                                    {a.name}
                                                                </div>
                                                                <div className="max-w-[175px] truncate text-[12.5px] leading-[16px] text-gray-500">
                                                                    +
                                                                    {config.is_holidoit_network_operator
                                                                        ? a.holidoit_price
                                                                        : a.partner_price}{' '}
                                                                    €
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button
                                                                className="cursor-pointer rounded-full p-2 hover:bg-gray-100"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    const i = expandedAddonsId.indexOf(a.id!.toString())

                                                                    if (i >= 0) {
                                                                        const aux = [...expandedAddonsId]
                                                                        aux.splice(i, 1)
                                                                        setExpandedAddonsId([...aux])
                                                                    } else {
                                                                        setExpandedAddonsId([
                                                                            ...expandedAddonsId,
                                                                            a.id!.toString(),
                                                                        ])
                                                                    }
                                                                }}
                                                            >
                                                                <ArrowUpIcon
                                                                    className={` ${
                                                                        expandedAddonsId.indexOf(a.id!.toString()) >= 0
                                                                            ? ''
                                                                            : 'rotate-180'
                                                                    }  h-[16px] w-[16px]`}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {/* Checkbox - Instead of BaseCheckbox (causing problems in mobile view) */}
                                                    <div className="mr-2.5 flex">
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                const i = selectedAddons?.findIndex(
                                                                    (el) => el.id === a.id
                                                                )
                                                                if (selectedAddons![i!].qt === 0) {
                                                                    const aux = [...selectedAddons!]
                                                                    aux[i!].qt = maxQuantity
                                                                    setSelectedAddons([...aux])
                                                                } else {
                                                                    const aux = [...selectedAddons!]
                                                                    aux[i!].qt = 0
                                                                    setSelectedAddons([...aux])
                                                                }
                                                            }}
                                                            className={clsx(
                                                                'flex h-5 w-5 cursor-pointer items-center rounded border border-gray-300 text-white',
                                                                selectedAddons![
                                                                    selectedAddons?.findIndex((el) => el.id === a.id)!
                                                                ].qt === 0
                                                                    ? ''
                                                                    : 'bg-black'
                                                            )}
                                                        >
                                                            {selectedAddons![
                                                                selectedAddons?.findIndex((el) => el.id === a.id)!
                                                            ].qt === 0 ? (
                                                                <Fragment></Fragment>
                                                            ) : (
                                                                <CheckIcon className="h-5 w-5" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* EXPANDED */}
                                    <div>
                                        {expandedAddonsId.indexOf(a.id!.toString()) >= 0 && (
                                            <div className="mb-[1px] mr-0.5 mt-[7px] max-h-[150px] overflow-y-scroll pl-1 pr-1.5 text-[14px] leading-[20px]">
                                                {a.is_mandatory_for_all && (
                                                    <Fragment>
                                                        <div className="mt-1.5">
                                                            {t("onlyForAllParticipant")}
                                                        </div>
                                                    </Fragment>
                                                )}

                                                {a.description != '' && (
                                                    <Fragment>
                                                        {/* <div className="mt-[15px] font-medium">{a.name}</div> */}
                                                        <div className="mt-1.5">
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: parseHtmlStrict(a.description || ''),
                                                                }}
                                                            />
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                <div className="absolute bottom-0 left-0 w-full items-center justify-center border-t-[1.5px] border-zinc-300 bg-white px-6 py-5 shadow-top screen775:!px-5 screen775:!py-4">
                    {/* BUTTON TO CONFIRMED DISABLED WHEN THE #PARTICIPANTS IS SET TO ZERO */}
                    {selectedAddons && selectedAddons.length > 0 && (
                        <Button
                            variant={ButtonVariant.BLACK}
                            // variant={ButtonVariant.BLACK}
                            onClick={(e) => {
                                e.preventDefault()
                                const addonsToSend = selectedAddons?.filter((el) => el.qt !== 0)
                                setSelectedAddons(addonsToSend!)
                                setStep(BookingSteps[BookingStep.Recap])
                            }}
                            className="flex w-full items-center justify-center"
                        >
                            <span>
                                {selectedAddons?.map((addon) => addon.qt ?? 0)?.length > 0 &&
                                selectedAddons
                                    ?.map((addon) => addon.qt ?? 0)
                                    .reduce((acc, curr) => (acc ?? 0) + curr!)! === 0
                                    ? t("skip")
                                    : t("continue")}
                            </span>
                            <ChevronRight className="h-[18px] w-[18px]" />
                        </Button>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
export { BBAddons }
