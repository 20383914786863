import { Fragment, useEffect } from 'react'
import { useExperiencePickerContext } from '../Context'
import { ExperiencePickerCard } from './Card'
import { debugLog } from '../../lib/utils'
import { ExperiencePickerStep } from '../../types'

interface ExperiencePickerProps extends React.ComponentPropsWithoutRef<'div'> {
    setShow: (show: boolean) => void
}

const ExperienceList = ({ setShow }: ExperiencePickerProps): JSX.Element => {
    const { experiences, setExperience, setStep, handleGoToNextStep } = useExperiencePickerContext()
    debugLog('ExperienceList', { experiences })

    return (
        <Fragment>
            <div className="mt-6 flex flex-col gap-3.5 px-6 pb-8 screen775:!px-[17px]">
                {experiences?.map((exp) => {
                    return (
                        <ExperiencePickerCard
                            key={exp.id}
                            experience={exp}
                            onClick={(e) => {
                                e.preventDefault()
                                setExperience(exp)
                                if (exp.show_embed_product_page) {
                                    setStep(ExperiencePickerStep.INFO)
                                } else {
                                    handleGoToNextStep()
                                }
                            }}
                            className="flex cursor-pointer flex-col gap-2.5 rounded-[5px] border border-zinc-300 bg-zinc-50 pb-3.5  hover:!shadow-md"
                        />
                    )
                })}
            </div>
        </Fragment>
    )
}

export { ExperienceList }
