export default function CategoryAddonCena({
    className = 'w-[16px] h-[16px] screen600:w-[14px] screen600:h-[14px]',
}: {
    className?: string
}) {
    return (
        <svg
            fill="none"
            width="15"
            height="15"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
        >
            <g>
                <g>
                    <path
                        d="M476.767,423.726h-0.302c-3.815-96.362-70.396-176.996-158.672-202.996c0-0.015,0-0.026,0-0.039
       c0-34.069-27.72-61.793-61.793-61.793s-61.793,27.724-61.793,61.793c0,0.013,0.008,0.024,0.008,0.039
       c-88.994,26-154.865,106.633-158.68,202.996h-0.302C15.806,423.726,0,439.562,0,459.036c0,19.474,15.806,35.31,35.233,35.31
       h441.535c19.427,0,35.233-15.836,35.233-35.31C512,439.562,496.194,423.726,476.767,423.726z M256,176.553
       c22.807,0,41.41,17.455,43.686,39.67c-14.127-2.847-28.733-4.36-43.686-4.36c-14.954,0-29.559,1.513-43.686,4.36
       C214.59,194.008,233.193,176.553,256,176.553z M256,229.519c108.996,0,198.207,86.336,202.845,194.207H97.551
       c4.609-78.634,69.851-141.241,149.622-141.241c4.875,0,8.828-3.948,8.828-8.828s-3.953-8.828-8.828-8.828
       c-89.503,0-162.643,70.528-167.277,158.897h-26.74C57.793,315.855,147.004,229.519,256,229.519z M476.767,476.691H35.233
       c-9.694,0-17.578-7.922-17.578-17.655c0-9.733,7.884-17.655,17.578-17.655h8.905h423.724h8.905
       c9.694,0,17.578,7.922,17.578,17.655C494.345,468.769,486.461,476.691,476.767,476.691z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M144.724,179.139c-0.211-0.216-21.207-21.604-7.724-41.828c20.681-31.026,0.853-61.474,0-62.759
       c-2.707-4.052-8.185-5.147-12.241-2.448c-4.056,2.707-5.151,8.181-2.448,12.241c0.142,0.215,14.224,21.836,0,43.172
       c-17.142,25.707-1,53.172,9.931,64.103c1.724,1.724,3.983,2.586,6.241,2.586c2.258,0,4.517-0.862,6.241-2.586
       C148.172,188.174,148.172,182.588,144.724,179.139z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M268.31,126.174c-0.211-0.216-21.207-21.604-7.724-41.828c20.681-31.026,0.853-61.474,0-62.759
       c-2.711-4.06-8.19-5.155-12.241-2.448c-4.056,2.707-5.151,8.181-2.448,12.241c0.142,0.215,14.224,21.836,0,43.172
       c-17.142,25.707-1,53.172,9.931,64.103c1.724,1.724,3.983,2.586,6.241,2.586s4.517-0.862,6.241-2.586
       C271.759,135.208,271.759,129.622,268.31,126.174z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M391.896,196.795c-0.211-0.216-21.207-21.604-7.724-41.828c20.681-31.026,0.853-61.474,0-62.759
       c-2.711-4.06-8.194-5.155-12.241-2.448c-4.056,2.707-5.151,8.181-2.448,12.241c0.142,0.215,14.224,21.836,0,43.172
       c-17.142,25.707-1,53.172,9.931,64.103c1.724,1.724,3.983,2.586,6.241,2.586c2.259,0,4.517-0.862,6.241-2.586
       C395.345,205.829,395.345,200.243,391.896,196.795z"
                    />
                </g>
            </g>
        </svg>
    )
}
