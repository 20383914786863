import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CountdownTimerIcon } from 'src/lib/icons/icons'

// Define types for your props as needed
type BookingProps = {
    expirationTime: string // ISO string for when the booking expires
    handleExpiration: () => void // Function to call when the timer expires
}

const BookingTimer: React.FC<BookingProps> = ({ expirationTime, handleExpiration }) => {
    // Calculate the initial remaining time in seconds
    const getRemainingTime = () => {
        const now = new Date()
        const expirationDate = new Date(expirationTime)
        const timeLeft = (expirationDate.getTime() - now.getTime()) / 1000
        return Math.max(0, timeLeft)
    }

    const { t } = useTranslation()

    // State to track the remaining time in seconds
    const [remainingTime, setRemainingTime] = useState(getRemainingTime())

    useEffect(() => {
        // Set up a timer that runs every second
        const timerId = setInterval(() => {
            const timeLeft = getRemainingTime()
            setRemainingTime(timeLeft)
            if (timeLeft <= 0) {
                clearInterval(timerId)
                handleExpiration()
            }
        }, 1000)

        // Cleanup function to clear the interval when component unmounts
        return () => clearInterval(timerId)
    }, [expirationTime, handleExpiration]) // Dependencies

    // Format the remaining time as minutes and seconds for display
    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = Math.floor(seconds % 60)
        return `${minutes}m ${remainingSeconds}s`
    }

    return (
        <div className="py-0.5 text-sm text-zinc-400">
            {remainingTime > 0 ? (
                <div className="flex w-full items-center justify-between">
                    <div>{t("weAreKeepingAvailability")}</div>
                    <div className="flex items-center gap-1.5">
                        {formatTime(remainingTime)}
                        <CountdownTimerIcon className="h-4 w-4" />
                    </div>
                </div>
            ) : (
                <div>{t("bookingTimeExpired")}</div>
            )}
        </div>
    )
}

export default BookingTimer
