import { IRecipientInfo } from '../../../types'
import { Fragment, useState } from 'react'
import clsx from 'clsx'
import Button, { ButtonVariant } from '../../../lib/components/Button'
import { ChevronRight } from '../../../lib/icons/icons'
import LoadingDots from '../../../lib/components/LoadingDots'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckoutStepId, useCOContext } from '../../Context'
import { api } from '../../../api'

export const checkoutInputClassname = `focus:outline-none focus:!border-zinc-900 bg-zinc-50 h-[44px] border border-zinc-300 placeholder-zinc-500 text-sm px-3.5 bg-white w-full rounded cursor-text`

export const CORecipientInfo = () => {
    const { t } = useTranslation()
    const { recipientInfo, setRecipientInfo, openBooking, setStep, checkoutSteps } = useCOContext()

    const [submitClicked, setSubmitClicked] = useState<boolean>(false)

    const form = useForm<IRecipientInfo>({
        defaultValues: recipientInfo || {
            name: '',
            message: '',
            sender: '',
            email: '',
        },
    })
    const { register, handleSubmit, formState, getValues, setValue } = form
    const { errors } = formState

    const onInformationEntered = async (data: IRecipientInfo) => {
        setRecipientInfo(data)
        const body = {
            name: data.name,
            email: data.email,
            sender: data.sender,
            message: data.message,
            sendEmail: data.sendEmail,
        }

        const response = await fetch(
            openBooking &&
                `${api.endpoints.backend.checkout.openBookingRecipient.replace(':code', openBooking.code)}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PUT',
                body: JSON.stringify(body),
            }
        )
        const responseStatus = response.status
        if (responseStatus == 204) {
            setStep(checkoutSteps[CheckoutStepId.YOUR_DATA])
        }
    }

    const handleFieldChange =
        (field: keyof IRecipientInfo) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const updatedInfo = {
                ...getValues(),
                [field]: e.target.value,
            }
            setValue(field, e.target.value)
            setRecipientInfo(updatedInfo)
        }

    const onSubmit = (data: IRecipientInfo) => {
        setSubmitClicked(true)
        onInformationEntered(data)
        setSubmitClicked(false)
    }

    return (
        <Fragment>
            <div className="">
                <form onSubmit={handleSubmit(onSubmit)} noValidate>

                    <div className="flex flex-col gap-4">
                        {/* To */}
                        <div className="relative flex flex-col gap-0.5">
                            <input
                                className={clsx(`${checkoutInputClassname}`, {
                                    'validation-error': errors.name,
                                })}
                                type="text"
                                id="name"
                                placeholder={t('recipientPlaceholder')}
                                {...register('name', {
                                    required: {
                                        value: true,
                                        message: 'Il destinatario è richiesto',
                                    },
                                    onChange: handleFieldChange('name'),
                                })}
                                maxLength={50}
                            />
                            {form.getValues('name').length === 0 && (
                                <span className="absolute left-[87px] top-3 font-medium text-red-700 screen600:left-[84px] cursor-pointer pointer-events-none">
                                    *
                                </span>
                            )}
                        </div>

                        <div className="flex flex-col gap-0.5">
                            <textarea
                                className={`${checkoutInputClassname} text-input !h-24 cursor-text border-gray-300 bg-white py-1.5`}
                                id="message"
                                placeholder={t('messagePlaceholder')}
                                {...register('message', {
                                    onChange: handleFieldChange('message'),
                                })}
                                maxLength={400}
                            />
                            {/* {openBooking?.experience_name && (
                                    <div className="gap-4 p-4 mt-2 bg-white rounded-lg">
                                        <GiftAIMessageGenerator
                                            experienceName={openBooking.experience_name}
                                            onConfirmAIMessage={(message) => {
                                                form.setValue('message', message);
                                            }}
                                        />
                                    </div>
                                )} */}
                        </div>

                        <div className="flex flex-col gap-0.5">
                            {/* <h5 className="hidden screen900:block font-medium text-[14.5px]">Da</h5> */}

                            {/* Sender */}
                            <input
                                className={`${checkoutInputClassname}`}
                                type="text"
                                id="sender"
                                placeholder={t('senderPlaceholder')}
                                {...register('sender', {
                                    onChange: handleFieldChange('sender'),
                                })}
                                maxLength={50}
                            />
                        </div>
                    </div>

                    <div className="absolute bottom-0 left-0 z-50 flex w-full items-center justify-center gap-3 border-t-[1.5px] border-zinc-300 bg-white px-6 py-[18px] shadow-top screen775:!px-5 screen775:!py-4">
                        <div className="flex w-36 flex-col gap-[2px] text-[16.5px] font-medium uppercase text-zinc-800 screen775:!text-[16px]">
                            <span className="text-[13.5px] uppercase text-zinc-500/95">{t('total')}</span>
                            <span>{Number(openBooking?.original_value).toFixed(2)}€</span>
                        </div>

                        <Button
                            variant={ButtonVariant.BLACK}
                            // type="submit"
                            className="flex w-full items-center justify-center gap-1.5 transition-all duration-150 hover:gap-2 disabled:cursor-not-allowed"
                        >
                            {!submitClicked ? (
                                <>
                                    <span>{t('continue')}</span>
                                    <ChevronRight className="h-[20px] w-[20px]" />
                                </>
                            ) : (
                                <LoadingDots color="white" />
                            )}
                        </Button>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}
