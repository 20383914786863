import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../api'
import {
    DISCOUNT_ERROR_CODE,
    GiftCardValidationError,
    GiftCardValidationErrorResponse,
    GiftCardValidationSuccessResponse,
} from '../../../types'
import { useCOContext } from '../../Context'
import { useTranslation } from 'react-i18next'
import { useExperiencePickerContext } from '../../../ExperiencePicker/Context'
import { GiftIcon } from '@heroicons/react/16/solid'

let renderCount = 0

type IGiftCardForm = {
    code: string
}

function isGiftCardValidationErrorResponse(object: any): object is GiftCardValidationErrorResponse {
    return (
        object &&
        Array.isArray(object.errors) &&
        object.errors.every(
            // @ts-ignore
            (result) => 'discount_code' in result && 'discount_error_code' in result && 'detail' in result
        )
    )
}

function isGiftCardValidationSuccessResponse(object: any): object is GiftCardValidationSuccessResponse {
    return (
        object &&
        Array.isArray(object.results) &&
        object.results.every(
            // @ts-ignore
            (result) => 'code' in result && 'discount_type' in result && 'value' in result
        )
    )
}

export const couponValidation = async (bookingCode: string, codes: string[], hasDeposit: boolean) => {
    // const url = `${API_ENDPOINTS_V1.FRONTEND_GIFT_CARD_VALIDATION.replace(':id', booking_code).replace(
    //     ':couponCodes',
    //     JSON.stringify(codes)
    // )}${withDeposit ? '?with_deposit=true' : ''}`;
    const url = api.endpoints.backend.checkout.bookingDiscountValidation.replace(':code', bookingCode)
    const searchParams = new URLSearchParams()
    codes.forEach((code) => searchParams.append('coupon_codes', code))
    searchParams.append('with_deposit', hasDeposit.toString())
    const urlWithCodes = `${url}?${searchParams.toString()}`

    const validatedCoupon = await fetch(urlWithCodes, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
    }).then((res) => {
        if (res.status === 400) {
            return res.json()
        } else {
            return res.json()
        }
    })
    return validatedCoupon as GiftCardValidationSuccessResponse | GiftCardValidationErrorResponse
}

export const GiftCardInputBox = () => {
    const { booking, setCoupons, coupons } = useCOContext()
    const { experience } = useExperiencePickerContext()
    renderCount++
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [duplicateCoupon, setDuplicateCoupon] = useState<string>('')
    const [invalidCoupon, setInvalidCoupon] = useState<GiftCardValidationError | null>(null)
    const { t } = useTranslation()

    const form = useForm<IGiftCardForm>({
        defaultValues: {
            code: '',
        },
    })

    const { register, handleSubmit, reset } = form

    const onSubmit = async (data: IGiftCardForm) => {
        const trimmedCode = data.code.trim()
        setInvalidCoupon(null)
        setDuplicateCoupon('')
        if (coupons.filter((c) => c.code === trimmedCode).length > 0) {
            setDuplicateCoupon(trimmedCode)
        } else {
            const codes = [...coupons.map((c) => c.code), trimmedCode]
            const validatedCoupon = await couponValidation(
                booking.code,
                codes,
                experience.deposit_percentage !== undefined
            )
            if (validatedCoupon) {
                if (isGiftCardValidationSuccessResponse(validatedCoupon)) {
                    setCoupons((prevCoupons) => {
                        const newCoupons = [...prevCoupons]

                        for (const coupon of validatedCoupon.results) {
                            // Check if the coupon code is already present in the array
                            const isCouponAlreadyExists = newCoupons.some(
                                (prevCoupon) => prevCoupon.code === coupon.code
                            )

                            if (!isCouponAlreadyExists) {
                                newCoupons.push(coupon)
                            }
                        }

                        return newCoupons
                    })
                    setIsOpen(false)
                } else if (isGiftCardValidationErrorResponse(validatedCoupon)) {
                    setInvalidCoupon(validatedCoupon.errors.filter((c) => c.discount_code === trimmedCode)[0])
                }
            }
            reset()
        }
    }

    return (
        <Fragment>
            <div className={`flex w-full flex-col font-normal ${isOpen ? 'mb-5 gap-5' : ''}`}>
                <div className="w-full">
                    {!isOpen && (
                        <div
                            className="flex cursor-pointer items-center gap-1 text-sm text-blue-500 hover:underline"
                            onClick={() => {
                                setIsOpen(!isOpen)
                                setDuplicateCoupon('')
                                setInvalidCoupon(null)
                            }}
                        >
                            <GiftIcon className="h-3" />
                            {t('coupons.insertACoupon')}
                        </div>
                    )}

                    {isOpen && (
                        <div
                            className="inline-block items-center cursor-pointer text-sm text-gray-500 underline hover:text-gray-600"
                            onClick={() => {
                                setIsOpen(!isOpen)
                                setDuplicateCoupon('')
                                setInvalidCoupon(null)
                            }}
                        >
                            {t('cancel')}
                        </div>
                    )}
                </div>
                {isOpen && (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={`flex max-h-6 place-content-between items-center gap-2.5`}>
                                <div className="h-full w-[80%]">
                                    <input
                                        id="code"
                                        className={`xs:border-gray-200 inline-block w-full cursor-text rounded border border-gray-300 bg-white px-3.5 py-3 text-sm outline-none transition-all duration-150 focus:border-gray-900`}
                                        type="text"
                                        placeholder={t('coupons.code')}
                                        {...register('code', {
                                            required: 'Inserisci un codice',
                                        })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault()
                                                handleSubmit(onSubmit)()
                                            }
                                        }}
                                    />
                                </div>
                                <div className="h-full">
                                    <button
                                        className="rounded bg-gray-900 px-3.5 py-3 text-sm font-medium text-white hover:bg-gray-900/80"
                                        type="submit"
                                    >
                                        <span>{t('coupons.applyCode')}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
                {/* Doppio inserimento coupon */}
                {duplicateCoupon != '' && (
                    <>
                        <div className="flex flex-col gap-5 text-sm font-normal">
                            <div className="flex w-full content-between items-center justify-between leading-[18px] screen1024:text-[13px]">
                                <span className="text-red-500">
                                    {t('coupons.errors.theCode')}{' '}
                                    <span className="font-semibold text-red-500">{duplicateCoupon}</span>{' '}
                                    {t('coupons.errors.alreadyInserted')}.
                                </span>
                            </div>
                        </div>
                    </>
                )}
                {/* Coupon non valido */}
                {invalidCoupon && (
                    <>
                        <div className="flex flex-col gap-5 text-sm font-normal">
                            <div className="flex w-full content-between items-center justify-between leading-[18px] screen1024:text-[13px]">
                                <span className="text-red-500">
                                    {invalidCoupon.discount_error_code != DISCOUNT_ERROR_CODE.NOT_LOGGED_USER &&
                                        invalidCoupon.discount_error_code !=
                                            DISCOUNT_ERROR_CODE.DISCOUNT_WITH_DEPOSIT_CANNOT_BE_USED && (
                                            <span>
                                                {t('coupons.errors.theCode')}{' '}
                                                <span className="font-semibold text-red-500">
                                                    {invalidCoupon.discount_code}
                                                </span>{' '}
                                                {t('coupons.errors.isInvalid')}.{' '}
                                            </span>
                                        )}
                                    {invalidCoupon.discount_error_code ==
                                        DISCOUNT_ERROR_CODE.DISCOUNT_WITH_DEPOSIT_CANNOT_BE_USED && (
                                        <span>
                                            {t('coupons.errors.theCode')}{' '}
                                            <span className="font-semibold text-red-500">
                                                {invalidCoupon.discount_code}
                                            </span>{' '}
                                            {t('coupons.errors.cannotBeUsedWithDeposit')}.
                                        </span>
                                    )}
                                    {invalidCoupon.discount_error_code ==
                                        DISCOUNT_ERROR_CODE.DISCOUNT_CODE_WITH_GIFTCARD && (
                                        <span>{t('coupons.errors.withGiftCard')}.</span>
                                    )}
                                    {invalidCoupon.discount_error_code == DISCOUNT_ERROR_CODE.NOT_LOGGED_USER && (
                                        <span>{t('coupons.errors.notLoggedIn')}.</span>
                                    )}
                                    {invalidCoupon.discount_error_code == DISCOUNT_ERROR_CODE.USAGE_LIMIT_REACHED && (
                                        <span>{t('coupons.errors.hasBeenAlreadyUsed')}.</span>
                                    )}
                                    {invalidCoupon.discount_error_code == DISCOUNT_ERROR_CODE.MIN_PRICE_NOT_VALID && (
                                        <span>{t('coupons.errors.minPriceTooLow')}.</span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    )
}
