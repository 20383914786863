import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import { useExperiencePickerContext } from 'src/ExperiencePicker/Context'
import Button, { ButtonVariant } from 'src/lib/components/Button'
import { useDebounce } from 'src/lib/hooks/useDebounce'
import { ChevronRight, MinusIcon, PeopleIcon, PlusIcon } from 'src/lib/icons/icons'
import useSWR from 'swr'
import { api } from '../api'
import {
    debugLog,
    fetchWithLanguage,
    getJSONOrThrow,
    getTotalParticipants,
    isAllocationsFull,
    pluralize,
    validateAllocations
} from '../lib/utils'
import { Allocation, AllocationRule, BOOKING_BOX_TYPE, ParticipantType, VariantCapacityResponse } from '../types'
import { BookingStep, BookingSteps, useBBContext } from './Context'
import useAvailability from './useAvailabilities'

const BBParticipants = () => {
    const {
        selectedDate,
        setStep,
        selectedVariant,
        selectedParticipants,
        setSelectedParticipants,
        variantsCapacities,
        mode,
        setSelectedAddons
    } = useBBContext()
    const { experience } = useExperiencePickerContext()

    const handler = useSwipeable({
        onSwipedRight: () => {
            setStep(BookingSteps[BookingStep.Variant])
        },
    })

    let capacities: ParticipantType[] | undefined = undefined
    let rules: AllocationRule[] | undefined = undefined

    // in experience pages (/esperienza/[slug]), variant capacities are prefetched
    const loadedCapacities = variantsCapacities?.find((item) => item.variantId === selectedVariant?.id)?.capacities
        .participants
    const hasLoadedRules = variantsCapacities?.find((item) => item.variantId === selectedVariant?.id)?.capacities.rules

    const { i18n, t } = useTranslation()
    const language = i18n.language

    if (loadedCapacities) {
        capacities = loadedCapacities
        rules = hasLoadedRules
    }

    const {
        data: apiVariantCapacities,
        isLoading,
        isValidating,
    } = useSWR<VariantCapacityResponse>(
        !capacities
            ? [
                  api.endpoints.backend.bookingBox.variantCapacities.replace(
                      ':id',
                      selectedVariant?.id?.toString() ?? ''
                  ),
                  language,
              ]
            : null,
        ([url, language]) =>
            fetchWithLanguage(url, { language })
                .then((res) => getJSONOrThrow(res))
                .then((res) => {
                    debugLog('[apiVariantCapacities] response', res)
                    return {
                        ...res,
                        results: res.participants?.map((el: VariantCapacityResponse['participants'][number]) => ({
                            ...el,
                        })),
                    }
                }),
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            keepPreviousData: true,
        }
    )

    if (apiVariantCapacities) {
        capacities = apiVariantCapacities.participants
        rules = apiVariantCapacities.rules
    }

    const [allocations, setAllocations] = useState<Allocation[]>()

    const computedAllocations = useMemo(() => {
        // initialise selected participants
        if (!selectedParticipants) {
            // if the user has suggested a quantity for at least one of the participants, we set the others to 0 which is the default
            const hasAtLeastOneSuggestedQuantity =
                capacities?.filter((cap) => cap.suggested_quantity > 0) &&
                capacities?.filter((cap) => cap.suggested_quantity > 0).length > 0

            return capacities?.map((el, i) => ({
                ...el,
                quantity: hasAtLeastOneSuggestedQuantity
                    ? el.suggested_quantity
                    : capacities?.length === 1
                      ? 2
                      : i === 0
                        ? 2
                        : 0,
            }))
        } else {
            return capacities?.map((el) => {
                const selected = selectedParticipants?.find((p) => p.id === el.id)
                if (selected) {
                    return {
                        ...selected,
                    }
                } else {
                    return {
                        ...el,
                        quantity: 0,
                    }
                }
            })
        }
    }, [capacities, selectedParticipants])

    useEffect(() => {
        setAllocations(computedAllocations)
    }, [capacities, computedAllocations])

    // Prefetch availabilities
    const debouncedAllocations = useDebounce(allocations, 400)
    const { prefetchedDays } = useAvailability(
        selectedVariant,
        selectedDate,
        debouncedAllocations,
        experience.notice_period
    )

    debugLog('[BBParticipants] capacities', capacities)
    debugLog('[BBParticipants] prefetchedDays', prefetchedDays)

    return (
        <div className="flex min-h-[45dvh] flex-col justify-between pb-1.5 screen775:!pb-[85px]" {...handler}>
            <Fragment>
                {isLoading || isValidating ? (
                    <div className="loading-pulse flex w-full flex-col gap-2.5">
                        <div className="h-[54px] w-full rounded-[4px] bg-zinc-100" />
                    </div>
                ) : (
                    <div className="space-y-2.5">
                        {allocations &&
                            allocations.map((c, index) => (
                                <div
                                    key={index}
                                    className="flex max-h-[68px] min-h-[62px] select-none items-center justify-between rounded-[4px] border border-zinc-300 px-3.5 py-3 text-[15px] screen600:!max-h-[74px] screen600:py-3.5"
                                >
                                    <div className="flex items-center gap-3 pl-1">
                                        <div className="pt-[1px]">
                                            <PeopleIcon />
                                        </div>
                                        <div className="flex flex-col gap-[1px]">
                                            <span className="screen6000:!leading-[21px] text-[15px] leading-6 screen600:!line-clamp-2">
                                                {/* todo: fix italian with generic pluralize */}
                                                {c.id && pluralize(c.name, language)}
                                            </span>
                                            {c.description && (
                                                <span className="max-w-[205px] truncate text-[13px] text-zinc-500 screen600:!max-w-[175px]">
                                                    {c.description}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center gap-1.5 leading-[22px]">
                                        <button
                                            className="cursor-pointer rounded-[4px] border border-zinc-100 p-1.5 text-zinc-800 transition-all duration-100 ease-in-out hover:!border-zinc-200 hover:!bg-zinc-100/70 hover:!text-black disabled:cursor-not-allowed disabled:!text-zinc-400 disabled:opacity-40"
                                            disabled={c.quantity === 0}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setAllocations((allocations) => {
                                                    return allocations?.map((item) => {
                                                        return item.id === c.id
                                                            ? { ...item, quantity: item.quantity! - 1 }
                                                            : item
                                                    })
                                                })
                                            }}
                                        >
                                            <MinusIcon className="h-[18px] w-[18px]" />
                                        </button>
                                        <span className="w-5 text-center">{c.quantity}</span>
                                        <button
                                            className="cursor-pointer rounded-[4px] border border-zinc-100 p-1.5 text-zinc-800 transition-all duration-100 ease-in-out hover:!border-zinc-200 hover:!bg-zinc-100/70 hover:!text-black disabled:cursor-not-allowed disabled:!text-zinc-400 disabled:opacity-40"
                                            disabled={isAllocationsFull(c.id, allocations, rules ?? [])}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setAllocations((allocations) => {
                                                    return allocations?.map((item) => {
                                                        return item.id === c.id
                                                            ? { ...item, quantity: item.quantity! + 1 }
                                                            : item
                                                    })
                                                })
                                            }}
                                        >
                                            <PlusIcon className="h-[18px] w-[18px]" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </Fragment>

            <div className="absolute bottom-0 left-0 w-full items-center justify-center border-t-[1.5px] border-zinc-300 bg-white px-6 py-5 shadow-top screen775:!px-5 screen775:!py-4">
                <Button
                    // variant={ButtonVariant.BLACK}
                    variant={ButtonVariant.BLACK}
                    className="flex w-full items-center justify-center"
                    disabled={
                        !allocations ||
                        !validateAllocations(allocations, rules ?? []) ||
                        getTotalParticipants(allocations) === 0 ||
                        getTotalParticipants(allocations) < (selectedVariant?.min_customers ?? 1)
                    }
                    onClick={(e) => {
                        e.preventDefault()
                        // remove the capacity with quantity 0 and sub capacity null (this combination is not allowed)
                        if (allocations) {
                            const filteredAllocations = allocations.filter((allocation) => allocation.quantity !== 0)

                            setSelectedParticipants(filteredAllocations)
                            if (mode === BOOKING_BOX_TYPE.BOOKING) {
                                setStep(3);
                            } else if (
                                mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING &&
                                selectedVariant?.addons?.length! > 0
                            ) {
                                if (selectedVariant?.addons?.length! > 0) {
                                    setSelectedAddons(
                                        selectedVariant?.addons?.map((el) => ({
                                            ...el,
                                            qt: 0,
                                            price: el.partner_price ?? '',
                                        })) ?? []
                                    );
                                }
                                setStep(BookingSteps[BookingStep.GiftAddons]);
                            } else {
                                setStep(BookingSteps[BookingStep.Recap]);
                            }                        }
                    }}
                >
                    <span>{t('continue')}</span>
                    <ChevronRight className="h-[18px] w-[18px]" />
                </Button>
            </div>
        </div>
    )
}
export { BBParticipants }
