import dayjs from 'dayjs'

import { useCOContext } from '../../Context'

import clsx from 'clsx'
import { Fragment } from 'react'
import { CalendarIconLean, CheckYesGreenIcon, PeopleIcon } from 'src/lib/icons/icons'
import {
    checkNullOrUndefined,
    convertToCOProductBooking,
    dayExtendedMonthDate,
    fetchWithLanguage,
    formatDate,
    formatDuration,
    formatJsonAddon,
    formatJsonAllocations,
} from 'src/lib/utils'
import { useTranslation } from 'react-i18next'
import { useExperiencePickerContext } from '../../../ExperiencePicker/Context'
import { useBBContext } from '../../../BookingBox/Context'
import { BOOKING_BOX_TYPE, DISCOUNT_TYPE, ThanksBooking } from '../../../types'
import Divider from '../../../lib/components/Divider'
import { BookingPurchaseDataLayer, OpenBookingPurchaseDataLayer } from '../../../Analytics/AnalyticsDataLayer'
import { useAnalyticsContext } from '../../../Analytics/Context'
import { api } from '../../../api'
import useSWR from 'swr'
import LoadingDots from '../../../lib/components/LoadingDots'

export const CheckoutRecapBox = ({ afterCheckout = false }: { afterCheckout?: boolean }) => {
    const { mode } = useBBContext()
    if (mode === BOOKING_BOX_TYPE.OPEN_DATE_BOOKING) {
        return <CheckoutOpenBookingRecapBox afterCheckout={afterCheckout} />
    } else if (mode === BOOKING_BOX_TYPE.BOOKING) {
        return <CheckOutBookingRecapBox afterCheckout={afterCheckout} />
    }
}

const CheckoutOpenBookingRecapBox = ({ afterCheckout = false }: { afterCheckout?: boolean }) => {
    const { openBooking, customerInfo } = useCOContext()
    const { useAnalytics } = useAnalyticsContext()

    const { i18n, t } = useTranslation()
    const language = i18n.language

    if (!openBooking)
        return (
            <div className="flex h-[114px] w-full flex-col gap-2.5 border-y border-zinc-300 bg-zinc-50 p-4">
                <div className="loading-pulse h-5 w-[80%] rounded-full bg-gray-100" />
                <div className="loading-pulse h-5 w-[50%] rounded-full bg-gray-100" />
            </div>
        )

    if (openBooking) {
        return (
            <div
                className={clsx(
                    'relative w-full border-zinc-300 bg-zinc-50 p-[11px] text-[14.5px]',
                    afterCheckout ? 'rounded border px-[13px]' : 'border-y px-6'
                )}
            >
                <div className="flex screen775:flex-wrap screen600:flex-nowrap">
                    <div className="flex w-full flex-col gap-[6px] py-[1.5px]">
                        <div className="flex items-center text-[14.5px]">
                            <PeopleIcon className="mr-2.5 h-4 w-4" />
                            <span>{formatJsonAllocations(openBooking.json_allocations, language)}</span>
                            {openBooking.json_addons && openBooking.json_addons.length > 0 && (
                                <span>
                                    {', '}
                                    {formatJsonAddon(openBooking.json_addons, 'qt')}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {afterCheckout && (
                    <Fragment>
                        {useAnalytics && (
                            <OpenBookingPurchaseDataLayer
                                openBookingCode={openBooking.code}
                                price={openBooking.partner_price}
                                customer_email={customerInfo.email}
                            />
                        )}
                        <Divider className="my-2.5" />
                        <div className="flex items-center justify-between text-[14.5px] font-medium">
                            <span className="font-bold">{t('totalImport')}</span>
                            <span className="font-bold">{Number(openBooking.original_value).toFixed(2)} €</span>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    } else {
        return <Fragment>No booking</Fragment>
    }
}

const CheckOutBookingRecapBox = ({ afterCheckout = false }: { afterCheckout?: boolean }) => {
    const { booking, coupons } = useCOContext()
    const { experience } = useExperiencePickerContext()
    const { useAnalytics } = useAnalyticsContext()

    const { i18n, t } = useTranslation()
    const language = i18n.language

    if (!booking)
        return (
            <div className="flex h-[114px] w-full flex-col gap-2.5 border-y border-zinc-300 bg-zinc-50 p-4">
                <div className="loading-pulse h-5 w-[80%] rounded-full bg-gray-100" />
                <div className="loading-pulse h-5 w-[50%] rounded-full bg-gray-100" />
            </div>
        )
    const convertedBooking = convertToCOProductBooking(booking)

    const hasVoucher =
        coupons && coupons.filter((coupon) => coupon.discount_type === DISCOUNT_TYPE.OPEN_BOOKING).length > 0

    if (!convertedBooking?.dateBooking) return <Fragment>Could not retrieve booking</Fragment>

    if (convertedBooking) {
        const { data: thanksBooking } = useSWR<ThanksBooking>(
            booking && afterCheckout
                ? api.endpoints.backend.checkout.thankYouForBooking.replace(':code', booking.code)
                : null,
            (url) => fetchWithLanguage(url, { language }).then((res) => res.json()),
            {
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
            }
        )

        return (
            <div
                className={clsx(
                    'relative w-full border-zinc-300 bg-zinc-50 p-[11px] text-[14.5px]',
                    afterCheckout ? 'rounded border px-[13px]' : 'border-y px-6'
                )}
            >
                <div className="flex w-full items-center justify-start gap-1.5 pr-2 text-[13px] text-emerald-600">
                    <CheckYesGreenIcon className="h-3.5 w-3.5" />

                    <div>
                        {t('freeCancellationUntilDatetime', {
                            time: dayjs
                                .tz(new Date(convertedBooking.dateBooking))
                                .subtract(convertedBooking.cancelationDeadlineDays!, 'day')
                                .format('H:mm'),
                            date: dayExtendedMonthDate(
                                dayjs
                                    .tz(new Date(convertedBooking.dateBooking))
                                    .subtract(convertedBooking.cancelationDeadlineDays!, 'day'),
                                language
                            ),
                        })}
                    </div>
                </div>

                {!(coupons && coupons.length > 0) &&
                    experience &&
                    !checkNullOrUndefined(experience.deposit_percentage) &&
                    !afterCheckout && (
                        <Fragment>
                            <Divider className="my-2.5" />

                            <div className="flex w-full items-center justify-start gap-1.5 pr-2 text-[13px] text-emerald-600">
                                <CheckYesGreenIcon className="h-3.5 w-3.5" />
                                {t('depositExperienceFromBoxRecap', {
                                    deposit: Number(booking.deposit_holidoit_price).toFixed(2),
                                    rest: (
                                        Number(booking.holidoit_price) - Number(booking.deposit_holidoit_price)
                                    ).toFixed(2),
                                })}
                            </div>
                        </Fragment>
                    )}

                <Divider className="my-2.5" />

                <div className="flex screen775:flex-wrap screen600:flex-nowrap">
                    <div className="flex w-full flex-col gap-[6px] py-[1.5px]">
                        <div className="flex w-full items-center justify-between">
                            <div className="flex w-full items-center text-[14.5px]">
                                <CalendarIconLean className="mr-2.5 h-4 w-4" />
                                <span className="truncate">
                                    {formatDate(convertedBooking.dateBooking, language)}, {convertedBooking.timeBooking}
                                    {convertedBooking.characteristics?.description && (
                                        <Fragment>
                                            {' - '}
                                            {formatDuration(convertedBooking.characteristics?.duration!, t)}
                                            <span className="mx-[10px] screen600:mx-[6px]">•</span>
                                            <span>{convertedBooking.characteristics?.description}</span>
                                        </Fragment>
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className="flex items-center text-[14.5px]">
                            <PeopleIcon className="mr-2.5 h-4 w-4" />
                            <span>{formatJsonAllocations(convertedBooking.json_allocations, language)}</span>
                            {convertedBooking.addons && convertedBooking.addons.length > 0 && (
                                <span>
                                    {', '}
                                    {formatJsonAddon(convertedBooking.addons)}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {afterCheckout &&
                    (thanksBooking ? (
                        <Fragment>
                            {useAnalytics && <BookingPurchaseDataLayer bookingCode={booking.code} />}
                            <Divider className="my-2.5" />
                            <div className="flex items-center justify-between text-[14.5px] font-medium">
                                <span className="font-bold">{t('bookingValue')}</span>
                                <span className="font-bold">{Number(thanksBooking.holidoit_price).toFixed(2)} €</span>
                            </div>

                            {thanksBooking.used_vouchers && thanksBooking.used_vouchers.length > 0 && (
                                <Fragment>
                                    <Divider className="my-2.5" />
                                    <div className="flex flex-col gap-1">
                                        <span className="font-semibold">{t('discounts')}:</span>
                                        {thanksBooking.used_vouchers.map((voucher) => (
                                            <div className="flex items-center justify-between text-[14.5px] font-medium">
                                                <span>{voucher.code}</span>
                                                <span>- {Number(voucher.amount).toFixed(2)} €</span>
                                            </div>
                                        ))}
                                    </div>
                                </Fragment>
                            )}

                            {!hasVoucher && booking.has_deposit && (
                                <Fragment>
                                    <Divider className="my-2.5" />
                                    <div className="flex items-center justify-between text-[14.5px] font-medium">
                                        <span className="underline">{t('toBePaidOnSite')}</span>
                                        <span>{Number(thanksBooking.to_be_paid_on_site).toFixed(2)} €</span>
                                    </div>
                                </Fragment>
                            )}

                            <Fragment>
                                <Divider className="my-2.5" />
                                <div className="flex items-center justify-between text-[14.5px] font-medium text-green-700">
                                    <span className="font-bold">{t('amountPaid')}</span>
                                    <span className="font-bold">
                                        {Number(thanksBooking.paid_online_by_customer).toFixed(2)} €
                                    </span>
                                </div>
                            </Fragment>
                        </Fragment>
                    ) : (
                        <LoadingDots />
                    ))}
            </div>
        )
    } else {
        return <Fragment>No booking</Fragment>
    }
}
