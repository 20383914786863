// import Do from '@/assets/images/do-holidoit.jpeg';
// import Facebook from '@/assets/images/facebook-circle-logo-png.png';
// import Google from '@/assets/images/Google.png';
// import Tripadvisor from '@/assets/images/tripadvisor-logo.png';
// import RatingStarIcon from '@/components/icons/RatingStarIcon';
// import GalleryModal from '@/components/media/GalleryModal';
// import PaginationBar from '@/components/navigation/PaginationBar';

import { CheckCircledIcon, StarIcon } from '@radix-ui/react-icons'
import clsx from 'clsx'

import React, { Fragment, useState } from 'react'
import useSWR from 'swr'
import { Languages, ReviewSource, ReviewType, VariantType } from '../../types'
import { api } from '../../api'
import PaginationBar from './PaginationBar'
import { RatingStarIcon } from '../../lib/icons/icons'
import { capitalize, formatDate_withFormat, formatTime, parseAndFormatDurationISO8601 } from '../../lib/utils'

interface IExperienceReviews extends React.ComponentPropsWithoutRef<'div'> {
    experienceId: number
    // images: string[]
    averageRating: number
    totalReviews: number
    // numPages: number
    reviewsPageSize: number
    t: any
    lang: Languages
    showDisclaimer?: boolean
    showVariant?: boolean
    showExperience?: boolean
}

export interface IApiExperienceReviewResponseData {
    results: ReviewType[]
    count: number
    next?: string | null
    previous?: string | null
}

const ExperienceReviews: React.FC<IExperienceReviews> = ({
    averageRating,
    totalReviews,
    reviewsPageSize,
    experienceId,
    t,
    lang,
    showDisclaimer = false,
    showVariant = true,
    showExperience = false,
}) => {
    const [page, setPage] = React.useState(1)

    const changePage = (newPage: number) => {
        setPage(newPage)
    }

    const { data: reviews, isLoading } = useSWR<IApiExperienceReviewResponseData>(
        experienceId
            ? api.endpoints.backend.experiencePicker.getReviews
                  .replace(':id', experienceId.toString())
                  .concat(`?page=${page}&page_size=${reviewsPageSize}&lang=${lang}`)
            : null,
        (url) => fetch(url).then((res) => res.json())
    )
    const numPages = Math.ceil(reviews?.count / reviewsPageSize)

    return (
        <Fragment>
            <div className="mb-2 flex items-center justify-between" id="reviewsTitle">
                {averageRating && (
                    <h2 className="relative text-[19px] font-semibold">
                        {totalReviews != 0 && (
                            <>
                                <StarIcon fill="#1f2937" className="mb-[5px] mr-[5px] inline-block h-[16px] w-[16px]" />
                                {Number(averageRating).toFixed(averageRating === 5 ? 1 : 2)}
                                <span className="mx-1.5"> • </span> {totalReviews} {t('review.plural').toLowerCase()}
                            </>
                        )}
                    </h2>
                )}
            </div>

            <div className="relative grid grid-cols-1 gap-2.5">
                {page === 1 &&
                    reviews &&
                    reviews.results.map((comment, index) => {
                        return (
                            <div key={index}>
                                <ExperienceCommentCard
                                    comment={comment}
                                    t={t}
                                    lang={lang}
                                    fullName={comment.source === ReviewSource.HOLIDOIT}
                                    showVariant={showVariant}
                                    showExperience={showExperience}
                                />
                            </div>
                        )
                    })}

                {page > 1 &&
                    isLoading &&
                    Array.from({ length: reviewsPageSize - 1 }).map((_, index) => {
                        return (
                            <div key={index}>
                                <div className="flex animate-pulse flex-col gap-2.5 border-b pb-5 pt-2">
                                    <div className="flex w-full justify-between pr-0.5 font-medium">
                                        <div className="flex items-center gap-[1px]">
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <div className="h-4 w-4 rounded bg-gray-100" key={index} />
                                            ))}
                                        </div>
                                        <div className="h-5 w-14 rounded bg-gray-100"></div>
                                    </div>
                                    <div className="h-5 w-full rounded bg-gray-100"></div>
                                    <div className="h-5 w-[70%] rounded bg-gray-100"></div>
                                </div>
                            </div>
                        )
                    })}
                {page > 1 &&
                    !isLoading &&
                    reviews &&
                    reviews.results.map((comment, index) => {
                        return (
                            <div key={index}>
                                <ExperienceCommentCard
                                    comment={comment}
                                    t={t}
                                    lang={lang}
                                    fullName={comment.source === ReviewSource.HOLIDOIT}
                                    showVariant={showVariant}
                                    showExperience={showExperience}
                                />
                            </div>
                        )
                    })}
            </div>

            {numPages > 1 && (
                <div className="mt-5">
                    <PaginationBar
                        containerClassName={'flex items-center gap-1.5'}
                        page={page}
                        setPage={changePage}
                        numPages={numPages}
                        // arrowsClassName="rounded-full h-[38px] aspect-square bg-gray-100 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                        // buttonsClassName="rounded-full px-2.5 py-1 h-[38px] aspect-square hover:border border-gray-400"
                        // selectedButtonClassName="font-medium bg-gray-800 text-white border-none"
                    />
                </div>
            )}

            {showDisclaimer && <div className="mt-4 text-[12.5px] text-gray-400">{t('review.reviewDisclaimer')}</div>}
        </Fragment>
    )
}

const descriptionCutoff = 210
const answerCutoff = 100

export const ExperienceCommentCard: React.FC<{
    comment: ReviewType
    t: any
    lang: Languages
    fullName?: boolean
    noBorder?: boolean
    noTooltip?: boolean
    showImages?: boolean
    showVariant?: boolean
    showExperience?: boolean
    className?: string
    showAnswer?: boolean
}> = ({
    comment,
    t,
    lang,
    fullName = false,
    noBorder = false,
    noTooltip = false,
    showImages = true,
    showVariant = true,
    showExperience = false,
    className,
    showAnswer = true,
}) => {
    const [expandedDescription, setExpandedDescription] = React.useState(false)
    const [expandedAnswer, setExpandedAnswer] = React.useState(false)

    const [photoId, setPhotoId] = useState(0)
    const [galleryOpen, setGalleryOpen] = useState(false)
    const [minimised, setMinimised] = useState(false)
    const [height, setHeight] = useState(0)

    return (
        <div className={clsx('flex flex-col gap-1.5 pb-5 pt-2', noBorder ? 'border-none' : 'border-b', className)}>
            <div className="flex w-full justify-between gap-2 pr-0.5 font-medium">
                <div className="flex items-center gap-[1px]">
                    <div className="flex items-center gap-[1px] pb-[2px]">
                        {Array.from({ length: comment.star }).map((_, index) => (
                            <span key={index}>
                                <RatingStarIcon
                                    className="h-3 w-3"
                                    fill={comment.star >= 3 ? 'rgb(250 204 21)' : '#f87171'}
                                    border
                                />
                            </span>
                        ))}
                    </div>
                    <div className="ml-[5px] text-[14.5px] text-gray-700">
                        -{' '}
                        <span className="max-w-[60%] text-ellipsis font-medium capitalize">
                            {fullName && !comment.hide_full_name
                                ? comment.author
                                : comment.author
                                      .split(' ')
                                      .map((letter, index) => (index === 0 ? letter : letter.slice(0, 1) + '.')) // get first letter of each word
                                      .join(' ')}
                        </span>
                    </div>
                    <div className="ml-2 flex items-center gap-[3px]">
                        {/*logos Holidoit / Google / etc. based on the source */}

                        {comment.booking?.code && (
                            <div className="group relative">
                                <div className="flex items-center gap-1 rounded-full bg-emerald-50 px-2 py-[3px] text-[11.5px] text-emerald-600 screen600:py-[2.5px]">
                                    <CheckCircledIcon className="h-3 w-3" />
                                    {t('review.verified')}
                                </div>
                            </div>
                        )}

                        {comment.source === ReviewSource.HOLIDOIT && (
                            <div className="ml-0.5">
                                <img
                                    width={16.5}
                                    height={16.5}
                                    alt="holidoit"
                                    src={"https://holidoit-public.s3.eu-south-1.amazonaws.com/logo-mini-png.png"}
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                    loading={'lazy'}
                                />
                            </div>
                        )}

                        {(comment.source === ReviewSource.GOOGLE || !comment.booking?.info?.datetime_from) && (
                            <div className="group relative">
                                <img
                                    width={15}
                                    height={15}
                                    alt="google"
                                    src={'https://holidoit-public.s3.eu-south-1.amazonaws.com/logos/Google.png'}
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                    loading={'lazy'}
                                />
                                <div className={clsx('screen900:hidden', noTooltip ? 'hidden' : '')}>
                                    <div className="tooltip absolute left-0 top-[calc(0%-40px)] z-10 hide whitespace-nowrap !px-2 !py-1.5 text-[13.5px] font-normal group-hover:block">
                                        {t('review.reviewFrom')} {capitalize(ReviewSource.GOOGLE)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {comment.source === ReviewSource.FACEBOOK && (
                            <div className="group relative">
                                <img
                                    width={15}
                                    height={15}
                                    alt="facebook"
                                    src={
                                        'https://holidoit-public.s3.eu-south-1.amazonaws.com/logos/facebook-circle-logo-png.png'
                                    }
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                    loading={'lazy'}
                                />
                                <div className={clsx('screen900:hidden', noTooltip ? 'hidden' : '')}>
                                    <div className="tooltip absolute left-0 top-[calc(0%-40px)] z-10 hide whitespace-nowrap !px-2 !py-1.5 text-[13.5px] font-normal group-hover:block">
                                        {t('review.reviewFrom')} {capitalize(comment.source)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {comment.source === ReviewSource.TRIPADVISOR && (
                            <div className="group relative -ml-2">
                                <img
                                    width={35}
                                    height={35}
                                    alt="tripadvisor"
                                    src={
                                        'https://holidoit-public.s3.eu-south-1.amazonaws.com/logos/tripadvisor-logo.png'
                                    }
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                    loading={'lazy'}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {comment.booking?.info?.datetime_from && (
                <div className="-mt-[1px] flex items-center gap-2 overflow-hidden whitespace-nowrap text-[13px] text-zinc-400">
                    <span className="capitalize">
                        {formatDate_withFormat(new Date(comment.booking.info.datetime_from), lang, 'ddd DD MMM YY')},{' '}
                        {formatTime(new Date(comment.booking.info.datetime_from))} -{' '}
                        {formatTime(new Date(comment.booking.info.datetime_to))}
                    </span>

                    {/*{comment.booking?.json_allocations && <span>•</span>}*/}
                    {/*{comment.booking?.json_allocations && (*/}
                    {/*    <>*/}
                    {/*        {lang === Languages.ENGLISH ? (*/}
                    {/*            <span>*/}
                    {/*                {formatEnglishJsonAllocations(comment.booking.json_allocations)}*/}
                    {/*                {comment.booking?.json_addons &&*/}
                    {/*                    comment.booking.json_addons.length > 0 &&*/}
                    {/*                    ', ' + formatEnglishJsonAddon(comment.booking.json_addons)}*/}
                    {/*            </span>*/}
                    {/*        ) : (*/}
                    {/*            <span>*/}
                    {/*                {formatReviewJsonAllocations(comment.booking.json_allocations)}*/}
                    {/*                {comment.booking?.json_addons &&*/}
                    {/*                    comment.booking.json_addons.length > 0 &&*/}
                    {/*                    ', ' + formatJsonAddon(comment.booking.json_addons)}*/}
                    {/*            </span>*/}
                    {/*        )}*/}
                    {/*    </>*/}
                    {/*)}*/}

                    {comment.booking?.info?.variant?.duration && showVariant && <span>•</span>}
                    {comment.booking?.info?.variant?.duration && showVariant && (
                        <span>
                            {parseAndFormatDurationISO8601(comment.booking?.info?.variant?.iso_formatted_duration, t)}
                            {comment.booking?.info?.variant?.name && ` - ${comment.booking?.info?.variant?.name}`}
                            {comment.booking?.info?.variant?.type === VariantType.PRIVATE && t.variant.private}
                        </span>
                    )}
                </div>
            )}

            {comment.comment && (
                <div className="text-sm leading-[22px] text-gray-600">
                    {comment.comment.slice(0, expandedDescription ? comment.comment.length : descriptionCutoff)}
                    {comment.comment.length > descriptionCutoff ? (
                        <span
                            className="ml-1 cursor-pointer font-semibold text-gray-900 hover:underline"
                            onClick={() => setExpandedDescription((prev) => !prev)}
                        >
                            {expandedDescription ? t('review.collapse') : t('review.expand')}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            )}

            {comment.images && comment.images.length > 0 && showImages && (
                <div className="mb-1 mt-2 flex flex-row gap-3 overflow-x-auto scrollbar-hide">
                    {comment.images.map((image, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                setGalleryOpen(true)
                                // setExpandedDescription(true);
                                setPhotoId(index)
                            }}
                        >
                            <img
                                width={120}
                                height={120}
                                alt="review"
                                src={image.image || ''}
                                // src={image.image.replace('/full/', '/300/') || ''}
                                className="max-h-[96px] min-h-[96px] min-w-[96px] max-w-[96px] cursor-pointer rounded-md object-cover screen900:max-h-[82px] screen900:min-h-[82px] screen900:min-w-[82px] screen900:max-w-[82px] screen900:rounded-[5px]"
                                // className="hidden screen600:flex cursor-pointer rounded-md screen900:rounded-[5px] max-h-[96px] max-w-[96px] min-w-[96px] min-h-[96px] screen900:max-h-[82px] screen900:max-w-[82px] screen900:min-w-[82px] screen900:min-h-[82px] object-cover"
                                loading="lazy"
                            />
                            {/* <Image*/}
                            {/*    width={120}*/}
                            {/*    height={120}*/}
                            {/*    alt="review"*/}
                            {/*    src={image.image.replace('/full/', '/450/') || ''}*/}
                            {/*    className="screen600:hidden cursor-pointer rounded-md screen900:rounded-[5px] max-h-[96px] max-w-[96px] min-w-[96px] min-h-[96px] screen900:max-h-[82px] screen900:max-w-[82px] screen900:min-w-[82px] screen900:min-h-[82px] object-cover"*/}
                            {/*    loading="lazy"*/}
                            {/*/>*/}
                        </div>
                    ))}
                </div>
            )}

            {comment.answer && comment.answer.length > 0 && showAnswer && (
                <div className="mt-1.5 flex flex-col gap-[3px] border-l-[3px] border-gray-200/90 py-1 pl-4 text-sm leading-[22px] text-gray-600">
                    <span className="font-semibold">{t('review.answerFromHost')}:</span>
                    <span>
                        {comment.answer.slice(0, expandedAnswer ? comment.answer.length : answerCutoff)}
                        {comment.answer.length > answerCutoff ? (
                            <span
                                className="ml-1 cursor-pointer font-medium text-gray-400 hover:underline"
                                onClick={() => setExpandedAnswer((prev) => !prev)}
                            >
                                {expandedAnswer ? t('review.collapse') : t('review.expand')}
                            </span>
                        ) : (
                            ''
                        )}
                    </span>
                </div>
            )}

            {/*{galleryOpen && (*/}
            {/*    <GalleryModal*/}
            {/*        images={comment.images.map((image, index) => ({*/}
            {/*            id: index,*/}
            {/*            thumbnailUrl: image.image,*/}
            {/*            // thumbnailUrl: isViewPortSize('screen600')*/}
            {/*            //     ? image.image.replace('/full/', '/300/')*/}
            {/*            //     : image.image.replace('/full/', '/450/'),*/}
            {/*            fullImgUrl: image.image,*/}
            {/*        }))}*/}
            {/*        carouselImagePixels={'full'}*/}
            {/*        photoId={photoId}*/}
            {/*        setPhotoId={setPhotoId}*/}
            {/*        galleryOpen={galleryOpen}*/}
            {/*        setGalleryOpen={setGalleryOpen}*/}
            {/*        imageCarousel={false}*/}
            {/*        preloadImages={false}*/}
            {/*    >*/}
            {/*        <div className="mb-1 mt-2 flex h-full w-full flex-col justify-between gap-3 overflow-x-auto scrollbar-hide screen600:mt-1.5 screen600:gap-2">*/}
            {/*            <div></div>*/}
            {/*            <motion.div*/}
            {/*                initial={{ y: 0 }}*/}
            {/*                animate={{ y: minimised ? 0 : 0 }}*/}
            {/*                transition={{ duration: 0.2 }}*/}
            {/*                drag="y"*/}
            {/*                dragConstraints={{ top: 0, bottom: 0 }}*/}
            {/*                dragElastic={{*/}
            {/*                    top: minimised ? 0.3 : 0,*/}
            {/*                    bottom: minimised ? 0 : 0.3,*/}
            {/*                }}*/}
            {/*                onDrag={(event, info) => {*/}
            {/*                    event.stopPropagation()*/}
            {/*                    setHeight(info.offset.y)*/}
            {/*                }}*/}
            {/*                onDragEnd={(event, info) => {*/}
            {/*                    event.stopPropagation()*/}
            {/*                    if (info.offset.y < 0) {*/}
            {/*                        setMinimised(false)*/}
            {/*                    } else {*/}
            {/*                        setMinimised(true)*/}
            {/*                    }*/}
            {/*                }}*/}
            {/*                className={clsx(*/}
            {/*                    'z-[120] flex flex-col gap-2 overflow-hidden rounded-t-[10px] bg-gray-900 p-3.5 pb-4',*/}
            {/*                    minimised ? 'max-h-[30px]' : `max-h-[${height}px]`*/}
            {/*                )}*/}
            {/*            >*/}
            {/*                <div className="flex w-full items-center justify-center">*/}
            {/*                    <div className="h-1 w-24 rounded-full bg-gray-200/80"></div>*/}
            {/*                </div>*/}
            {/*                <div className="text-sm font-semibold text-white">{comment.author}</div>*/}
            {/*                <div className="flex items-center gap-1 text-sm text-white">*/}
            {/*                    {Array.from({ length: comment.star }).map((_, index) => (*/}
            {/*                        <span key={index}>*/}
            {/*                            <RatingStarIcon*/}
            {/*                                className="h-3 w-3"*/}
            {/*                                fill={comment.star >= 3 ? 'rgb(250 204 21)' : '#f87171'}*/}
            {/*                                border*/}
            {/*                            />*/}
            {/*                        </span>*/}
            {/*                    ))}*/}
            {/*                </div>*/}
            {/*                <div className="mt-2 text-sm text-white">*/}
            {/*                    {comment.comment.slice(*/}
            {/*                        0,*/}
            {/*                        expandedDescription ? comment.comment.length : descriptionCutoff*/}
            {/*                    )}*/}
            {/*                    {comment.comment.length > descriptionCutoff ? (*/}
            {/*                        <span*/}
            {/*                            className="ml-1 cursor-pointer font-semibold text-white underline"*/}
            {/*                            onClick={(e) => {*/}
            {/*                                e.stopPropagation()*/}
            {/*                                setExpandedDescription((prev) => !prev)*/}
            {/*                            }}*/}
            {/*                        >*/}
            {/*                            {expandedDescription*/}
            {/*                                ? t.experience.reviews.collapse*/}
            {/*                                : t.experience.reviews.expand}*/}
            {/*                        </span>*/}
            {/*                    ) : (*/}
            {/*                        ''*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*            </motion.div>*/}
            {/*        </div>*/}
            {/*    </GalleryModal>*/}
            {/*)}*/}
        </div>
    )
}

export { ExperienceReviews }
