// utils/dayjsConfig.ts
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with UTC and Timezone support
dayjs.extend(utc);
dayjs.extend(timezone);

// Set default timezone globally to Rome
dayjs.tz.setDefault("Europe/Rome");

export default dayjs;

