import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'

interface IPaginationBarProps {
    numPages: number;
    page: number;
    setPage: (page: number) => void;
    containerClassName?: string;
    buttonsClassName?: string;
    selectedButtonClassName?: string;
    arrowsClassName?: string;
    hasArrows?: boolean;
}

export const PaginationBar: React.FC<IPaginationBarProps> = ({
    numPages,
    page,
    setPage,
    hasArrows = true,
    containerClassName = 'flex justify-center items-center col-start-2 gap-2',
    buttonsClassName = 'rounded px-2.5 py-1 h-[35px] hover:border border-gray-800',
    selectedButtonClassName = 'font-medium border border-black',
    arrowsClassName = 'flex items-center justify-center w-9 bg-gray-100 rounded-md aspect-square hover:bg-gray-200/80 transition-all duration-150 disabled:bg-gray-50 disabled:hover:bg-gray-100/60 disabled:text-gray-500 disabled:hover:text-gray-500 disabled:cursor-not-allowed',
}) => {
    const renderButton = (pageNum: number, isSelected: boolean) => (
        <button
            key={pageNum}
            type="button"
            className={`${buttonsClassName} ${isSelected && `${selectedButtonClassName}`}`}
            onClick={() => setPage(pageNum)}
        >
            {pageNum}
        </button>
    );

    const renderDots = () => <div className={`rounded px-2.5 py-1`}>...</div>;

    return (
        <div className={containerClassName}>
            {hasArrows && (
                <button
                    type="button"
                    className={arrowsClassName}
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                >
                    <ChevronLeftIcon className="w-5" />
                </button>
            )}
            {renderButton(1, page === 1)}
            {page > 3 && renderDots()}
            {page > 2 && renderButton(page - 1, false)}
            {page !== 1 && page !== numPages && renderButton(page, true)}
            {page < numPages - 1 && renderButton(page + 1, false)}
            {page < numPages - 2 && renderDots()}
            {renderButton(numPages, page === numPages)}
            {/* Last page button */}
            {hasArrows && (
                <button
                    type="button"
                    className={arrowsClassName}
                    disabled={page === numPages}
                    onClick={() => setPage(page + 1)}
                >
                    <ChevronRightIcon className="w-5" />
                </button>
            )}
        </div>
    );
};

export default PaginationBar;
