import React from 'react'

interface LoadingProps {
    size?: string
    color?: string
}

const Loading: React.FC<LoadingProps> = ({ size = '30px', color = '#212121' }) => {
    const spinnerStyle: React.CSSProperties = {
        position: 'relative',
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const circleStyle: React.CSSProperties = {
        boxSizing: 'border-box',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transformOrigin: 'center',
        border: `calc(${size} / 10) solid rgba(0, 0, 0, 0.1)`,
        borderTopColor: color,
        borderRadius: '50%',
        width: `calc(${size} - calc(${size} / 6))`,
        height: `calc(${size} - calc(${size} / 6))`,
        animation: 'spin 0.7s ease-in-out infinite',
    }

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translate(-40%, -40%)',
    }

    return (
        <div style={containerStyle}>
            <div style={spinnerStyle}>
                <div style={circleStyle} />
            </div>
        </div>
    )
}

export { Loading }
