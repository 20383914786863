import React, { Fragment, useEffect } from 'react'
import useSWR from 'swr'
import { api } from '../api'

type EventNames = 'booking-purchase-holidoit' | 'gift-purchase-holidoit' | 'purchase-holidoit'

// Generic type for DataLayer events
interface DataLayerEvent {
    event: EventNames

    [key: string]: any
}

declare global {
    interface Window {
        dataLayer: DataLayerEvent[]
    }
}

// Base props for the generic DataLayer component
interface DataLayerPushProps<T extends DataLayerEvent> {
    data: T
    identifier: string
}

const AnalyticsDataLayer = <T extends DataLayerEvent>({ data, identifier }: DataLayerPushProps<T>) => {
    useEffect(() => {
        window.dataLayer = window.dataLayer || []
        const alreadyPushed = window.dataLayer.some((obj) => obj[identifier] === data[identifier])
        if (!alreadyPushed) {
            window.dataLayer.push(data)
        }
    }, [data, identifier])
    return null
}

interface PurchaseEvent extends DataLayerEvent {
    event: EventNames
    value: string
    currency: string
    transaction_id: string
    email: string
    coupon?: string
}

interface BookingPurchaseDataLayerProps {
    bookingCode: string
}

export const BookingPurchaseDataLayer: React.FC<BookingPurchaseDataLayerProps> = ({ bookingCode }) => {
    type BookingPricing = {
        discounted_partner_price: number
        partner_price: string
        customer_email: string
        discount_codes: string[]
    }

    const {
        data: bookingPricing,
        error: errorBookingInfo,
        isLoading: loadingBooking,
    } = useSWR<BookingPricing>(
        api.endpoints.backend.checkout.partnerBookingPricing.replace(':code', bookingCode),
        (url) => fetch(url).then((res) => res.json()),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    )

    // Render nothing or a fallback if loading or error
    if (loadingBooking || errorBookingInfo || !bookingPricing) {
        return null // Or render a loading spinner or error message
    }

    const purchaseData: PurchaseEvent = {
        event: 'booking-purchase-holidoit',
        value: bookingPricing.discounted_partner_price.toString(),
        currency: 'EUR',
        transaction_id: `booking_${bookingCode}`,
        email: bookingPricing.customer_email,
        coupon: bookingPricing.discount_codes.join(', '),
    }
    const genericPurchaseData: PurchaseEvent = {
        event: 'purchase-holidoit',
        value: bookingPricing.discounted_partner_price.toString(),
        currency: 'EUR',
        transaction_id: `booking_${bookingCode}`,
        email: bookingPricing.customer_email,
        coupon: bookingPricing.discount_codes.join(', '),
        generic_id: `generic_booking_${bookingCode}`,
    }

    return (
        <Fragment>
            <AnalyticsDataLayer data={purchaseData} identifier="transaction_id" />
            <AnalyticsDataLayer data={genericPurchaseData} identifier="generic_id" />
        </Fragment>
    )
}

interface OpenBookingPurchaseDataLayerProps {
    openBookingCode: string
    price: number
    customer_email: string
}

export const OpenBookingPurchaseDataLayer: React.FC<OpenBookingPurchaseDataLayerProps> = ({
    openBookingCode,
    price,
    customer_email,
}) => {
    const purchaseData: PurchaseEvent = {
        event: 'gift-purchase-holidoit',
        value: price.toString(),
        currency: 'EUR',
        transaction_id: `gift_${openBookingCode}`,
        email: customer_email,
    }
    const genericPurchaseData: PurchaseEvent = {
        event: 'purchase-holidoit',
        value: price.toString(),
        currency: 'EUR',
        transaction_id: `gift_${openBookingCode}`,
        email: customer_email,
        generic_id: `generic_gift_${openBookingCode}`,
    }
    return (
        <Fragment>
            <AnalyticsDataLayer data={purchaseData} identifier="transaction_id" />
            <AnalyticsDataLayer data={genericPurchaseData} identifier="generic_id" />
        </Fragment>
    )
}
