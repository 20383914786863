import { BoltIcon, ChristmasPresentsIcon, CreditCardIcon, RatingStarIcon } from 'src/lib/icons/icons'
import { ExperienceType } from '../../types'
import { CarouselGallery } from 'src/lib/components/CarouselGallery'
import { minimumPriceText } from 'src/lib/utils'
import { useExperiencePickerContext } from '../Context'
import { useTranslation } from 'react-i18next'

interface ExperienceCardProps extends React.ComponentPropsWithoutRef<'div'> {
    experience: ExperienceType
}

const ExperiencePickerCard = ({ experience, ...props }: ExperienceCardProps): JSX.Element => {
    const { config } = useExperiencePickerContext()
    const { t } = useTranslation()

    return (
        <div {...props}>
            <div className="relative flex h-[250px] w-full items-center justify-center overflow-hidden rounded-t-[5px]">
                {/* TODO: rimuovere sto scempio */}
                {config.embed_id !== 'ba96b407-c872-4492-a4f3-d6d9320adeac' ? (
                    <CarouselGallery
                        images={experience.images.map((img) => img.image).map((img) => img.replace('/full/', '/600/'))}
                        id={experience.slug}
                        outsideContainerClasses="w-full h-full overflow-hidden"
                        containerClasses=""
                        showMobileArrows
                    />
                ) : (
                    <img
                        src={experience.images[0].image.replace('/full/', '/600/')}
                        alt={experience.name}
                        className="object-cover"
                        loading="lazy"
                    />
                )}
                <div className="absolute left-4 top-4 z-[100] inline-flex flex-col gap-2">
                    {experience.embed_open_booking && (
                        <span
                            className={`flex h-6 w-fit items-center rounded-md border-zinc-700 bg-white px-2 py-1 text-xs text-zinc-700`}
                        >
                            <ChristmasPresentsIcon className="-ml-1 mr-1 h-auto w-[17px] text-[#388600]" />
                            {t('giftOption')}
                        </span>
                    )}
                    {experience.instant_booking && (
                        <span
                            className={`flex h-6 w-fit items-center rounded-md border-zinc-700 bg-white px-2 py-1 text-xs text-zinc-700`}
                        >
                            <BoltIcon className="-ml-2 w-[24px] text-yellow-600" /> {t('instantBook')}
                        </span>
                    )}
                    {experience.deposit_percentage && (
                        <span
                            className={`flex h-6 w-fit items-center rounded-md border-zinc-700 bg-white px-2 py-1 text-xs text-zinc-700`}
                        >
                            <CreditCardIcon className="-ml-1 mr-1 h-auto w-[17px] text-[#388600]" />
                            {t('depositOnly', {
                                percentage: Math.round(experience.deposit_percentage),
                            })}
                        </span>
                    )}
                </div>
            </div>
            <div className="mt-1 select-none px-4 text-[14.5px] font-medium">
                {experience.name_partner_pages || experience.name}
            </div>
            <div className="flex select-none justify-between px-4 text-[14.5px]">
                {/* <div className="flex items-center gap-1">
                    {experience.city}, {experience.region}
                </div> */}
                {/* <div className="flex items-center gap-1 text-zinc-700">Da {experience.min_person_price}€ a persona</div> */}

                <div className="flex items-center gap-1 text-zinc-700">
                    {minimumPriceText(
                        config.is_holidoit_network_operator ? 'holidoit' : 'partner',
                        { holidoit: experience.min_person_price, partner: experience.min_person_price_partner },
                        t,
                        experience.min_price_is_group,
                        experience.min_price_is_fixed,
                        { holidoit: experience.price_one_liner, partner: experience.price_one_liner_partner }
                    )}
                </div>

                <div className="flex items-center gap-1 rounded-full pr-1.5 text-[13.5px] text-zinc-500">
                    <RatingStarIcon className="h-3 w-3" />
                    {experience.average_review} ({experience.total_reviews} {t('reviews').toLowerCase()})
                </div>
            </div>
        </div>
    )
}

export { ExperiencePickerCard }
