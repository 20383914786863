import { Fragment, useState } from 'react'
import { IMinimalExperienceVariant } from '../../types'
import { useTranslation } from 'react-i18next'
import { ChevronUpIcon } from '@radix-ui/react-icons'
import { parseAndFormatDurationISO8601 } from '../../lib/utils'

export const PartnerExperiencePrices: React.FC<
    {
        variants: IMinimalExperienceVariant[]
        onVariantSelected?: (variant?: IMinimalExperienceVariant) => void
    } & React.ComponentPropsWithoutRef<'div'>
> = (props) => {
    const { variants = [] } = props
    const { t } = useTranslation()

    return (
        <Fragment>
            <div className="mt-3 grid grid-cols-1 rounded-[5px] border bg-[#ECEFF1]/20">
                {variants &&
                    variants.length > 0 &&
                    variants.map((variant, index) => (
                        <div
                            key={index}
                            className={`${index < variants.length - 1 && variants.length !== 1 ? 'border-b' : ''} ${
                                index === variants.length - 1 && 'rounded-b'
                            } grid grid-cols-12 border-gray-200`}
                        >
                            <div className="col-span-4 border-r border-gray-200 px-2 py-[6px]">
                                <h3 className="text-[15px] font-medium leading-6">
                                    {parseAndFormatDurationISO8601(variant.iso_formatted_duration, t)}
                                    {variant.name && ' • '}
                                    {variant.name && variant.name}
                                </h3>
                            </div>
                            <VariantPriceDescription variant={variant} />
                        </div>
                    ))}
            </div>
        </Fragment>
    )
}

interface VariantPriceDescriptionProps {
    variant: IMinimalExperienceVariant
    moreTextKey?: string
    lessTextKey?: string
}

const priceDescriptionCharsCutOff = 210

const VariantPriceDescription: React.FC<VariantPriceDescriptionProps> = ({
    variant,
    moreTextKey = 'review.expand',
    lessTextKey = 'review.collapse',
}) => {
    const { t } = useTranslation()
    const [expandedDescription, setExpandedDescription] = useState(false)
    const partnerPriceDescription = variant.partner_price_description || variant.price_description

    const moreText = t(moreTextKey)
    const lessText = t(lessTextKey)

    return (
        <>
            {partnerPriceDescription && (
                <div className="col-span-8 px-2 py-[6px]">
                    <div
                        className="prose-base rounded text-[14.5px] leading-6"
                        dangerouslySetInnerHTML={{
                            __html:
                                `${partnerPriceDescription.slice(
                                    0,
                                    expandedDescription ? partnerPriceDescription.length : priceDescriptionCharsCutOff
                                )}` +
                                (expandedDescription
                                    ? ''
                                    : partnerPriceDescription.length > priceDescriptionCharsCutOff
                                      ? '...'
                                      : ''),
                        }}
                    ></div>

                    {partnerPriceDescription.length > priceDescriptionCharsCutOff && (
                        <div
                            className="mt-0.5 flex  cursor-pointer items-center gap-0.5 pb-[3px] text-[14px] font-medium text-zinc-400 hover:underline"
                            onClick={() => setExpandedDescription((prev) => !prev)}
                        >
                            {expandedDescription ? lessText : moreText}
                            {expandedDescription && <ChevronUpIcon className="h-3.5 w-3.5" />}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
