import React, { useState } from 'react'
import { ChevronDownIcon } from '../../lib/icons/icons'
import clsx from 'clsx'

interface AccordionItemProps {
    title: string
    body: React.ReactNode;
    isOpen: boolean
    onClick: () => void
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, body, isOpen, onClick }) => (
    <div className="border-b border-gray-200 last:border-b-0">
        <button className="flex w-full items-center justify-between py-4 text-left" onClick={onClick}>
            <div>
                <h3 className="text-lg font-bold">{title}</h3>
            </div>
            <ChevronDownIcon className={`h-5 w-5 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
        </button>
        {isOpen && (
            <div className="pb-4">
                {body}
            </div>
        )}
    </div>
)

interface AccordionProps {
    items: { title: string; body: React.ReactNode }[]
    className?: string
}

const Accordion: React.FC<AccordionProps> = ({ items, className }) => {
    const [openSection, setOpenSection] = useState(null)

    const toggleSection = (index, className = '') => {
        setOpenSection(openSection === index ? null : index)
    }


    return (
        <div className={clsx('flex flex-col border-t border-gray-200', className)}>
            {' '}
            {items.map((item, index) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    body={item.body}
                    isOpen={openSection === index}
                    onClick={() => toggleSection(index)}
                />
            ))}
        </div>
    )
}

export default Accordion
