import { Fragment, useEffect, useState } from 'react'
import { CalendarIconLean, ChevronRight, GroupIcon, SlotClockIcon, VariantsIcon } from 'src/lib/icons/icons'
import { formatDateReduced, formatDuration, formatTime, getTotalParticipants } from 'src/lib/utils'
import { BookingStep, BookingSteps, useBBContext } from '../Context'
import { useTranslation } from 'react-i18next'
import { BOOKING_BOX_TYPE } from '../../types'

const BBStepIndicator = () => {
    const { step, setStep, selectedVariant, selectedParticipants, selectedDate, selectedSlot, variants, mode } =
        useBBContext()

    const { i18n, t } = useTranslation()
    const language = i18n.language

    const [progressWidth, setProgressWidth] = useState<string>('32%')
    useEffect(() => {
        if (mode === BOOKING_BOX_TYPE.BOOKING) {
            if (step === BookingSteps[BookingStep.Variant]) {
                setProgressWidth('22%')
            }
            if (step === BookingSteps[BookingStep.Participants]) {
                setProgressWidth('50%')
            }
            if (step === BookingSteps[BookingStep.Date]) {
                setProgressWidth('80%')
            }
            if (
                step === BookingSteps[BookingStep.Slot] ||
                step === BookingSteps[BookingStep.Addons] ||
                step === BookingSteps[BookingStep.Recap]
            ) {
                setProgressWidth('100%')
            }
        } else {
            if (step === BookingSteps[BookingStep.Variant]) {
                setProgressWidth('22%')
            }
            if (step >= BookingSteps[BookingStep.Participants]) {
                setProgressWidth('100%')
            }
        }
    }, [step, mode])

    const variantsLenght = variants?.length ?? 0

    return (
        <Fragment>
            <div className="relative h-[37px] overflow-hidden rounded-[3px] bg-sky-50 px-2.5 text-[14px] font-medium leading-[37px]">
                {/* This div creates the 'arrow' background shape in the progress bar */}
                <div className={`absolute left-0  h-full rounded-[3px] bg-black`} style={{ width: progressWidth }}>
                    <div className="absolute right-[-11px] h-[37px] w-[37px] rotate-45 rounded-[3px] bg-black"></div>
                </div>

                <div className="relative z-10 flex items-center justify-around">
                    {/* Variant */}
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            // if (variantsLenght > 1) {
                            setStep(BookingSteps[BookingStep.Variant])
                            // }
                        }}
                        className={`flex cursor-pointer flex-nowrap items-center gap-1.5 text-white ${
                            variantsLenght > 1 ? '' : 'cursor-not-allowed'
                        }`}
                    >
                        <div className="h-[37px]" />
                        <VariantsIcon className="h-[18px] w-[18px]" />
                        {/* {step === BookingSteps[BookingStep.Variant] && <span className="mr-[30px]">Tour</span>} */}
                        {step > BookingSteps[BookingStep.Variant] && (
                            <span className="shrink overflow-hidden text-ellipsis whitespace-nowrap !font-medium">
                                {formatDuration(selectedVariant?.formatted_duration!, t)}
                            </span>
                        )}
                    </button>

                    <div className={`${step > BookingSteps[BookingStep.Variant] ? 'text-white' : 'text-transparent'}`}>
                        <ChevronRight className="h-[18px] w-[18px]" />
                    </div>

                    {/* Participants */}
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setStep(BookingSteps[BookingStep.Participants])
                        }}
                        className={`flex flex-nowrap items-center gap-1.5 !font-medium ${
                            step >= BookingSteps[BookingStep.Participants]
                                ? 'cursor-pointer text-white'
                                : 'pointer-events-none'
                        }`}
                    >
                        <GroupIcon
                            className={`h-[18px] w-[18px] ${
                                step >= BookingSteps[BookingStep.Participants] ? 'text-white' : 'text-black'
                            }`}
                        />
                        {/* {step === BookingSteps[BookingStep.Participants] && <span>Partecipanti</span>} */}
                        {step > BookingSteps[BookingStep.Participants] && selectedParticipants && (
                            <span className="whitespace-nowrap">{getTotalParticipants(selectedParticipants)}</span>
                        )}
                    </button>

                    {/* Date */}
                    {mode == BOOKING_BOX_TYPE.BOOKING && (
                        <Fragment>
                            <div
                                className={`${step > BookingSteps[BookingStep.Participants] ? '!text-white' : 'text-transparent'}`}
                            >
                                <ChevronRight className="h-[18px] w-[18px]" />
                            </div>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setStep(BookingSteps[BookingStep.Date])
                                }}
                                className={`flex flex-nowrap  items-center gap-[7px] !font-medium ${
                                    step >= BookingSteps[BookingStep.Date]
                                        ? 'cursor-pointer text-white '
                                        : 'pointer-events-none text-zinc-800'
                                }`}
                            >
                                <CalendarIconLean className="h-[18px] w-[18px]" />
                                {step === BookingSteps[BookingStep.Date] && <span>{t('date')}</span>}
                                {step > BookingSteps[BookingStep.Date] && selectedDate && (
                                    <span className="whitespace-nowrap">
                                        {formatDateReduced(selectedDate, language)}
                                    </span>
                                )}
                            </button>
                        </Fragment>
                    )}

                    {mode == BOOKING_BOX_TYPE.BOOKING && (
                        <Fragment>
                            <div
                                className={`${step >= BookingSteps[BookingStep.Slot] ? '!text-white' : 'text-transparent'}`}
                            >
                                <ChevronRight className="h-[18px] w-[18px]" />
                            </div>

                            {/* Slot */}
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setStep(BookingSteps[BookingStep.Slot])
                                }}
                                className={`flex flex-nowrap items-center gap-[7px] !font-medium ${
                                    step === BookingSteps[BookingStep.Slot] ||
                                    step === BookingSteps[BookingStep.Recap] ||
                                    step === BookingSteps[BookingStep.Addons]
                                        ? 'cursor-pointer text-white '
                                        : 'pointer-events-none'
                                }`}
                            >
                                <SlotClockIcon className="h-[18px] w-[18px]" />
                                {step === BookingSteps[BookingStep.Slot] && <span>{t('time')}</span>}
                                {step > BookingSteps[BookingStep.Slot] && selectedSlot && (
                                    <span>{formatTime(new Date(selectedSlot.starting_time))}</span>
                                )}
                            </button>
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export { BBStepIndicator }
