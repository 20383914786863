import clsx from 'clsx'
import React, { Fragment, useState } from 'react'
import Button, { ButtonVariant } from 'src/lib/components/Button'
import { Loading } from 'src/lib/components/LoadingCircle'
import { debugLog } from 'src/lib/utils'
import { BOOKING_BOX_TYPE, PaymentMethod } from '../../types'
import { CheckoutStepId, useCOContext } from '../Context'
import BookingTimer from './components/BookingTimer'
import { CardsButton } from './components/CardsButton'
import { COCustomerInformationForm } from './customer-info-step/COCustomerInfoForm'
import { PaypalGoldButton } from './payment-step/PaypalGoldButton'
import { AppleAndGooglePay } from './payment-step/Stripe'
import { StripeCardSection } from './payment-step/StripeCardSection'
import { Trans, useTranslation } from 'react-i18next'
import { useBBContext } from '../../BookingBox/Context'
import { CORecipientInfo } from './customer-info-step/CORecipientInfo'
import { PaymentRecap } from './components/PaymentRecap'
import { SkipPaymentButton } from './payment-step/SkipPaymentButton'
import { computeBookingPriceWithVoucher } from '../lib/priceUtils'

export interface CheckoutFormProps extends React.ComponentPropsWithoutRef<'form'> {
    className?: string
}

export interface COContactUserData {
    firstName: string
    lastName: string
    email: string
    phone: string
    saveContactData: string
}

const CheckoutForm: React.FC<CheckoutFormProps> = () => {
    const {
        config,
        coupons,
        booking,
        step,
        paymentMethod,
        test,
        setPaymentSuccess,
        handleGoBack,
        setCompletedBookingCode,
    } = useCOContext()

    const { mode } = useBBContext()

    const [loadingTest, setLoadingTest] = useState(false)
    const { t } = useTranslation()

    const titleMap = {
        [CheckoutStepId.YOUR_DATA]: t('enterYourDetails'),
        [CheckoutStepId.PAYMENT]: t('selectPaymentMethod'),
        [CheckoutStepId.RECIPIENT_DATA]: t('customiseYourGift'),
    } as const

    const skipPayment =
        booking && coupons && coupons.length > 0 ? computeBookingPriceWithVoucher(booking, coupons) === 0 : false

    return (
        <Fragment>
            <div className="pt-3.5">
                {mode === BOOKING_BOX_TYPE.BOOKING &&
                    (booking && booking.checkout_expiration_at ? (
                        <div>
                            <BookingTimer
                                handleExpiration={handleGoBack}
                                expirationTime={booking.checkout_expiration_at}
                            />
                        </div>
                    ) : (
                        <div className="loading-pulse my-0.5 h-[24px] w-[90%] rounded-full bg-zinc-50" />
                    ))}

                <div
                    className={`${mode === BOOKING_BOX_TYPE.BOOKING && step.id === CheckoutStepId.PAYMENT ? '' : 'hidden'}`}
                >
                    <PaymentRecap />
                </div>

                <div
                    className={clsx(
                        'mb-2 mt-3.5 text-[16px] font-medium screen775:!mt-4 screen775:text-[16.5px]',
                        step.id === CheckoutStepId.YOUR_DATA && 'screen775:hidden'
                    )}
                >
                    {titleMap[step.id] || ''}
                </div>

                <div className={`${step.id === CheckoutStepId.RECIPIENT_DATA ? '' : 'hidden'}`}>
                    <CORecipientInfo />
                </div>

                <div className={`${step.id === CheckoutStepId.YOUR_DATA ? '' : 'hidden'}`}>
                    <COCustomerInformationForm />
                </div>

                {test && step.id === CheckoutStepId.PAYMENT && (
                    <div>
                        <Button
                            variant={ButtonVariant.BLACK}
                            onClick={() => {
                                setLoadingTest(true)
                                window.setTimeout(() => {
                                    setPaymentSuccess(true)
                                    setCompletedBookingCode('test-booking')
                                }, 1000)
                            }}
                            className="flex w-full items-center justify-center"
                        >
                            {loadingTest ? <Loading size="32px" color="white" /> : 'Completa senza pagare (test)'}
                        </Button>
                        <div className="mt-3 text-xs text-zinc-400">{t('paymentOptions')}</div>
                    </div>
                )}

                {booking && coupons && coupons.length > 0 && skipPayment && (
                    <div className={clsx('relative mt-3', step.id === 3 ? '' : 'hidden')}>
                        <SkipPaymentButton />
                    </div>
                )}

                {/* {!test && config.payment_locations.includes('online') && ( */}
                {!test && !skipPayment && config.payment_locations.includes('online') && (
                    <div className={clsx('relative mt-3', step.id === 3 ? '' : 'hidden')}>
                        {/* ApplePay / GooglePay (Stripe) */}
                        <div className={paymentMethod === PaymentMethod.Card ? 'hidden' : ''}>
                            <AppleAndGooglePay />
                        </div>

                        {/* Cards (Stripe) */}
                        <CardsButton />

                        <div className={paymentMethod === PaymentMethod.Card ? '' : 'hidden'}>
                            <StripeCardSection />
                        </div>

                        {/* PayPal */}
                        <div className={`${paymentMethod === PaymentMethod.Card ? 'hidden' : ''}`}>
                            <PaypalGoldButton
                                onPaypalPaymentCompleted={() => {
                                    debugLog('paypal order completed!')
                                }}
                            />
                        </div>

                        <div className="mt-3 text-xs text-gray-400 screen775:!pb-4">
                            <Trans
                                i18nKey="termsAndPrivacy.holidoit"
                                t={t}
                                components={{
                                    holidoitLink: (
                                        <a
                                            href="https://www.holidoit.com/t/embed"
                                            target="_blank"
                                            className="text-blue-400 hover:text-blue-500 hover:underline"
                                        >
                                            Holidoit
                                        </a>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export { CheckoutForm }
