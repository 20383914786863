import useSWR from 'swr'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Allocation, ApiAvailabilitiesResponse, ExperienceVariantType } from 'src/types'
import { fetchWithLanguage, handleChangeCurrentDateV2, stringifyParticipants } from 'src/lib/utils'
import { api } from 'src/api'
import { useTranslation } from 'react-i18next'

const useAvailability = (
    selectedVariant: ExperienceVariantType | null,
    selectedDate: string | null,
    allocations: Allocation[] | undefined | null,
    noticePeriodHours: number | undefined,
    customCurrentDate?: dayjs.Dayjs
) => {
    const computeInitialDate = () => {
        // Dates

        let initialDate = dayjs(customCurrentDate) // Use customCurrentDate if provided

        // Check if customCurrentDate is valid, otherwise compute as before
        if (!initialDate.isValid()) {
            initialDate = dayjs()
        }

        if (selectedVariant?.first_available_month) {
            const currentMonth = initialDate.month() + 1
            if (selectedVariant.first_available_month < currentMonth) {
                initialDate = initialDate
                    .add(1, 'year')
                    .month(selectedVariant.first_available_month - 1)
                    .startOf('month')
            } else if (selectedVariant.first_available_month > currentMonth) {
                initialDate = initialDate.month(selectedVariant.first_available_month - 1).startOf('month')
            }
        }

        const noticePeriodDays = (noticePeriodHours ?? 48) / 24
        if (initialDate.date() >= initialDate.endOf('month').subtract(noticePeriodDays, 'day').date()) {
            initialDate = initialDate.add(1, 'month').startOf('month')
        }

        return initialDate
    }

    const [currentDate, setCurrentDate] = useState(dayjs(selectedDate || computeInitialDate()))

    const [dateFrom, dateTo] = handleChangeCurrentDateV2(currentDate);

    // Allocations

    const computeFetchParameters = () => {
        const totalParticipants = allocations?.reduce((acc, curr) => acc + (curr.quantity || 0), 0) ?? 0

        return totalParticipants > 0
    }

    const totalParticipants = allocations?.reduce((acc, curr) => acc + (curr.quantity || 0), 0) ?? 0

    const shouldFetch = computeFetchParameters()

    const { i18n } = useTranslation()
    const language = i18n.language

    const fetcher = async (url: string) => {
        const response = await fetchWithLanguage(url, { language, method: 'GET' })
        if (!response.ok) {
            throw new Error(`Error fetching data: ${response.status}`)
        }
        return response.json()
    }

    const url = `${api.endpoints.backend.bookingBox.variantAvailabilities.replace(
        ':id',
        selectedVariant?.id?.toString() ?? ''
    )}?date_from=${dateFrom}&date_to=${dateTo}&allocations=${stringifyParticipants(allocations ?? null)}`

    const { data: prefetchedDays, error } = useSWR<ApiAvailabilitiesResponse>(shouldFetch ? url : null, fetcher, {
        revalidateOnFocus: false,
        keepPreviousData: true,
        revalidateIfStale: false,
    })

    return { prefetchedDays, error, totalParticipants }
}

export default useAvailability
