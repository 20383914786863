import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import clsx from 'clsx'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import { useBBContext } from 'src/BookingBox/Context'
import { useExperiencePickerContext } from 'src/ExperiencePicker/Context'
import { CheckYesGreenIcon } from 'src/lib/icons/icons'
import { cancelCheckoutBooking, debugLog } from 'src/lib/utils'
import { useCOContext } from './Context'
import { CheckoutForm } from './checkout-form/CheckoutForm'
import { COStepper } from './checkout-form/components/COStepper'
import { CheckoutRecapBox } from './checkout-form/components/CORecapBox'
import { BOOKING_BOX_TYPE, ExperiencePickerStep } from '../types'

const stripePromise = loadStripe(import.meta.env.NEXT_PUBLIC_STRIPE_CLIENT_ID)

export const paypalPublicClientId = import.meta.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID ?? ''

interface COFlowProps extends React.ComponentPropsWithoutRef<'div'> {
    setShow: (show: boolean) => void
    startingExperienceId: number | null
    setStartingExperienceId: (experienceId: number | null) => void
}

const COFlow = ({ setShow, startingExperienceId, setStartingExperienceId }: COFlowProps) => {
    const {
        step,
        paymentSuccess,
        customerInfo,
        handleGoToExperienceStep,
        completedBookingCode,
        booking,
        paypalToken,
        handleGoBack,
    } = useCOContext()
    const { bookingCode, setBookingCode, selectedVariant, mode } = useBBContext()
    const { experience, experiences, setExperience, setStep: setEPStep } = useExperiencePickerContext()

    useEffect(() => {
        debugLog('startingExperienceId', startingExperienceId)
        if (startingExperienceId) {
            const selectedExperience = experiences.find((exp) => exp.id === startingExperienceId)
            if (selectedExperience) {
                setExperience(selectedExperience)
                if (selectedExperience.show_embed_product_page) {
                    setEPStep(ExperiencePickerStep.INFO)
                    handleGoToExperienceStep()
                } else {
                    handleGoBack()
                }
                setShow(true)
                cancelCheckoutBooking(bookingCode, setBookingCode)
            }
            setStartingExperienceId(null)
        }
    }, [startingExperienceId])

    const handler = useSwipeable({
        onSwipedRight: () => {
            handleGoBack()
        },
    })
    const { t } = useTranslation()

    debugLog('[COFlow] booking code', bookingCode)
    debugLog('[COFlow] checkout_expiration_at', booking)
    debugLog('[COFlow] completedBookingCode', completedBookingCode)

    const intent =
        mode === BOOKING_BOX_TYPE.BOOKING ? (selectedVariant.instant_booking ? 'capture' : 'authorize') : 'capture'

    return (
        <Fragment>
            <div className="screen775:!relative">
                {paymentSuccess ? (
                    <div className="space-y-3.5 px-6 py-6 text-[15px] screen600:!px-[17px]">
                        <div className="mb-3 ">
                            {t(mode === BOOKING_BOX_TYPE.BOOKING ? 'bookingSent' : 'openBookingSent', {
                                email: customerInfo.email,
                            })}
                        </div>

                        <div className={clsx('mb-2 space-y-2')}>
                            <div className="flex items-center gap-1.5 text-sm text-zinc-600">
                                <span>
                                    <CheckYesGreenIcon className="h-3.5 w-3.5" />
                                </span>
                                <span className="font-medium">{t('yourBooking')}</span>
                            </div>
                            <div className="flex w-full items-center gap-3.5 rounded border border-zinc-300 bg-zinc-50 p-2.5">
                                <div className="aspect-square w-[70px] overflow-hidden rounded screen600:!w-[55px]">
                                    <img
                                        loading="eager"
                                        src={experience?.images[0].image.replace('/full/', '/450/') ?? ''}
                                        className="aspect-square w-[70px] rounded-[4px] object-cover screen600:!w-[55px]"
                                    />
                                </div>
                                <div className="line-clamp-2 space-y-1.5 text-[14.5px] screen600:!text-[14px]">
                                    <div>{experience?.name_partner_pages || experience?.name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <CheckoutRecapBox afterCheckout />
                        </div>

                        {/*<div className="mt-4">*/}
                        {/*    <a*/}
                        {/*        className="cursor-pointer text-[14.5px] font-normal text-zinc-400 underline hover:!text-zinc-600"*/}
                        {/*        href={'http://www.holidoit.com/d/thank-you/' + bookingCode}*/}
                        {/*        target="_blank"*/}
                        {/*    >*/}
                        {/*        {t('bookingDetailsPage')} <IconExternal className="inline-block h-4 w-4" />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                ) : (
                    <div
                        className={clsx(
                            step.id === 2 ? 'screen775:!pb-[95px]' : 'screen775:!pb-[10px]',
                            'flex flex-col dynamic-body-height overflow-auto scrollbar-hide screen775:!max-h-[calc(100dvh-48px)] screen775:!overflow-auto screen775:!scrollbar-hide'
                        )}
                    >
                        <StripeElements
                            stripe={stripePromise}
                            options={{
                                fonts: [
                                    {
                                        cssSrc: 'https://fonts.googleapis.com/css?family=DM+Sans&display=swap',
                                    },
                                ],
                                appearance: {
                                    theme: 'stripe',
                                    variables: {
                                        colorPrimary: '#0570de',
                                        colorBackground: '#FAFAFA',
                                        colorText: '#30313d',
                                        colorDanger: '#df1b41',
                                        fontFamily: 'DM Sans, system-ui, sans-serif',
                                        fontSizeBase: '16px',
                                        spacingUnit: '2px',
                                        borderRadius: '4px',
                                        // See all possible variables below
                                    },
                                },
                            }}
                        >
                            <div className="flex w-full flex-col gap-0 bg-white">
                                <div className="w-full border-b border-zinc-300 bg-white px-6 pb-[26px] pt-[50px] screen775:!hidden screen775:!px-[17px]">
                                    <COStepper />
                                </div>
                                {handleGoToExperienceStep && experience && (
                                    <div className="flex h-[52px] items-center bg-zinc-50 px-5 screen775:!px-[17px]">
                                        <div
                                            className={clsx(
                                                'flex w-full  items-center gap-2',
                                                experiences?.length === 1 ? '' : 'cursor-pointer'
                                            )}
                                            onClick={() => {
                                                if (experiences?.length === 1) {
                                                    return
                                                }
                                                handleGoToExperienceStep()
                                            }}
                                        >
                                            <div className="relative !aspect-square !h-[32px] overflow-hidden rounded-[6px] screen600:!h-[23px]">
                                                <img
                                                    loading="eager"
                                                    src={experience?.images[0].image.replace('/full/', '/450/') ?? ''}
                                                    className="h-full w-full rounded-[6px] object-cover"
                                                />
                                            </div>
                                            <div className="line-clamp-2 space-y-1.5 truncate text-[14px] font-bold screen600:!text-[14px]">
                                                <div>{experience?.name_partner_pages || experience?.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <CheckoutRecapBox />
                            </div>
                            <div className="" {...handler}>
                                {paypalToken?.token && (
                                    <PayPalScriptProvider
                                        options={{
                                            clientId: paypalPublicClientId,
                                            currency: 'EUR',
                                            components: 'buttons,hosted-fields',
                                            intent: intent,
                                            vault: false,
                                            'disable-funding': 'credit,mybank',
                                            dataClientToken: paypalToken?.token,
                                        }}
                                    >
                                        <div
                                            className={clsx(
                                                // 'h-[calc(91dvh-48px-339px)] scrollbar-hide screen775:!h-auto',
                                                step.id === 2 ? 'pb-[150px]' : 'pb-[20px]',
                                                'h-auto overflow-auto px-6 scrollbar-hide screen775:!px-[17px] screen775:!pb-0'
                                            )}
                                        >
                                            <CheckoutForm />
                                        </div>
                                    </PayPalScriptProvider>
                                )}
                            </div>
                        </StripeElements>
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export { COFlow }
