import React, { Fragment, useState } from 'react'
import { api } from '../../../api'
import { useCOContext } from '../../Context'
import LoadingDots from '../../../lib/components/LoadingDots'
import { debugLog } from '../../../lib/utils'
import { useTranslation } from 'react-i18next'
import { useBBContext } from '../../../BookingBox/Context'

export const SkipPaymentButton: React.FC = () => {
    const [isPaying, setIsPaying] = useState<boolean>(false)
    const { booking, customerInfo, coupons, setCompletedBookingCode, setPaymentSuccess } = useCOContext()
    const { config } = useBBContext()
    const { t } = useTranslation()

    const skipPaymentSubmit = async () => {
        setIsPaying(true)
        return await fetch(
            api.endpoints.backend.checkout.skipPayment.replace(':embed_id', config.embed_id).replace(':id', booking.code),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    email: customerInfo.email,
                    phone: customerInfo.phone,
                    notes: customerInfo.additional_info == '' ? null : customerInfo.additional_info,
                    discount_codes: coupons.map((coupon) => coupon.code),
                }),
            }
        ).then((res) => {
            if (res.status === 404) {
            } else if (res.status === 200) {
                setPaymentSuccess(true)
                setCompletedBookingCode(booking.code)
                debugLog('success!! paypal payment completed')
                setIsPaying(false)
            } else {
                setIsPaying(false)
            }
            setIsPaying(false)
        })
    }

    return (
        <Fragment>
            <button
                className={`flex h-[52px] w-full items-center justify-center rounded-[7px] border-[1.5px] border-black bg-black/90 py-[14px] text-center font-sans text-[16px] font-medium text-white transition-all duration-200 hover:shadow-sm enabled:hover:bg-black enabled:hover:text-white enabled:hover:shadow-inner disabled:cursor-not-allowed disabled:border-gray-50/50 disabled:bg-gray-100 disabled:text-gray-400 disabled:outline-gray-300 screen900:text-[15.5px]`}
                style={{ float: 'right' }}
                onClick={(e) => {
                    e.preventDefault()
                    skipPaymentSubmit()
                }}
            >
                {isPaying ? <LoadingDots color="white" /> : t('confirmAndPay')}
            </button>
            {/*{errorInOrder && (*/}
            {/*    <Alert className="mt-2" withIcon={true}>*/}
            {/*        {t.bb.errorCreatingBooking}.*/}
            {/*    </Alert>*/}
            {/*)}*/}
        </Fragment>
    )
}
