const LoadingDots = ({ color = '#000' }: { color?: string }) => {
    return (
        // <span className={styles.loading}>
        <span className="loading-dots-holidoit">
            <span style={{ backgroundColor: color }} />
            <span style={{ backgroundColor: color }} />
            <span style={{ backgroundColor: color }} />
        </span>
    )
}

export default LoadingDots
