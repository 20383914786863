import { useCOContext } from 'src/Checkout/Context'
import { AmexIcon, ChevronDownIcon, MastercardIcon, VisaIcon, XMarkIcon } from 'src/lib/icons/icons'
import { PaymentMethod } from 'src/types'

export const CardsButton = () => {
    const { paymentMethod, setPaymentMethod } = useCOContext()
    return (
        <button
            type="button"
            className={`${
                paymentMethod === PaymentMethod.Card ? 'cursor-default' : 'cursor-pointer hover:!bg-blue-500/95 '
            } relative mb-[13px] flex h-[45px] w-full items-center justify-center gap-2.5 rounded bg-blue-500  transition-all duration-100`}
            onClick={(e) => {
                e.preventDefault()
                setPaymentMethod(PaymentMethod.Card)
            }}
        >
            <VisaIcon className="w-10" />
            <MastercardIcon className="w-10" />
            <AmexIcon className="w-10" />

            <div
                className="absolute right-2.5 top-1/2 -translate-y-1/2 cursor-pointer rounded-full p-2.5 text-white transition-all duration-75 hover:bg-blue-400 screen600:right-1.5"
                onClick={(e) => {
                    e.stopPropagation()
                    if (paymentMethod === PaymentMethod.Card) {
                        setPaymentMethod(null)
                    } else {
                        setPaymentMethod(PaymentMethod.Card)
                    }
                }}
            >
                {paymentMethod === PaymentMethod.Card ? (
                    <XMarkIcon color="white" className="h-[18px] w-[18px]" />
                ) : (
                    <ChevronDownIcon className="h-[19px] w-[19px]" />
                )}
            </div>
        </button>
    )
}
